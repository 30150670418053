import * as types from '../../actions/actionTypes';
import { FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS } from '../../utils/consts/consts';
import deepCopy from '../../utils/deepCopy';
import { isEmpty } from '../../utils/formatters/strings';

const initialStepperAccess = {
    renewaldashboard: false,
    reviewDetail: false,
    decide: false,
    enrollment: false,
    finalize: false,
};

const initialState = {
    stepperAccess: initialStepperAccess,
    caseReinstateFlag: false,
    caseCancelFlag: false,
    noDataText: 'No Records Found',
    refreshApisLoading: false,
    renewalDashBoardFlag: false,
    renewals: [],
    logInData: '',
    userRolesPermissions: {},
    workingOnBehalfOf: '',
    globalSearchFlag: false,
    renewalSearchCase: '',
    rlCaseProgress: '',
    renewAsIs : false,
    token:'',
    accessRoles:[],
    accessRolesMmrRn:[],
    medicalCensusSummary: {
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    priorYearDataSelected: 'N',
    disableTopHeader: false,
};

export default function renewalReducer(state = initialState, action) {
    switch (action.type) {

        case types.REQUEST_UPCOMING_RENEWALS:
            return {
                ...state,
                renewalsRequest: action.payload,
                noDataText: 'Loading....',
            };

        case types.RECEIVE_UPCOMING_RENEWALS:
            return {
                ...state,
                renewals: state.renewals ? [...new Map((state.renewals).concat(action.payload).map((o) => [o._id, o])).values()] : action.payload,
                noDataText: 'No Records Found',
            };

        // Payload for Filtered Dates
        case types.REQUEST_SELECTED_RENEWALS:
            return {
                ...state,
                selectedRenewalsRequest: action.payload,
                noDataText: 'No Records Found',
            };
        case types.RECEIVE_SELECTED_RENEWALS:
            return {
                ...state,
                renewals: action.payload,
                noDataText: 'No Records Found',
            };
        case 'RESET_RENEWALS':
            return {
                ...state,
                renewals: [],
                renewalData: {},
                renewalCaseData: {},
                stepperAccess: {},
                rlCaseData: {}
            };
        case types.RECEIVE_MONTH_RENEWALS:
            return {
                ...state,
                totalRenwalCases: action.payload,
                noDataText: 'No Records Found',
            };

        case types.LOGGING_IN:
            return {
                ...state,
                loginRequest: action.payload,
                noDataText: 'No Records Found',
            };

        case types.LOGGED_IN:
            return {
                ...state,
                logInData: action.payload,
                noDataText: 'No Records Found',
            };
        case types.SET_TABLE_PREFERENCE:
            return {
                ...state,
                logInData: state.logInData !== '' && state.logInData.length > 0 ? [
                    {
                        ...state.logInData[0],
                        tablePreference: action.payload
                    }
                ] : state.logInData
            }
        case types.LOGOUT_RENEWAL:
            return {
                ...initialState,
            };
        case types.RESET_RENEWAL_FLAG:
            return {
                ...state,
                renewalDashBoardFlag: action.payload,
                noDataText: 'No Records Found',
            };
        case types.SET_CASE_REINSTATE_FLAG_RENEWALS:
            return {
                ...state,
                caseReinstateFlag: action.payload,
                noDataText: 'No Records Found',
            };
        case types.SET_CASE_CANCEL_FLAG_RENEWALS:
            return {
                ...state,
                caseCancelFlag: action.payload,
                noDataText: 'No Records Found',
            };

        case types.GET_PCISID:
            return {
                ...state,
                ...action.payload,
                noDataText: 'No Records Found',
            };
        case types.STORE_NAME:
            return {
                ...state,
                fullName: action.payload,
                noDataText: 'No Records Found',
            };
        case types.GET_ROLE:
            return {
                ...state,
                role: action.payload,
                noDataText: 'No Records Found',
            };
        case types.STORE_EMAIL:
            return {
                ...state,
                emailAddress: action.email,
            };
        case types.SET_MEMBERGROUP_ID:
            return {
                ...state,
                memberGroupId: action.payload,
            };
        case types.STORE_ROLE_NAME:
            return {
                ...state,
                roleName: action.roleName,
            };
        case 'IS_LOGGEDIN':
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload,
            }
        case types.GET_ACCESS_ROLES:
            return {
                ...state,
                accessRoles : action.payload.accessRoles
            }
        case types.GET_ACCESS_ROLES_MMR_RN:
            return {
                ...state,
                accessRolesMmrRn : action.payload
            }     
        case types.STORE_DELEGATE_INFO:
            return {
                ...state,
                workingOnBehalfOf: action.delegateInfo,
            };
        case types.STORE_ROLES_PERMISSON_NAMES:
            return {
                ...state,
                userRolesPermissions: action.payload,
            };
        case types.GLOBAL_SEARCH_FLAG:
            return {
                ...state,
                globalSearchFlag: action.payload,
            };
        case types.RENEWAL_SEARCH_CASE:
            return {
                ...state,
                renewalSearchCase: action.payload,
            };
        case types.SET_RENEW_AS_IS:
            return {
                ...state,
                renewAsIs: action.payload
            }    
        case types.UPDATE_STEPPER_ACCESS:
            const accessPage = state.stepperAccess;
            accessPage[action.page] = true;
            return {
                ...state,
                stepperAccess: accessPage,
                noDataText: 'No Records Found',
            };

        case types.REQUEST_LAST_MODIFIED:
            return {
                ...state,
                ...action.payload,
                noDataText: 'No Records Found',
            };
        case types.RECEIVE_LAST_MODIFIED:
            return {
                ...state,
                lastModified: action.payload,
                noDataText: 'No Records Found',
            };
        case types.RECEIVE_SALESREP_DETAILS:
            return {
                ...state,
                ...action.payload,
                salesRepData: action.payload.salesRepData ? action.payload.salesRepData: [],
                agentData: action.payload.agentData,
                renewalCaseData: {
                    ...action.payload.renewalCaseData,
                    caseId: action.payload.caseId ? action.payload.caseId : action.payload.renewalCaseData.caseId ? action.payload.renewalCaseData.caseId : '',
                },
                noDataText: 'No Records Found',
            };

        case types.REQUEST_CREATE_HISTORY:
            return {
                ...state,
                ...action.payload,
                noDataText: 'No Records Found',
            };
        case types.RECEIVE_CREATE_HISTORY:
            return {
                ...state,
                ...action.payload,
                noDataText: 'No Records Found',
            };
        case types.INITIAL_STEPPER_ACCESS:
            const stepperAcces = action.payload;
            return {
                ...state,
                stepperAccess: stepperAcces,
                noDataText: 'No Records Found',
            };
        case types.SET_REFRESH_APIS_LOADER:
            return {
                ...state,
                refreshApisLoading: action.payload,
            };
        case types.UPDATE_RENEWALS_CASE_STATUS:
            if (state && state.renewals && state.renewals.globalSearchFlag) {
                const newRenewals = deepCopy(state.renewals);
                const searchCase = deepCopy(state.renewalSearchCase);
                searchCase.caseProgressUI = action.caseStatus;
                searchCase.renewalDataStatusUI = action.caseStatus;
                const renewalCaseIdx = newRenewals.findIndex((x) => x.memberGroupId === action.memberGroupId);
                if (renewalCaseIdx) {
                    if (action.caseStatus === 'Accepted As Is' || action.caseStatus === 'Accepted' || action.caseStatus === 'Pending Acceptance' || action.caseStatus === 'Pending Cancellation' || action.caseStatus === 'Cancelled') {
                        newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                        newRenewals[renewalCaseIdx].renewalDataStatusUI = action.caseStatus;
                    } else if (action.caseStatus === 'Not Started' && !isEmpty(action.prevRenewalDataStatus)) {
                        newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                        newRenewals[renewalCaseIdx].renewalDataStatusUI = action.prevRenewalDataStatus;
                    } else {
                        newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                    }
                }
                return {
                    ...state,
                    renewals: newRenewals,
                    renewalSearchCase: searchCase,
                };
            }
            const newRenewals = deepCopy(state.renewals);
            const renewalCaseIdx = newRenewals.findIndex((x) => x.memberGroupId === action.memberGroupId);
            if (renewalCaseIdx >= 0) 
            {
                if (action.caseStatus === 'Accepted As Is' || action.caseStatus === 'Accepted' || action.caseStatus === 'Pending Acceptance' || action.caseStatus === 'Pending Cancellation' || action.caseStatus === 'Cancelled') {
                    newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                    newRenewals[renewalCaseIdx].renewalDataStatusUI = action.caseStatus;
                } else if (action.caseStatus === 'Not Started' && !isEmpty(action.prevRenewalDataStatus)) {
                    newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                    newRenewals[renewalCaseIdx].renewalDataStatusUI = action.prevRenewalDataStatus;
                } else {
                    newRenewals[renewalCaseIdx].caseProgressUI = action.caseStatus;
                }
            }            
            return {
                ...state,
                renewals: newRenewals,
            };

        case 'UPDATE_RENEWALS_SEARCH_CASE':
            const searchCase = deepCopy(state.renewalSearchCase);
            if(searchCase) {
                searchCase.caseProgressUI = action.caseStatus;
                return {
                    ...state,
                    renewalSearchCase: searchCase,
                };
            }

        case types.UPDATE_RL_CASE_PROGRESS:
            return {
                ...state,
                rlCaseProgress: action.caseStatus
            }
        case types.UPDATE_RENEWALS_ACCEPTED_DATE:
            const newRenewalsforAcceptedDate = deepCopy(state.renewals);
            const renewalCaseIdxOfAcceptedDate = newRenewalsforAcceptedDate.findIndex((x) => x.memberGroupId === action.memberGroupId);
            newRenewalsforAcceptedDate[renewalCaseIdxOfAcceptedDate].acceptedDate = action.acceptedDate;
            return {
                ...state,
                renewals: newRenewalsforAcceptedDate,
            };
        case types.UPDATE_RENEWALS_SUBMITTER_DATA: 
            return {
                ...state,
                submitterDetails: action.req
            }
        case types.UPDATE_RENEWALS_SUBMITTED_DATE:
            const newRenewalsforSubmittedDate = deepCopy(state.renewals);
            const renewalCaseIdxOfSubmittedDate = newRenewalsforSubmittedDate.findIndex((x) => x.memberGroupId === action.memberGroupId);
            newRenewalsforSubmittedDate[renewalCaseIdxOfSubmittedDate].submittedDate = action.submittedDate;
            return {
                ...state,
                renewals: newRenewalsforSubmittedDate,
            };
        case types.UPDATE_RENEWAL_PROGRESS:
            return {
                ...state,
                totalProgress: action.payload,
            };
        case types.INITIALIZE_EFFECTIVE_DATE_RULES:
            return {
                ...state,
                dateRules : action.payload
            };
            case types.INITIALIZE_PLAN_TYPE_RULES:
            return {
                ...state,
                pcpAutoAssignmentRules : action.payload
            };
        case types.STORE_USER_INTENT:
            return{
                ...state,
                userIntent: action.payload
            }
        case types.LATEST_RENEWAL_CASE_DATA:
            return{
                ...state,
                rlCaseData: action.payload
            }
        case types.SET_MEDICAL_CENSUS_SUMMARY:
            return {
                ...state,
                medicalCensusSummary: {
                    ...state.medicalCensusSummary,
                    employeeOnly: action.payload.medicalCensusSummary.employeeOnly,
                    employeeWithSpouse: action.payload.medicalCensusSummary.employeeWithSpouse,
                    employeeWithChild: action.payload.medicalCensusSummary.employeeWithChild,
                    employeeWithFamily: action.payload.medicalCensusSummary.employeeWithFamily
                },
            }
        case types.STORE_PRIOR_YEAR_DATA_VAL: 
            return{
                ... state,
                priorYearDataSelected: action.payload
            }

        case types.SET_RENEWAL_CASE_DATA:
            return {
                ...state,
                renewalCaseData: action.payload
            }
        case types.UPDATE_TRANSACTION_TYPE:
            const newRenewalsForTranType = deepCopy(state.renewals);
            const renewalWithTranType = newRenewalsForTranType.findIndex((x) => x.memberGroupId === action.payload.memberGroupId);
            newRenewalsForTranType[renewalWithTranType].samxTransactionType = action.payload.tranType;
            return {
                ...state,
                renewals: newRenewalsForTranType,
            }
        case types.UPDATE_ENROLLED_PLANS_FROM_DECIDE:
            return{
                ...state,
                enrolledPlans: action.payload
            }
        case types.DISABLE_TOP_HEADER:
            return {
                ...state,
                disableTopHeader: action.payload
            }
        case types.AUTOMATE_MMR_UPLOAD:
            return {
                ...state,
                automateMMR :{  
                    payload : action.payload
                } 
            }
        case types.IS_AUTOMATE_MMR_UPLOAD_IN_PROGRESS:
            return {
                ...state,
                isAutomateMMRUploadInProgress : action.payload
            }   
        case types.AUTOMATE_MMR_UPLOAD_REPORT:
            let updatedAutomatedMMRReport = state.automateMMRReport? deepCopy(state.automateMMRReport) : [];
            if(updatedAutomatedMMRReport?.length > 0){
                let recordExisting = updatedAutomatedMMRReport.find(rec=> rec.memberGroupId === action.payload?.memberGroupId)
                if(!recordExisting){
                    updatedAutomatedMMRReport.push(action.payload);
                }
            }else{
                updatedAutomatedMMRReport.push(action.payload);
            }
            return {
                ...state,
                automateMMRReport : updatedAutomatedMMRReport
                    //need to append action in array named report,
            }  
        case types.CLEAR_AUTOMATE_MMR_UPLOAD_REPORT:
            return {
                ...state,
                automateMMRReport : []
                    //need to append action in array named report,
            }               
        case types.UPDATE_RENEWAL_CASE_STATUS_FOR_DASHBOARD:
            const updatedRenewals = deepCopy(state.renewals);
            const caseIndex = updatedRenewals.findIndex(renewalCase => renewalCase.memberGroupId === action.memberGroupId);
            if (caseIndex > -1) {
                const updatedRenewalCase = {
                    ...updatedRenewals[caseIndex],
                    caseProgressUI: FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS,
                    renewalDataStatusUI: FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS,
                    renewalStatus: FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS
                }
                updatedRenewals[caseIndex] = updatedRenewalCase;
            }
            return {
                ...state,
                renewals: updatedRenewals
            }
        default:
            return state;
    }
}
