const { mapInstallOption, formatCompositeRates, formatEmployeeRates, getCopayDetails, getINODetails, mapPlanType, dollarFormat } = require("./FilterACAHelper");
import { getMonthDiffInDates } from '../../../../../../utils/formatters/dates';

export const mapVisionPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let visionPlanTypeDescription = productPlan.contributionLevel;
    if (visionPlanTypeDescription === '100% Employer Paid') {
        visionPlanTypeDescription = '100% ER Paid';
    }
    if (visionPlanTypeDescription === 'Employee Core') {
        visionPlanTypeDescription = 'EE Core/Vol Dep';
    }
    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        planName: productPlan.planName ? productPlan.planName : '',
        visionPlanTypeCode: (productPlan.planCode.charAt(0) === 'V' || productPlan.planCode.charAt(0) === 'L') ? 'V/L Plan' : 'S Plan',
        visionPlanTypeDescription,
        renewalPlanType,
        exam: {
            coveredMonthsInterval: productPlan.frequency.examFrequency,
            copayIn: productPlan.copay && productPlan.copay.exam
                ? productPlan.copay.exam : 0,
            allowanceOut: productPlan.allowance.oon.examAllowance,
            secondExamForDiabeticsCopay: productPlan.copay.secondExamForDiabetics,
        },
        lenses: {
            coveredMonthsInterval: productPlan.frequency.lensFrequency,
            allowanceOut: productPlan.allowance.oon.contactLensAllowance, // Need to check
            lensOption: productPlan.lensOptions.lensOptionsText,
        },
        frames: {
            coveredMonthsInterval: productPlan.frequency.frameFrequency,
            allowanceOut: productPlan.allowance.oon.frameAllowance,
            allowanceRetail: productPlan.retailFrameAllowance,
        },
        materialCopayIn: productPlan.copay && productPlan.copay.materials
            ? productPlan.copay.materials : 0,
        contactLenses: {
            allowanceIn: productPlan.allowance.contactLensAllowance === null
                ? 0 : productPlan.allowance.contactLensAllowance,
            allowanceOut: productPlan.allowance.oon.contactLensAllowance,
            fittingAllowance: productPlan.allowance.clFitAllowance === null
                ? 0 : productPlan.allowance.clFitAllowance,
            formularyContactLensesFittingEvaluation: productPlan.copay.formularyContactLensesFittingEvaluation,
        },
        planType: 'Vision',
        platform: 'CIRRUS',
        orderofPreference: productPlan.orderOfPref,
        isPreferredPlan: productPlan.isPrefPlan,
        allowance: productPlan.allowance,
        copayDiabeticsRetinalScreening: productPlan.copay && productPlan.copay.retinalScreeningForDiabetics ? productPlan.copay.retinalScreeningForDiabetics : 0,
        necessaryContactLenses: productPlan.necessaryContactLenses == null ? 0 : productPlan.necessaryContactLenses,
        frequency: productPlan.frequencyCombined,
        copays: productPlan.copay && productPlan.copay.examAndMaterials ? productPlan.copay.examAndMaterials : '0',
        installationType: mapInstallOption(productPlan.availability['ues/SAM'], 'CT', 'cirrus'),
        isMultiStatePlan: productPlan.availability['cirrus'].states.length > 1,
        rateGuarantee: (productPlan && productPlan.rateGuarantee && productPlan.rateGuarantee.rateGuaranteeValue) ? productPlan.rateGuarantee.rateGuaranteeValue : '',
        productSuite: productPlan.productSuite,
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType)

    if (planRate) {
        let monthlyPremium = formatCompositeRates(planRate.rateTable.rate);
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        obj.monthlyPremium = monthlyPremium;
        obj.finalRates = monthlyPremium;

        obj.quote = premiumMonthly;
        obj.finalMonthlyPremium = premiumMonthly;

        obj.enrolledCount = planRate.empCount
        obj.renewalChangePercent = null
        let census = formatEmployeeRates(planRate.employeeRates || [])

        obj.byCensus = {
            finalMonthlyPremium: premiumMonthly,
            employeeRates: census
        }

        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;
}



export const mapDentalPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false) => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: planCode,
        renewalPlanType: renewalPlanType,
        platform: 'CIRRUS',
        planName: productPlan.planName ? productPlan.planName : '',
        dentalPlanTypeCode: productPlan.productType,
        deductible: {
            individualIn: productPlan.deductible.inDeductibleIndiv,
            individualOut: productPlan.deductible.oonDeductibleIndiv,
            // productPlan.deductibleAmount.family.in,
            familyIn: productPlan.deductible.inDeductibleFamily,
            // productPlan.deductibleAmount.family.out
            familyOut: productPlan.deductible.oonDeductibleFamily,
            // indDeductibleText: `$${productPlan.deductible.inDeductibleIndiv} / $${productPlan.deductible.oonDeductibleIndiv}`,
            // familyDeductibleText: `$${productPlan.deductible.inDeductibleFamily} / $${productPlan.deductible.oonDeductibleFamily}`,
            indDeductibleText: `${dollarFormat(productPlan.deductible.inDeductibleIndiv)} / ${dollarFormat(productPlan.deductible.oonDeductibleIndiv)}`,
            familyDeductibleText: `${dollarFormat(productPlan.deductible.inDeductibleFamily)} / ${dollarFormat(productPlan.deductible.oonDeductibleFamily)}`,
        },
        deductibleWaiver: 'N/A', // productPlan.indicator.deductibleWaiver === "Y",
        coinsurance: {
            preventiveIn: productPlan.services['preventiveIn/Out'].split(' / ')[0],
            preventiveOut: productPlan.services['preventiveIn/Out'].split(' / ')[1],
            basicIn: productPlan.services['basicIn/Out'].split(' / ')[0],
            basicOut: productPlan.services['basicIn/Out'].split(' / ')[1],
            majorIn: productPlan.services['majorIn/Out'].split(' / ')[0],
            majorOut: productPlan.services['majorIn/Out'].split(' / ')[1],
            minorIn: 'N/A', // productPlan.minorRestorativeInNetCoInsurancePct,
            minorOut: 'N/A', // productPlan.minorRestorativeOutNetCoInsurancePct,
            orthodonticsIn: productPlan.services['orthoIn/Out'] !== ' ' && productPlan.services['orthoIn/Out'] !== '' ? productPlan.services['orthoIn/Out'].split(' / ')[0] : '0%',
            orthodonticsOut: productPlan.services['orthoIn/Out'] !== ' ' && productPlan.services['orthoIn/Out'] !== '' ? productPlan.services['orthoIn/Out'].split(' / ')[1] : '0%',
            implantIn: productPlan.services['implantsIn/Out'] !== ' ' ? productPlan.services['implantsIn/Out'].split(' / ')[0] : 0,
            implantOut: productPlan.services['implantsIn/Out'] !== ' ' ? productPlan.services['implantsIn/Out'].split(' / ')[1] : 0,
            periodonticsIn: productPlan.services['perioIn/Out'].split(' / ')[0],
            periodonticsOut: productPlan.services['perioIn/Out'].split(' / ')[1],
            coinsuranceOralIn: productPlan.services['oralSurgeryIn/Out'] !== ' ' && productPlan.services['oralSurgeryIn/Out'] !== '' ? productPlan.services['oralSurgeryIn/Out'].split(' / ')[0] : 0,
            coinsuranceOralOut: productPlan.services['oralSurgeryIn/Out'] !== ' ' && productPlan.services['oralSurgeryIn/Out'] !== '' ? productPlan.services['oralSurgeryIn/Out'].split(' / ')[1] : 0,
        },
        maxBenefit: {
            in: productPlan.annualMax.inAnnualMax,
            out: productPlan.annualMax.oonAnnualMax,
            // maxBenefitText: `${productPlan.annualMax.inAnnualMax} / ${productPlan.annualMax.oonAnnualMax}`,
            maxBenefitText: `${dollarFormat(productPlan.annualMax.inAnnualMax)} / ${dollarFormat(productPlan.annualMax.oonAnnualMax)}`,
        },
        ucr: productPlan.ucrPct,
        orthodontics: {
            inCovered: productPlan.ortho.inOrthoEligibility,
            inLifetimeBenefit: productPlan.ortho.inOrthoLifetimeMax,
            outCovered: productPlan.ortho.oonOrthoEligibility,
            outLifetimeBenefit: productPlan.ortho.oonOrthoLifetimeMax,
        },
        waitingPeriodMonths: 'N/A', // productPlan.waitingPeriodMonths,
        fundingType: productPlan.contributionStrategy !== undefined ? productPlan.contributionStrategy.toUpperCase() : '', // productPlan.planFundingDescription,
        planType: 'Dental',
        endodonticServices: {
            inEndo: productPlan.endo.inEndo,
            inEndoPulpotomy: productPlan.endo.inEndoPulpotomy,
            oonEndo: productPlan.endo.oonEndo,
            oonEndoPulpotomy: productPlan.endo.oonEndoPulpotomy,
        },
        simpleExtractions: {
            inSimpleExtractions: productPlan.simpleExtractions.inSimpleExtractions,
            oonSimpleExtractions: productPlan.simpleExtractions.oonSimpleExtractions,
        },
        orderofPreference: productPlan.orderOfPref,
        isPreferredPlan: productPlan.isPrefPlan,
        dentalPlanType: mapPlanType(productPlan.planType),
        classShiftDescription: productPlan.classShiftDesc,
        waitingPeriodMajor: productPlan.waitingPeriodMajorServices,
        dedicatedLine: productPlan.dedicateLine ? productPlan.dedicateLine : '**standard**',
        includeOrtho: productPlan.includeOrtho,
        includeImplants: productPlan.includeImplants,
        includeCMM: productPlan.includeCMM,
        includePMM: productPlan.includePMM,
        inCoins: productPlan.coinsurance['inCoins(P&D/Basic/Major/Ortho)'],
        outCoins: productPlan.coinsurance['outCoins(P&D/Basic/Major/Ortho)'],
        installationType: mapInstallOption(productPlan.stateAvailability['ues/SAM'], 'CT','cirrus'),
        dentalNetworkType: productPlan.dentalNetworkType,
        style: productPlan.style,
        productType: productPlan.productType,
        copayDetails: getCopayDetails(productPlan),
        inoDetails: getINODetails(productPlan),
        isMultiStatePlan: productPlan.stateAvailability['cirrus'].states.length > 1,
        rateGuarantee: (productPlan && productPlan.rateGuarantee && productPlan.rateGuarantee.rateGuaranteeValue) ? productPlan.rateGuarantee.rateGuaranteeValue : '',
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType)

    if (planRate) {
        let monthlyPremium = formatCompositeRates(planRate.rateTable.rate);
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        obj.monthlyPremium = monthlyPremium;
        obj.finalRates = monthlyPremium;

        obj.quote = premiumMonthly;
        obj.finalMonthlyPremium = premiumMonthly;

        obj.enrolledCount = planRate.empCount
        obj.renewalChangePercent = null
        let census = formatEmployeeRates(planRate.employeeRates || [])

        obj.byCensus = {
            finalMonthlyPremium: premiumMonthly,
            employeeRates: census
        }

        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;

}



export const mapBasicLifePlans = (isCurrent, planCode, productPlan, planRates, planRateComponents, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        ADandDCode: productPlan.blADnDPairNew,
        depLifeCode: productPlan.blBdlPairNew,
        benefitAmount: productPlan.blBenefitLevel,
        productType: productPlan.productType,
        planType: productPlan.product,
        fundingType: productPlan.ContributionType,
        minimumParticipationPercentage: productPlan.participationRequirement,
        ADandDBenefitAmount: productPlan.pairedAdnDPlanDetails.blBenefitLevel ? productPlan.pairedAdnDPlanDetails.blBenefitLevel : productPlan.blBenefitLevel,
        spouseBenefitAmount: productPlan.coverageType.toUpperCase() === 'DEPENDANT' ? productPlan.benefitDetails.FlatAmount : productPlan.benefitDetails.childBenefit2,
        childBenefitAmount: productPlan.benefitDetails.childBenefit2,
        planMax: productPlan.maxAmt,
        lifeBenefitType: productPlan.coverageType,
        ADandDBenefitType: productPlan.pairedAdnDPlanDetails.coverageType,
        ageBenefitReductionSchedule: productPlan.benefitReduction,
        coverageTermination: productPlan.coverageTerm,
        suicideLimitBenefit: productPlan.lifeBenefits.suicideLimitBenefit,
        acceleratedDeathBenefit: productPlan.lifeBenAccelDeathBenComined,
        lifeExpectancy: productPlan.lifeBenefits.accidentalDeathBenefit.lifeExpectancy,
        eliminationPeriod: productPlan.lifeBenefits.waiverofPremium.eliminationPeriod,
        conversion: productPlan.blConversion,
        portability: productPlan.blPortability,
        waiverOfPremium: productPlan.LifeBenWOPCombined,
        primePlanCode: productPlan.primePlanCode,
        adAndDBenefit: productPlan.pairedAdnDPlanDetails.aDnDBenefits,
        benefitDetails: productPlan.benefitDetails,
        lifeBenefits: productPlan.lifeBenefits,
        rateType: productPlan.rateType,
        isPreferredPlan: true, 
        platform: 'CIRRUS',
        pairedAdnDDetails: {
            code: productPlan.pairedAdnDPlanDetails.planCode,
            benefitAmount: productPlan.pairedAdnDPlanDetails.blBenefitLevel,
            productType: productPlan.pairedAdnDPlanDetails.productType,
            planType: productPlan.pairedAdnDPlanDetails.product,
            fundingType: productPlan.pairedAdnDPlanDetails.ContributionType,
            minimumParticipationPercentage: productPlan.pairedAdnDPlanDetails.participationRequirement,
            spouseBenefitAmount: productPlan.pairedAdnDPlanDetails.benefitDetails ? productPlan.pairedAdnDPlanDetails.benefitDetails.FlatAmount : '',
            childBenefitAmount: productPlan.pairedAdnDPlanDetails.benefitDetails ? productPlan.pairedAdnDPlanDetails.benefitDetails.childBenefit2 : '',
            planMax: productPlan.pairedAdnDPlanDetails.maxAmt,
            lifeBenefitType: productPlan.pairedAdnDPlanDetails.coverageType,
            ageBenefitReductionSchedule: productPlan.pairedAdnDPlanDetails.benefitReduction,
            coverageTermination: productPlan.pairedAdnDPlanDetails.coverageTerm,
            suicideLimitBenefit: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.suicideLimitBenefit : '',
            acceleratedDeathBenefit: productPlan.pairedAdnDPlanDetails.lifeBenAccelDeathBenComined,
            lifeExpectancy: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.accidentalDeathBenefit.lifeExpectancy : '',
            eliminationPeriod: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.waiverofPremium.eliminationPeriod : '',
            conversion: productPlan.pairedAdnDPlanDetails.blConversion,
            portability: productPlan.pairedAdnDPlanDetails.blPortability,
            waiverOfPremium: productPlan.pairedAdnDPlanDetails.LifeBenWOPCombined,
            primePlanCode: productPlan.pairedAdnDPlanDetails.primePlanCode,
            adAndDBenefit: productPlan.pairedAdnDPlanDetails.aDnDBenefits,
            benefitDetails: productPlan.pairedAdnDPlanDetails.benefitDetails,
            lifeBenefits: productPlan.pairedAdnDPlanDetails.lifeBenefits,
            rateType: productPlan.pairedAdnDPlanDetails.rateType,
            name: productPlan.pairedAdnDPlanDetails.planName,
        },
        isMultiStatePlan: productPlan.stateAvailability.isUHCMultiStatePlan,
        name: productPlan.planName,

        renewalPlanType,
        isDiscontinued
    }

    let blPlanCode = planCode && planCode.split('--')[0];
    let addPlanCode = planCode && planCode.split('--')[1];
    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    let blPlanRateComp = planRateComponents.find(planRateComp => planRateComp.planCode && planRateComp.planCode === blPlanCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    let addPlanRateComp = planRateComponents.find(planRateComp => planRateComp.planCode && planRateComp.planCode === addPlanCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    
    if (planRate && blPlanRateComp && addPlanRateComp) {
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        let census = formatEmployeeRates(planRate.employeeRates || []);
        let byCensus = {
            totalMonthlyPremium: premiumMonthly,
            employeeRates: census,
            employeeRate: census
        };

        let rateObj = {
            basicLifeRateVol: blPlanRateComp.planRate.rate,
            aDnDLifeRateVol: addPlanRateComp.planRate.rate,
            totalLifeRateVol: planRate.rateTable.rate[0].total,

            BasicLifeTotVolAmount: blPlanRateComp.rateTotals[0].totalVolume,
            ADDTotVolAmount: addPlanRateComp.rateTotals[0].totalVolume,

            basicLifeTotalPremium: blPlanRateComp.rateTotals[0].total,
            aDndTotalPremium: addPlanRateComp.rateTotals[0].total,
            totalMonthlyPremium: premiumMonthly
        };

        obj.byCensus = byCensus;
        obj.finalMonthlyPremium = premiumMonthly;
        obj.finalRates = {
            byCensus,
            ...rateObj,
            employeeRates: census,
            employeeRate: census
        };
        obj.quote = {
            byCensus,
            ...rateObj,
            employeeRates: census,
            employeeRate: census
        };
        obj.enrolledCount = planRate.empCount;
        obj.guaranteedIssue = planRate.guarIssueAmt;
        obj.pairedAdnDDetails.guaranteedIssue = planRate.guarIssueAmt;
        obj.rateGuarantee = getMonthDiffInDates(new Date(planRate.rateGuarBegin), new Date(planRate.rateGuarEnd));
        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;
}



export const mapDepLifePlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        ADandDCode: productPlan.blADnDPairNew,
        depLifeCode: productPlan.blBdlPairNew,
        benefitAmount: productPlan.blBenefitLevel,
        productType: productPlan.productType,
        planType: productPlan.productType == "Basic Dep Life" ? "LIFE_DEP_BASIC" : productPlan.product,
        fundingType: productPlan.ContributionType,
        minimumParticipationPercentage: productPlan.participationRequirement,
        spouseBenefitAmount: productPlan.coverageType.toUpperCase() === 'DEPENDANT' ? productPlan.benefitDetails.FlatAmount : productPlan.benefitDetails.childBenefit2,
        childBenefitAmount: productPlan.benefitDetails.childBenefit2,
        planMax: productPlan.maxAmt,
        lifeBenefitType: productPlan.coverageType,
        ageBenefitReductionSchedule: productPlan.benefitReduction,
        coverageTermination: productPlan.coverageTerm,
        suicideLimitBenefit: productPlan.lifeBenefits.suicideLimitBenefit,
        acceleratedDeathBenefit: productPlan.lifeBenAccelDeathBenComined,
        lifeExpectancy: productPlan.lifeBenefits.accidentalDeathBenefit.lifeExpectancy,
        eliminationPeriod: productPlan.lifeBenefits.waiverofPremium.eliminationPeriod,
        conversion: productPlan.blConversion,
        portability: productPlan.blPortability,
        waiverOfPremium: productPlan.LifeBenWOPCombined,
        primePlanCode: productPlan.primePlanCode,
        benefitDetails: productPlan.benefitDetails,
        lifeBenefits: productPlan.lifeBenefits,
        rateType: productPlan.rateType,
        isPreferredPlan: true, 
        platform: 'CIRRUS',
        isMultiStatePlan: productPlan.stateAvailability.isUHCMultiStatePlan,
        name: productPlan.planName,

        renewalPlanType,
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    
    if (planRate) {
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        let census = formatEmployeeRates(planRate.employeeRates || []);
        let byCensus = {
            totalMonthlyPremium: premiumMonthly,
            employeeRates: census
        };

        let rateObj = {
            depLifeChildRate: planRate.rateTable.rate[0].total,
            depLifeSpouseRate: planRate.rateTable.rate[0].total,
            depLifeTotPremium: premiumMonthly,
            totalMonthlyPremium: premiumMonthly,
            blCode: productPlan.planCode,
            code: productPlan.planCode,
            combinedCodes: productPlan.planCode+"--null"
        };

        obj.byCensus = byCensus;
        obj.finalMonthlyPremium = premiumMonthly;
        obj.finalRates = {
            byCensus,
            ...rateObj,
            employeeRates: census,
        };
        obj.quote = premiumMonthly;
        obj.enrolledCount = planRate.depCount - planRate.empCount;
        obj.dependentLifeRate = planRate.rateTable.rate[0].total;
        obj.rateGuarantee = getMonthDiffInDates(new Date(planRate.rateGuarBegin), new Date(planRate.rateGuarEnd));
        obj.renewalChangePercent = premiumMonthlyRenewalChange;
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange;
    }

    return obj;
}


export const mapStdPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false) => {
    //dummy mapping
    let idx = isCurrent ? planRates[0].renewalPlanType === "Current" ? 0 : 1 : planRates[0].renewalPlanType === "Renewal" ? 0 : 1 
    let planObj = {
        code: planCode,
        piaPlanCode: productPlan.piaPlanCode ? productPlan.piaPlanCode : "",
        contributionType: productPlan.contribType ? productPlan.contribType : "",
        planType: productPlan.product ? productPlan.product : "STD",
        weeklyBenefitAmount: productPlan.benAmount ? productPlan.benAmount : "",
        flatAmount: null,
        benefitPercentage: productPlan.benefit ? productPlan.benefit.amount ? productPlan.benefit.amount : "" : "",
        benifitDuration: productPlan.benDuration ? productPlan.benDuration : "",
        eliminationPeriod: productPlan.elimPeriod ? productPlan.elimPeriod : "",
        preEx: productPlan.preEx ? productPlan.preEx.exclusion ? productPlan.preEx.exclusion : "" : "",
        rehab: productPlan.rehabBen ? productPlan.rehabBen : "",
        lumpSum: {
          survivorBen: productPlan.survivorBen ? productPlan.survivorBen.survivorBen ?  productPlan.survivorBen.survivorBen : "" : "",
          accelLifeExpect: productPlan.survivorBen ? productPlan.survivorBen.accelLifeExpect ? productPlan.survivorBen.accelLifeExpect : "" : "",
          amount: productPlan.survivorBen ? productPlan.survivorBen.amount ? productPlan.survivorBen.amount : "" : "",
          amountType: productPlan.survivorBen ? productPlan.survivorBen.amountType ? productPlan.survivorBen.amountType : "" : "",
          type: productPlan.survivorBen ? productPlan.survivorBen.type ? productPlan.survivorBen.type : "" : "",
          weeks: productPlan.survivorBen ? productPlan.survivorBen.weeks ? productPlan.survivorBen.weeks : "" : ""
        },
        recurrent: productPlan.recurrent ? productPlan.recurrent : "",
        renewalPlanType: isCurrent ? "current" : "renewing",
        coverageType: productPlan.covType ? productPlan.covType : "",
        productType: productPlan.productType ? productPlan.productType : "",
        maternity:  productPlan.maternityBen ? productPlan.maternityBen : "",
        maxWeeklyBenifit: productPlan.benMax ? productPlan.benMax : "",
        rateGuarantee: "24",
        rateType: productPlan.rateType ? productPlan.rateType : "",
        platform: "CIRRUS",
        orderofPreference: "1",
        isPreferredPlan: true,
        bridge2Health: "Yes",
        selected: true,
        favorited: false,
        totalMonthlyPremium:  planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
        quote: {
          stdPlanCode: productPlan.planCode ? productPlan.planCode : "",
          rateGuarBegin: planRates[idx].rateGuarBegin ? planRates[idx].rateGuarBegin : "",
          rateGuarEnd: planRates[idx].rateGuarEnd ? planRates[idx].rateGuarEnd : "",
          totalWeeklyBenefit: planRates[idx].weeklyBenefit ? planRates[idx].weeklyBenefit : "",
          totalMonthlyPremium:  planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          rateOver10WeeklyBenefit: "0.14",
          maximumWeeklyBenefit: "341.54",
          employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
          employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
          ageBands: planRates[idx].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idx].rateTable.rate) : null ,
          quoteId: "0689329-01"
        },
        finalMonthlyPremium:  planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
        finalRates: {
          stdPlanCode: productPlan.planCode ? productPlan.planCode : "",
          rateGuarBegin: planRates[idx].rateGuarBegin ? planRates[idx].rateGuarBegin : "",
          rateGuarEnd: planRates[idx].rateGuarEnd ? planRates[idx].rateGuarEnd : "",
          totalWeeklyBenefit: planRates[idx].weeklyBenefit ? planRates[idx].weeklyBenefit : "",
          totalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          rateOver10WeeklyBenefit: "0.13",
          maximumWeeklyBenefit: "54.58",
          employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
          employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
          ageBands: planRates[idx].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idx].rateTable.rate) : null ,
          quoteId: "0689621-01"
        },
        selectedPackages: [
          "Single"
        ],
        platformRatingID: "0689621-01",
        quoteFinal: "7.09",
        hasDifferentPremium: true,
        employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
        employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
        byCensus: {
            finalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
            employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
        },
        byCensusShopping: {
          finalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
            employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
        },
        employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
      }
    return planObj
}
function formatAgeBandOxf(rates){
    let ratesNew = [];
    for (let i = 0; i < rates.length; i++) {
      let age = rates[i].band.split('-');
        ratesNew[i] = {
            minAge: age[0],
            maxAge: age[1],
            rate: parseFloat(rates[i].total),
            total: parseFloat(rates[i].total)
        }
    }
    return ratesNew;

}

export const mapLtdPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false) => {
  let idx = isCurrent ? planRates[0].renewalPlanType === "Current" ? 0 : 1 : planRates[0].renewalPlanType === "Renewal" ? 0 : 1 
    let planObj = {
        code: planCode,
        piaPlanCode:  productPlan.piaPlanCode ? productPlan.piaPlanCode : "",
        contributionType: productPlan.contribType ? productPlan.contribType : "",
        planType:productPlan.product ? productPlan.product : "LTD",
        weeklyBenefitAmount:  productPlan.benAmount ? productPlan.benAmount : "",
        benifitDuration: productPlan.benDuration ? productPlan.benDuration : "",
        eliminationPeriod: productPlan.elimPeriod ? productPlan.elimPeriod : "",
        totalMonthlyPremium:  planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
        ownOccDefination:productPlan.defDisOwnOcc ? productPlan.defDisOwnOcc : "",
        defOfDis: productPlan.defOfDis ? productPlan.defOfDis : "",
        preEx: productPlan.preEx ? productPlan.preEx.exclusion ? productPlan.preEx.exclusion : "" : "",
        recurrent:  productPlan.recurrent ? productPlan.recurrent : "",
        earningTest: productPlan.earningsTest ? productPlan.earningsTest : "",
        ssOffset: "Full Family",
        rehab: productPlan.rehabBen ? productPlan.rehabBen : "",
        transplant: productPlan.transplantBen ? productPlan.transplantBen : "",
        mentalIllnessLimit: productPlan.mnSubAbuse ? productPlan.mnSubAbuse.mentalIllness ? productPlan.mnSubAbuse.mentalIllness : "" : "",
        rateGuarantee: "24",
        rateType: productPlan.rateType ? productPlan.rateType : "",
        renewalPlanType: isCurrent ? "current" : "renewing",
        platform: "CIRRUS",
        orderofPreference: "1",
        isPreferredPlan: true,
        benefitPercent: productPlan.benefitPercent ? productPlan.benefitPercent : "",
        maxMonthlyBenefit: productPlan.maxMnthBen ? productPlan.maxMnthBen : "",
        selected: true,
        favorited: false,
        quote: {
          ltdPlanCode: productPlan.planCode ? productPlan.planCode : "",
          rateGuarBegin: planRates[idx].rateGuarBegin ? planRates[idx].rateGuarBegin : "",
          rateGuarEnd: planRates[idx].rateGuarEnd ? planRates[idx].rateGuarEnd : "",
          totalMCP: "29333.33",
          totalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          timesRatesPer100ofMCP: "0.10",
          maximumMonthlyBenefit: "2933.33",
          employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
          employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
          ageBands: planRates[idx].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idx].rateTable.rate) : null ,
          quoteId: "0689330-01"
        },
        finalMonthlyPremium:planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
        finalRates: {
          ltdPlanCode: productPlan.planCode ? productPlan.planCode : "",
          rateGuarBegin: planRates[idx].rateGuarBegin ? planRates[idx].rateGuarBegin : "",
          rateGuarEnd: planRates[idx].rateGuarEnd ? planRates[idx].rateGuarEnd : "",
          totalMCP: "4730.17",
          totalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          timesRatesPer100ofMCP: "0.11",
          maximumMonthlyBenefit: "473.02",
          employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
          employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
          ageBands: planRates[idx].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idx].rateTable.rate) : null ,
          quoteId: "0689619-01"
        },
        selectedPackages: [
          "Single"
        ],
        platformRatingID: "0689619-01",
        quoteFinal: "5.20",
        hasDifferentPremium: true,
        employeeRatesAgeBanded: formatEmployeeRates( planRates[idx].employeeRates || []),
        employeeRatesComposite:  formatEmployeeRates( planRates[idx].employeeRates || []),
        byCensus: {
          finalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
      },
      byCensusShopping: {
        finalMonthlyPremium: planRates[idx].rateTotals[0].total ? planRates[idx].rateTotals[0].total : "",
          employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
      },
      employeeRates: formatEmployeeRates(planRates[idx].employeeRates || [])
      }
    return planObj
}



export const mapSuppLifePlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false) => {
  let idxEE = isCurrent ? planRates[0].renewalPlanType === "Current" ? 0 : 1 : planRates[0].renewalPlanType === "Renewal" ? 0 : 1 
  let idxSP = ""
  let idxCH = ""
  let employeePremium = []
  if(planRates.length > 2){
    idxCH = isCurrent ? planRates[2].renewalPlanType === "Current" ? 2 : 3 : planRates[2].renewalPlanType === "Renewal" ? 2 : 3
    idxSP = isCurrent ? planRates[4].renewalPlanType === "Current" ? 4 : 5 : planRates[4].renewalPlanType === "Renewal" ? 4 : 5
  }
  let chRate = idxCH !=="" ? planRates[idxCH].employeeRates[0].total : "0.0"
  let spRate = idxSP !=="" ? planRates[idxSP].employeeRates[0].total : "0.0"
  let eeID =  1
  planRates[idxEE].employeeRates && planRates[idxEE].employeeRates.map((rate) => {
    employeePremium.push({
      employeeId: eeID,
      slCHADnDPremium: "0.00",
      slCHPremium: chRate,
      slEEADnDPremium: "0.00",
      slEEPremium: rate.total,
      slSPADnDPremium: "0.00",
      slSPPremium: spRate,
    })
    eeID++;
  })
    let planObj = {
        code: productPlan[0].code ? productPlan[0].code : "",
        piaPlanCode: productPlan[0].piaPlanCode ? productPlan[0].piaPlanCode : "",
        benefitAmount: productPlan[0].benefitAmount ? productPlan[0].benefitAmount : "",
        productType: productPlan[0].productType ? productPlan[0].productType : "",
        planType: "Supplement Life",
        fundingType: productPlan[0].fundingType ? productPlan[0].fundingType : "",
        minimumParticipationPercentage: productPlan[0].minimumParticipationPercentage ? productPlan[0].minimumParticipationPercentage : "",
        guaranteedIssue: productPlan[0].guaranteedIssue ? productPlan[0].guaranteedIssue : "",
        planMax: productPlan[0].planMax ? productPlan[0].planMax : "",
        lifeBenefitType: productPlan[0].lifeBenefitType ? productPlan[0].lifeBenefitType : "",
        ageBenefitReductionSchedule: productPlan[0].ageBenefitReductionSchedule ? productPlan[0].ageBenefitReductionSchedule : "",
        coverageTermination: productPlan[0].coverageTermination ? productPlan[0].coverageTermination : "",
        suicideLimitBenefit: productPlan[0].suicideLimitBenefit ? productPlan[0].suicideLimitBenefit : "",
        acceleratedDeathBenefit: productPlan[0].acceleratedDeathBenefit ? productPlan[0].acceleratedDeathBenefit : "",
        lifeExpectancy: productPlan[0].lifeExpectancy ? productPlan[0].lifeExpectancy : "",
        eliminationPeriod: productPlan[0].eliminationPeriod ? productPlan[0].eliminationPeriod : "",
        primeInstallOption: productPlan[0].primeInstallOption ? productPlan[0].primeInstallOption : "",
        benefitDetails: productPlan[0].benefitDetails ? productPlan[0].benefitDetails : "",
        lifeBenefits: productPlan[0].lifeBenefits ? productPlan[0].lifeBenefits : "",
        rateType: productPlan[0].rateType ? productPlan[0].rateType : "",
        renewalPlanType: isCurrent ? "current" : "renewing",
        isPreferredPlan: productPlan[0].isPreferredPlan ? productPlan[0].isPreferredPlan : "false",  
        platform: productPlan[0].platform ? productPlan[0].platform : "CIRRUS",
        isMultiStatePlan: productPlan[0].isMultiStatePlan ? productPlan[0].isMultiStatePlan : "false",
        rateGuarantee: productPlan[0].rateGuarantee ? productPlan[0].rateGuarantee : "24",
        name: productPlan[0].name ? productPlan[0].name : "",
        adAndDBenefit: productPlan[0].adAndDBenefit ? productPlan[0].adAndDBenefit : "",
        SLEEADDPlanCode: productPlan[0].SLEEADDPlanCode ? productPlan[0].SLEEADDPlanCode : "",
        PIASLEEADDPlanCode: productPlan[0].PIASLEEADDPlanCode ? productPlan[0].PIASLEEADDPlanCode : "",
        SLSpPlanCode: productPlan[0].SLSpPlanCode ? productPlan[0].SLSpPlanCode : "",
        PIASLSpPlanCode: productPlan[0].PIASLSpPlanCode ? productPlan[0].PIASLSpPlanCode : "",
        SLSpADDPlanCode: productPlan[0].SLSpADDPlanCode ? productPlan[0].SLSpADDPlanCode : "",
        PIASLSpADDPlanCode: productPlan[0].PIASLSpADDPlanCode ? productPlan[0].PIASLSpADDPlanCode : "",
        SLChPlanCode: productPlan[0].SLChPlanCode ? productPlan[0].SLChPlanCode : "",
        PIASLChPlanCode: productPlan[0].PIASLChPlanCode ? productPlan[0].PIASLChPlanCode : "",
        SLChADDPlanCode: productPlan[0].SLChADDPlanCode ? productPlan[0].SLChADDPlanCode : "",
        PIASLChADDPlanCode: productPlan[0].PIASLChADDPlanCode ? productPlan[0].PIASLChADDPlanCode : "",
        SLEEADDPlan: productPlan[0].SLEEADDPlan ? productPlan[0].SLEEADDPlan : "",
        SLSpPlan: productPlan[0].SLSpPlan ? productPlan[0].SLSpPlan : "",
        SLSpADDPlan: productPlan[0].SLSpADDPlan ? productPlan[0].SLSpADDPlan : "",
        SLChPlan: productPlan[0].SLChPlan ? productPlan[0].SLChPlan : "",
        SLChADDPlan: productPlan[0].SLChADDPlan ? productPlan[0].SLChADDPlan : "",
        selected: true,
        favorited: false,
        filtered: true,
        finalMonthlyPremium:  planRates[idxSP].rateTotals[0].total ? planRates[idxSP].rateTotals[0].total : "" +
                              planRates[idxCH].rateTotals[0].total ? planRates[idxCH].rateTotals[0].total : ""+
                              planRates[idxEE].rateTotals[0].total ? planRates[idxEE].rateTotals[0].total : ""
        ,
        isDiscontinued,
        quote:{
          slEEADnDRate: planRates[idxEE].rateTotals[0].total ? planRates[idxEE].rateTotals[0].total : "",
          slSPADnDRate: planRates[idxSP].rateTotals[0].total ? planRates[idxSP].rateTotals[0].total : "",
          slCHADnDRate: planRates[idxCH].rateTotals[0].total ? planRates[idxCH].rateTotals[0].total : "",
          slCHRate: idxCH !=="" ? planRates[idxCH].rateTable?.rate ? planRates[idxCH].rateTable.rate[0].total : "" : "",

          totalSPMonthlyPremium: planRates[idxSP].rateTotals[0].total ? planRates[idxSP].rateTotals[0].total : "",
          totalCHMonthlyPremium: planRates[idxCH].rateTotals[0].total ? planRates[idxCH].rateTotals[0].total : "",
          totalEEMonthlyPremium: planRates[idxEE].rateTotals[0].total ? planRates[idxEE].rateTotals[0].total : "",
          slEEAgeBands: planRates[idxEE].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxEE].rateTable.rate) : null ,
          slSPAgeBands: idxSP !== "" ? planRates[idxSP].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxSP].rateTable.rate) : null : null,

        },
      finalRates: {
        byCensus: {
          employeePremium: employeePremium,
          productWiseTotalPremium:{
            totalSlCHADnDPremium : "See Benefit Summary",
            totalSlCHPremium: "",
            totalSlEEADnDPremium: "See Benefit Summary",
            totalSlEEPremium: "",
            totalSlSPADnDPremium: "See Benefit Summary",
            totalSlSPPremium: ""
          },
          slEEADnDRate: "See Benefit Summary", //need to map
          slSPADnDRate: "See Benefit Summary",//need to map
          slCHADnDRate: "See Benefit Summary",//need to map
          slCHRate: idxCH !=="" ? planRates[idxCH].rateTable?.rate ? planRates[idxCH].rateTable.rate[0].total : "" : "",

          totalSPMonthlyPremium:  idxSP !==""  ? planRates[idxSP].rateTotals[0].total ? planRates[idxSP].rateTotals[0].total : "" : "",
          totalCHMonthlyPremium: idxCH !==""? planRates[idxCH].rateTotals[0].total ? planRates[idxCH].rateTotals[0].total : "" : "",
          totalEEMonthlyPremium: planRates[idxEE].rateTotals[0].total ? planRates[idxEE].rateTotals[0].total : "",
          slEEAgeBands: planRates[idxEE].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxEE].rateTable.rate) : null,
          slSPAgeBands: idxSP !== "" ? planRates[idxSP].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxSP].rateTable.rate) : null : null,
          employeeRates: formatEmployeeRates(planRates[idxEE].employeeRates || [])
        },    
      },
      byCensus: {
        employeePremium: employeePremium,
        productWiseTotalPremium:{
          totalSlCHADnDPremium : "See Benefit Summary",
          totalSlCHPremium: "",
          totalSlEEADnDPremium: "See Benefit Summary",
          totalSlEEPremium: "",
          totalSlSPADnDPremium: "See Benefit Summary",
          totalSlSPPremium: ""
        },
        slEEADnDRate: "See Benefit Summary", //need to map
        slSPADnDRate: "See Benefit Summary",//need to map
        slCHADnDRate: "See Benefit Summary",//need to map
        slCHRate: idxCH !=="" ? planRates[idxCH].rateTable?.rate ? planRates[idxCH].rateTable.rate[0].total : "" : "",

        totalSPMonthlyPremium:  idxSP !==""  ? planRates[idxSP].rateTotals[0].total ? planRates[idxSP].rateTotals[0].total : "" : "",
        totalCHMonthlyPremium: idxCH !==""? planRates[idxCH].rateTotals[0].total ? planRates[idxCH].rateTotals[0].total : "" : "",
        totalEEMonthlyPremium: planRates[idxEE].rateTotals[0].total ? planRates[idxEE].rateTotals[0].total : "",
        slEEAgeBands: planRates[idxEE].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxEE].rateTable.rate) : null,
        slSPAgeBands: idxSP !== "" ? planRates[idxSP].rateTable.ratingMethod === "AgeBanded" ? formatAgeBandOxf(planRates[idxSP].rateTable.rate) : null : null,
        employeeRates: formatEmployeeRates(planRates[idxEE].employeeRates || [])
      },    
      
        

      }
    return planObj
}