import { axios } from '../../../../utils/api/axiosConfig';
import deepCopy from '../../../../utils/deepCopy';
import { isoStringToMMDDYYYY, YYYYMMDDtoIsoString, toRlYYYYMMDDFormat } from '../../../../utils/formatters/dates';
import { getAge, getAgeFromDOB } from '../../../../utils/formatters/numbers';
import { isEmpty } from '../../../../utils/formatters/strings';
import { generateRenewalCompanyProfileIntial, generateRenewalCompanyProfileNewFormat, generateRenewalEmployerEnrollmentNewFormat, saveCensusRefreshRenewPlanAsIs, saveReviewDetailContractChange } from '../RenewalsCommonComponents/saveDetails';
import { identifyPlatform, getExistingCoverageForSpecialty, getQuoteIdForSpecialty, getARQRatioForSpecialty, getExistingPlanSelectedVal, identifySpecialtyProductInCirrus, getExistingRatesedVal, getARQRatioForSpecialtyUhcRenewals } from '../Review/reviewUtils';
import { isUndefinedOrNullOrEmpty } from '../../../../utils/validation/commonValidations';
import { getMarketType, MARKET_TYPES, isUhcRenewals,getEligibleCountFromIbr } from '../../../../utils/businessLogic/platformUtils';
import { getZipObjUhcRenewals } from '../../../../utils/Renewal/getZipObjUhc';
import { logError } from '../../../../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../../../../utils/errorLogging/logKeys';
import { isPlanTwoTiered, isPlanThreeTiered } from '../../../../utils/businessLogic/isWashingtonTwoTier';
import { store } from '../../../..';
import {appendBasicLifeDetailsInReqBody} from '../../../../../src/actions/fullQuotesActions';
import { formatDate } from '../Review/reviewComponents/FilterIBR';

const apiRoutes = require('../../../../utils/api/apiRouteConfig')


export const getRatesDetailsFetcher = (fullProps, isPrimeIbr = false) => {
    const medicalPlan = {};
    const medicalPlanCodes = [];

    let location;
    let groupSize;
    const employerContribution = {};
    let fteCount;
    let atneCount;
    let eligiblesCount;
    const employeeClasses = {};
    const employeeCounts = {};

    const isUhcRenewal = isUhcRenewals();

    if (!isUhcRenewal &&  fullProps.rules && fullProps.rules.enrollment.enrolledPlans.standalone && fullProps.rules.enrollment.enrolledPlans.standalone.length > 0) {
        medicalPlan.Ny_shop = fullProps.rules.enrollment.enrolledPlans.standalone[0].isExchange;
        medicalPlan.platform = fullProps.rules.enrollment.enrolledPlans.standalone[0].platform ? fullProps.rules.enrollment.enrolledPlans.standalone[0].platform.toUpperCase() : '';
        fullProps.rules.enrollment.enrolledPlans.standalone.forEach((data) => {
            medicalPlanCodes.push(data.code);
        });
        medicalPlan.medicalPlanCodes = medicalPlanCodes;
    }else if(isUhcRenewal && fullProps.rules && fullProps.rules.enrollment.enrolledPlans){
        const enrolledPlans = [].concat(...Object.values(fullProps.rules.enrollment.enrolledPlans)) || [];
        if(enrolledPlans && enrolledPlans.length > 0){
            medicalPlan.Ny_shop = enrolledPlans[0].isExchange;
            medicalPlan.platform = enrolledPlans[0].platform ? enrolledPlans[0].platform.toUpperCase() : '';
            enrolledPlans.forEach((data) => {
                medicalPlanCodes.push(data.code);
            });
            medicalPlan.medicalPlanCodes = medicalPlanCodes;
        }
    }

    location = fullProps.companyProfile.locations[0]
    // groupSize = location.totalEmps

    employeeCounts.fteCount = parseInt(location.fte) > 0 ? parseInt(location.fte) : 0
    employeeCounts.atneCount = parseInt(location.atne) > 0 ? parseInt(location.atne) : 0
    // employeeCounts.eligiblesCount = parseInt(location.totalEligEmps)
    employeeCounts.eligiblesCount = isUhcRenewals() ? parseInt(location.totalEligEmps) : parseInt(location.totalEmps) > 0 ? parseInt(location.totalEmps) : 0

    if (fullProps.renewalEnrollment && fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup) {
        employeeClasses.id = fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses ? fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses[0]._id : '';
        employeeClasses.name = fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses ? fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses[0].name : '';

        if (fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution && fullProps.renewalEnrollment.mdvContribution) {
            employerContribution.productType = fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.productType||'Medical'; //selecting medical if product type doesn't come
            employerContribution.classId = fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.classId||1;//selecting medical if product type doesn't come
            employerContribution.subscriberType = fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.subscriber.type;
            employerContribution.subscriberValue = fullProps.renewalEnrollment.mdvContribution?.medical?.eValue ? fullProps.renewalEnrollment.mdvContribution.medical.eValue : fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.subscriber.value; 
            employerContribution.dependentType = fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.dependent.type;
            employerContribution.dependentValue = fullProps.renewalEnrollment.mdvContribution?.medical?.dValue ? fullProps.renewalEnrollment.mdvContribution?.medical?.dValue : fullProps.renewalEnrollment.renewalData.memberGroup.employerContribution.dependent.value;
        } else {
            employerContribution.productType = 'Medical';
            employerContribution.classId = '1';
            employerContribution.subscriberType = 'Percent';
            employerContribution.subscriberValue = fullProps.renewalEnrollment.mdvContribution?.medical?.eValue ? fullProps.renewalEnrollment.mdvContribution.medical.eValue : 0;
            employerContribution.dependentType = 'Percent';
            employerContribution.dependentValue = fullProps.renewalEnrollment.mdvContribution?.medical?.dValue ? fullProps.renewalEnrollment.mdvContribution?.medical?.dValue : 0;
        }
    }

    const effectiveDate = isoStringToMMDDYYYY(fullProps.renewalEnrollment.renewalCaseData.effectiveDate)
    let updatedCensus = []

    let employees = deepCopy(fullProps.companyProfile.employees.flat())
    let counter = 1
    employees.flat().map((employee, index) => {
        // if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0]['medical']) {
        //     if (!['', '-', 'select', 'waive'].includes(employee.productSelection[0]['medical'])) {
        let updatedDeps = []
        if (employee.dependents && employee.dependents.length > 0) {
            employee.dependents.map((dependent, i) => {
                if (employee.productSelection[i + 1] && !['', '-', 'select', 'waive'].includes(employee.productSelection[i + 1]['medical'])) {
                    dependent.age = dependent.dob ? getAgeFromDOB(dependent.dob, effectiveDate) : dependent.age ? dependent.age : 0
                    dependent.gender = dependent.gender ? dependent.gender.charAt(0) : ''
                    updatedDeps.push(dependent)
                }
            })
        }
        employee['dependents'] = updatedDeps
        // employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        employee['age'] = employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : employee.age ? employee.age : 0
        if (employee.employeeInfo) {
            employee.employeeInfo['gender'] = employee.gender.charAt(0)
        }

        if(employee.employeeStatus !== '1099 Independent Contractors'){
            updatedCensus.push(employee)
        }       
        // } 
        //     else {
        //         employee['dependents'] = []
        //         employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        //         employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        //         employee['age'] = employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : employee.age ? employee.age : 0
        //         if (employee.employeeInfo) {
        //             employee.employeeInfo['gender'] = employee.gender.charAt(0)
        //         }
        //         updatedCensus.push(employee)

        //     }
        // }
    })

    groupSize = isUhcRenewals() ? parseInt(location.totalEligEmps) :updatedCensus ? updatedCensus.length : location.totalEmps

    const companyName = fullProps.companyProfile.companyName

    // TO DO will remove this line once everything works fine.
    // const brokerID = (fullProps.renewalEnrollment.agentData && fullProps.renewalEnrollment.agentData[0].brokerPcisId) ? fullProps.renewalEnrollment.agentData[0].brokerPcisId.toString() : '';

    const zipObj = isUhcRenewal ? getZipObjUhcRenewals(fullProps.renewals.renewalCaseData) : {
        zipCode: location.zipData.zipCode,
        stateCode: location.zipData.stateCode,
        countyFIPSCode: !isUndefinedOrNullOrEmpty(location.zipData.countyFIPSCode) ? location.zipData.countyFIPSCode : ' ',
        county: !isUndefinedOrNullOrEmpty(location.zipData.county) ? location.zipData.county.slice(-3) : !isUndefinedOrNullOrEmpty(location.zipData.countyFIPSCode) ? location.zipData.countyFIPSCode : ' ',
        isPrimary: location.primaryInd
    }

    const validRequest = groupSize > 0 && !isEmpty(zipObj.zipCode) && !isEmpty(fullProps.renewalEnrollment.renewalCaseData.effectiveDate);

    if (validRequest) {
        const includeSpecialty = false;
        const selectedProducts = ['medical'];
        const viewSpecialty = {
            dental: false,
            life: false,
            ltd: false,
            medical: true,
            std: false,
            sul: false,
            vision: false,
        };

        const originalSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode !== '' ? fullProps.renewalEnrollment.renewalData.memberGroup.industryCode : null
        const updatedSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.userEnteredSic && fullProps.renewalEnrollment.renewalData.userEnteredSic !== '' ? fullProps.renewalEnrollment.renewalData.userEnteredSic : null
        const sicCode = originalSIC ? originalSIC : updatedSIC ? updatedSIC : '9900'

        const otherDetails = {
            includeSpecialty,
            selectedProducts,
            viewSpecialty,
            sicCode,
        };
        const companyProfile = {
            companyName,
            sicCode,
        };

        var shoppingReq = {
            groupSize,
            zipObj,
            effectiveDate: fullProps.renewalEnrollment.renewalCaseData.effectiveDate ? fullProps.renewalEnrollment.renewalCaseData.effectiveDate : '',
            //effectiveDate: '2021-02-01T00:00:00.000Z',
            employeeCounts,
            employeeClasses,
            employees: updatedCensus,
            empCensus: updatedCensus,
            // TO DO will remove this line once everything works fine.
            // brokerID,
            includeSpecialty: false,
            companyProfile,
            Ny_shop: medicalPlan.Ny_shop,
            otherDetails,
            memberGroupId: fullProps.companyProfile.memberGroupId,
        };

        shoppingReq.parentId = fullProps.caseTracking.currentQuoteId;
        if (!isPrimeIbr)
            shoppingReq.employerContribution = employerContribution

    }

    return [medicalPlan, shoppingReq];
};

export const callRefreshApis = async (priorYearDataSelected, rules, quoteTrackingNumber, renewalData, companyProfile, userName, premium, isDecide = false, memberGroupId, renewalQuoteId, platform, allowPrimeRefreshCall = false, renewAsIs = false) => {
    
    //TODO: Remove the below if block once the refresh apis are working for uhc renewals
    // if(isUhcRenewals()) {
    //     return {
    //         companyProfileUpdatedResponse: companyProfile,
    //         renewalDataUpdatedResponse: renewalData,
    //         contractOptList: []
    //     };
    // }

    const fullProps = store.getState();

    let renewalDataCopy = renewalData
    if(Object.keys(renewalDataCopy).length < 1 || Object.keys(renewalDataCopy.renewalCaseData).length < 1 || Object.keys(renewalDataCopy.renewalData).length < 1){
        renewalDataCopy = fullProps.renewals || {}
    }

    const { isEnrolled, renewalCaseData } = renewalDataCopy;
    if (isEnrolled && isEnrolled === true) {
        let contractOptList = [];
        const marketType = getMarketType();
        const effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate;
        const memberGroupIdFinal = renewalDataCopy?.renewalData?.memberGroup?.memberGroupId ? renewalDataCopy.renewalData.memberGroup.memberGroupId : memberGroupId
        const res = await axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${memberGroupIdFinal}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`).catch(err => {
                if(isUhcRenewals()) {
                    const reqBody = {
                        memberGroupId: memberGroupIdFinal,
                        marketType, quoteTrackingNumber, priorYearDataSelected
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'RenewalEnrollmentUtils.callRefreshApis', reqBody, err);
                }
                throw err;
            });
        if (res.data) {
            const { employees, employerEnrollment } = res.data;
            // need to optimize below condition for uhcRenewals
            if (employees.length === 0 || (employees.length > 0 && employees[0].length === 0) || (employees[0] && employees[0].length === 0) || JSON.stringify(employerEnrollment) === '{}' || JSON.stringify(employerEnrollment.employer) === '{}' || JSON.stringify(employerEnrollment.employer.generalInformation) === '{}' || !employerEnrollment.employer.generalInformation.requestedEffectiveDate || !employerEnrollment.employer.generalInformation.requestedEffectiveDate === '') {
                const { responseEnrolledGroup, responseEnrolledEmployees } = await recallEnrollEmployeeAndgroupEnrollmentDetailsApis(quoteTrackingNumber, renewalDataCopy, allowPrimeRefreshCall);
                if (responseEnrolledGroup.data && responseEnrolledEmployees.data) {
                    const { companyProfileUpdatedResponse, renewalDataUpdatedResponse } = await updateContractChange(rules, responseEnrolledGroup.data, responseEnrolledEmployees.data, quoteTrackingNumber, renewalDataCopy, userName, premium, isDecide, memberGroupId, renewalQuoteId, platform)
                    return { companyProfileUpdatedResponse, renewalDataUpdatedResponse };
                }
            }
        }
        if (renewalDataCopy) {
            const contractOptionObj = { data: renewalDataCopy.contractOptions ? renewalDataCopy.contractOptions : {} }
            contractOptList = await getContractOptData(contractOptionObj, renewalCaseData);
        }
        return {
            companyProfileUpdatedResponse: companyProfile,
            renewalDataUpdatedResponse: renewalDataCopy,
            contractOptList: contractOptList
        };
    }

    // Need to make if user hasn't navigated to the Enrollment page ie., isEnrolled === false
    try {
        const { responseEnrolledGroup, responseEnrolledEmployees, contractOptionData } = await refreshCensusGroupContractOptions(quoteTrackingNumber, renewalDataCopy, allowPrimeRefreshCall, renewAsIs, priorYearDataSelected);
        const contractOptList = await getContractOptData(contractOptionData, renewalCaseData);
        if (responseEnrolledGroup.data && responseEnrolledEmployees.data) {
            const { companyProfileUpdatedResponse, renewalDataUpdatedResponse } = await updateContractChange(rules, responseEnrolledGroup.data, responseEnrolledEmployees.data, quoteTrackingNumber, renewalDataCopy, userName, premium, isDecide, memberGroupId, renewalQuoteId, platform, contractOptList, priorYearDataSelected)
            return { companyProfileUpdatedResponse, renewalDataUpdatedResponse, contractOptList };
        }

        return {
            companyProfileUpdatedResponse: companyProfile,
            renewalDataUpdatedResponse: renewalDataCopy,
            contractOptList: contractOptList
        };
    } catch (err) {
        throw err;
    }
}

const getNiceEmployees = (census,plans) => {
    let nicePlans = new Set();
    for(let i=0;i<plans.length;i++){
        let plan = plans[i];
        let cdd = plan.clientDefinedData.data;
        for(let j=0;j<cdd.length;j++){
            if(cdd[j].key === "PlatformIdentifier" && cdd[j].value === "NICE"){
                nicePlans.add(plan.planID);
                break;
            }
        }
    }
    const employeeArray = [];

    for(let i=0;i<census.length;i++){
        let employeeData = {
            employeeSSN : "",
            coverageType : "",
            medicalPlans : [],
            dependents : [],
        };
        let isNiceEmployee = false;
        let employee = census[i];
        let renewingPlans = employee.renewingPlans.plan;
        for(let j=0;j<renewingPlans.length;j++){
            let renewingPlan = renewingPlans[j];
            if(nicePlans.has(renewingPlan.planID)){
                if(employeeData.employeeSSN === ""){
                    employeeData.employeeSSN = employee.ssn;
                }
                if(employeeData.coverageType === ""){
                    employeeData.coverageType = renewingPlan.coverageType
                }
                employeeData.medicalPlans.push(renewingPlan.planID);
                isNiceEmployee = true;
            }
        }

        if(isNiceEmployee && employee.hasOwnProperty("dependents")){
            employee.dependents.dependent.forEach((dependent)=> {
                let dependentData = {
                    dependentXREF : "",
                    dependentType : "",
                    medicalPlans : [],
                };
                let renewingPlans = dependent.renewingCoverageElections.plan;
                for(let i=0;i<renewingPlans.length;i++){
                    let renewingPlan = renewingPlans[i];
                    if(nicePlans.has(renewingPlan.planID)){
                        if(dependentData.dependentXREF === ""){
                            dependentData.dependentXREF = dependent.xref;
                        }
                        if(dependentData.dependentType === ""){
                            dependentData.dependentType = dependent.depType
                        }
                        dependentData.medicalPlans.push(renewingPlan.planID);
                    }
                }

                employeeData.dependents.push(dependentData)
            })
        }
        if(isNiceEmployee){
            employeeArray.push(employeeData);
        }
    }
    return employeeArray
}


const checkCoverage = (dependents) => {
    const depArray = dependents.dependent;
    let hasChild = false;
    let hasSpouse = false;
    depArray.forEach((dependent)=>{
        if(dependent.depType === 'S'){
            hasSpouse = true;
        }
        if(dependent.depType === 'C'){
            hasChild = true;
        }
    })
    if(hasSpouse && hasChild){
        return "EE/FAM"
    }else if(hasChild){
        return "EE/CH"
    }else{
        return "EE/SP"
    }
}


const makeDependentsBody = (dependent,employee , medicalCoverage) => {
    const zipCode = employee.clientDefinedData.data.find(data=> data.key === "Zip")?.value;
    const streetAddress =  employee.clientDefinedData.data.find(data=> data.key === "EmployeeAddr1")?.value
    const depData = {
        "firstName": dependent.first ? dependent.first : "",
        "middleInitial": "",
        "lastName": dependent.last ? dependent.last : "",
        "suffix": "",
        "ssnTin": dependent.ssn ? dependent.ssn : "",
        "noSSNReason": "",
        "ssnCheck": false,
        "dob": formatDate(dependent.dateOfBirth.month) + '/' + formatDate(dependent.dateOfBirth.day) + '/' + dependent.dateOfBirth.year,
        "gender": dependent.genderType === "F" ? "Female" : "Male",
        "medicarePrimary": "No",
        "isDisabled": "No",
        "memberId": 1234,
        "relationship": dependent.depType === 'S' ? "Spouse" : "Child",
        "subscriberID":  employee.xref ? employee.xref : "",
        "streetAddress": streetAddress,
        "streetAddressln2": "",
        "city": "Los Angeles",
        "state": "CA",
        "zipCode": zipCode,
        "zipCode4": "",
        "telephone": "",
        "outOfArea": "No",
        "addressDifferentThanEmployee": "No",
        "pcpAssignment": [],
        "newDependent": true,
        "productSelection": [
            {
                "medical": medicalCoverage,
                "dental": "",
                "vision": "",
                "life": "",
                "depLife": ""
            }
        ],
        "age": getAge(formatDate(dependent.dateOfBirth.month) + '/' + formatDate(dependent.dateOfBirth.day) + '/' + dependent.dateOfBirth.year)
    }
    return depData;
}

const makeRequestBody = (employee, medicalCoverage) => {
    const zipCode = employee.clientDefinedData.data.find(data=> data.key === "Zip")?.value;
    const dateofHire = employee.clientDefinedData.data.find(data=> data.key === "EmployeeDOE")?.value
    const streetAddress =  employee.clientDefinedData.data.find(data=> data.key === "EmployeeAddr1")?.value
    let coverage = employee.hasOwnProperty("dependents") ? checkCoverage(employee.dependents) : "EE"

    const dateObj = dateofHire.split("-");
    const dateFinal = dateObj[2] +"/" + dateObj[1] + "/" + dateObj[0]
    const employeeData = {
        "firstName": employee.first ? employee.first : "",
        "middleInitial": "",
        "lastName": employee.last ? employee.last : "",
        "suffix": "",
        "ssnTin": employee.ssn ? employee.ssn : "",
        "noSSNReason": "",
        "ssnCheck": false,
        "dob": formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
        "gender": employee.genderType === "F" ? "Female" : "Male",
        "numberOfHoursWorked": "",
        "dateOfHire": dateofHire,
        "salary": "",
        "status": "In Progress",
        "employeeStatus": employee.status ? employee.status : "",
        "medicarePrimary": "No", 
        "nameOtherCarrier": "",
        "anyOtherCoverage": "No",
        "memberId": employee.xref ? employee.xref : "",
        "relationship":  "Self",
        "subscriberID": employee.xref ? employee.xref : "",
        "existingEmpStatus": "A",
        "newEmployee": true,
        "coverages": {
            "life": "EL",
            "dental": "EE",
            "ltd": "EE",
            "medical": coverage,
            "std": "EE",
            "vision": "EE"
        },
        "zipCode": zipCode,
        "streetAddress": streetAddress,
        "city": "Los Angeles",
        "telephone": "",
        "stateCode": "CA",
        "employeeInfo": {
            "firstName": employee.first ? employee.first : "",
            "middleInitial": "",
            "lastName": employee.last ? employee.last : "",
            "suffix": "",
            "ssnTin": employee.ssn ? employee.ssn : "",
            "noSSNReason": "",
            "ssnCheck": false,
            "dob": formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year,
            "gender": employee.genderType === "F" ? "Female" : "Male",
            "numberOfHoursWorked": "",
            "dateOfHire": dateFinal,
            "salary": "",
            "status": "In Progress",
            "employeeStatus": employee.status ? employee.status : "",
            "medicarePrimary": "No",
            "nameOtherCarrier": "",
            "anyOtherCoverage": "No",
            "memberId": employee.xref ? employee.xref : "",
            "relationship": "Self",
            "subscriberID": employee.xref ? employee.xref : "",
            "existingEmpStatus": "A",
            "newEmployee": true
        },
        "contactInfo": {
            "streetAddress": streetAddress,
            "streetAddressln2": "",
            "city":  "Los Angeles",
            "state": "CA",
            "zipCode": {
                "key": "",
                "verbosetext": zipCode + " - Los angeles, CA (Los angeles County)",
                "compacttext": zipCode +", CA - Los angeles",
                "text": zipCode + " - Los angeles, CA (Los angeles County)",
                "value": zipCode + " - Los angeles, CA (Los angeles County)",
                "data": {
                    "zipCode": zipCode,
                    "city": "LOS ANGELES",
                    "stateCode": "CA",
                    "state": "CA",
                    "stateFIPSCode": "",
                    "county": "Los angeles",
                    "countyFIPSCode": ""
                }
            },
            "zipCode4": zipCode,
            "telephone": "",
            "telephoneType": "",
            "outOfArea": "No",
            "email": "",
            "county": "Los angeles"
        },
        "pcpAssignment": [],
        "productSelection": [
            {
                "medical": medicalCoverage,
                "dental": "",
                "vision": "",
                "life": "",
                "depLife": ""
            }
        ],
        "dependents": [],
        "waiveCoverage": {
            "isWaivingAll": "No",
            "medicalWaiverReason": ""
        },
        "waiveCoverageCopy": {
            "isWaivingAll": "No",
            "medicalWaiverReason": ""
        },
        "errors": {
            "firstName": null,
            "lastName": null,
            "dob": null,
            "age": null,
            "gender": null,
            "employeeStatus": null,
            "coverages": null,
            "outOfArea": null,
            "salary": null,
            "zipCode": null
        },
        "age": getAge(formatDate(employee.dateOfBirth.month) + '/' + formatDate(employee.dateOfBirth.day) + '/' + employee.dateOfBirth.year)
    }
    if(employee.hasOwnProperty("dependents")){
        const depArray = employee.dependents.dependent
        for(let i = 0 ;i < depArray.length; i++){
            let depBody = makeDependentsBody(depArray[i],employee,medicalCoverage)
            employeeData.dependents.push(depBody);
        }
    }
    return employeeData
}
  

const updateContractChange = async (rules, responseEnrolledGroupData, responseEnrolledEmployeesData, quoteTrackingNumber, renewalData, userName, premium, isDecide = false, memberGroupId, renewalQuoteId, platform, contractOptList, priorYearDataSelected = 'N') => {
    const groupData = responseEnrolledGroupData;
    const employeesData = responseEnrolledEmployeesData;
    const marketType = getMarketType()
    const employerEnrollment = generateRenewalEmployerEnrollmentNewFormat(groupData);
    let latestRenewalCompanyProfile = generateRenewalCompanyProfileNewFormat(employeesData, renewalData.renewalData, employerEnrollment);
    const fullProps = store.getState();
    const situsState = responseEnrolledGroupData.renewalCaseData.situsState;
    const isInternalGrp = responseEnrolledGroupData.renewalCaseData.samxInternalGrp === 'Y';
    const isDual =  responseEnrolledGroupData.renewalCaseData.custType === 'DUAL'
    const isMergingAllowed = fullProps.overall.appConfigDetails.allowMerge ? fullProps.overall.appConfigDetails.allowMerge : false;
    if(isUhcRenewals() && situsState === "CA" && isDual && isInternalGrp && isMergingAllowed && memberGroupId !== undefined && memberGroupId !== " "){
        const availableMedicalPlans = responseEnrolledGroupData.enrolledPlans;
        const medicalPlanSet = new Set();
        const niceEmployeeSet = new Set();
        let census = renewalData.renewalData.Ibr.quote.census.employee;
        let selectedPlanCode =  "";

        availableMedicalPlans.forEach((plan)=>{
            medicalPlanSet.add(plan.medicalPlanCode);
        })

        const niceEmployees = getNiceEmployees(renewalData.renewalData.Ibr.quote.census.employee , renewalData.renewalData.Ibr.quote.planOfferingData[0].newPlanPkg.plans.plan);

        for(let i=0;i<niceEmployees.length;i++){
            let niceEmployee = niceEmployees[i];

            let selectedPlan = "";
            niceEmployee.medicalPlans.forEach((plan) => {
                if(medicalPlanSet.has(plan)){
                    selectedPlan = plan;
                }
            })

            let min = Number.MAX_SAFE_INTEGER;
            if(selectedPlan === ""){
                availableMedicalPlans.forEach((plan) => {
                    if(plan.quote < min){
                        min = plan.quote;
                        selectedPlan = plan.medicalPlanCode;
                    }
                })
            }

            selectedPlanCode = selectedPlan.split("/")[0];
            
            let employees = latestRenewalCompanyProfile.employees[0];
            let isCommonEmployee = false;
            for(let j=0;j<employees.length;j++){
                const employee = employees[j];
                if(niceEmployee.employeeSSN === employee.ssnTin){
                    latestRenewalCompanyProfile.employees[0][j].productSelection[0].medical = selectedPlanCode;
                    isCommonEmployee = true;

                    if(latestRenewalCompanyProfile.employees[0][j].hasOwnProperty("dependents")){
                        let dependents = latestRenewalCompanyProfile.employees[0][j].dependents;
                        for(let k=0; k < dependents.length; k ++){
                            latestRenewalCompanyProfile.employees[0][j].dependents[k].productSelection[0].medical = selectedPlanCode;
                        }

                    }   
                }
            }
            if(!isCommonEmployee){
                niceEmployeeSet.add(niceEmployee.employeeSSN);
            }
        }

        
        for(let i =0 ; i < census.length ; i++){
            if(niceEmployeeSet.has(census[i].ssn)){
                let employeeData = makeRequestBody(census[i],selectedPlanCode);
                latestRenewalCompanyProfile.employees[0].push(employeeData);
            }
        }
    }

    const responseCompanyProfile = await axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${renewalData.renewalData.memberGroup.memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=${priorYearDataSelected}`).catch(err => {
        if(isUhcRenewals()) {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber, priorYearDataSelected: 'N'
            }
            logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'RenewalEnrollmentUtils.updateContractChange', reqBody, err);
        }
        throw err;
    });

    let companyProfileToBeSaved = deepCopy(responseCompanyProfile.data);
    companyProfileToBeSaved.employees = latestRenewalCompanyProfile.employees;
    companyProfileToBeSaved.employerEnrollment = latestRenewalCompanyProfile.employerEnrollment;
    companyProfileToBeSaved.quoteTrackingNumber = quoteTrackingNumber;
    companyProfileToBeSaved.marketType = marketType;

    await axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, companyProfileToBeSaved).catch(err => {
        if(isUhcRenewals()) {
            logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'RenewalEnrollmentUtils.updateContractChange', companyProfileToBeSaved, err);
        }
        throw err;
    });
    const companyProfileUpdatedResponse = companyProfileToBeSaved

    const query = await saveCensusRefreshRenewPlanAsIs(rules, renewalData, premium, isDecide, quoteTrackingNumber, contractOptList)
    const renewalUpdateRoute = marketType === MARKET_TYPES.UHC ? '/uhcRenewal/renewalUpdateUhc/' : '/renewalUpdate/'
    await axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query).catch(err => {
        if(isUhcRenewals()) {
            logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalEnrollmentUtils.updateContractChange', query, err);
        }
        throw err;
    });

    let renDataResponse = ""
    if(memberGroupId && renewalQuoteId && platform)
        renDataResponse = marketType === MARKET_TYPES.OXFORD ? await axios.get(`${apiRoutes.quickQuotes}/renewalReview?memberGroupId=${memberGroupId}&renewalQuoteId=${renewalQuoteId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}`)
        : await axios.get(`${apiRoutes.quickQuotes}/uhcRenewal/renewalReviewUhc?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=PRIME`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId, quoteTrackingNumber, platform: 'PRIME'
                }
                logError(KEY_NAME, UHC_REN_APIS.RENEWAL_REVIEW, 'RenewalEnrollmentUtils.updateContractChange', reqBody, err);
            }
            throw err;
        })
    const renewalDataUpdatedResponse = renDataResponse && renDataResponse.data ? renDataResponse.data : {}

    return { companyProfileUpdatedResponse, renewalDataUpdatedResponse }
}

export const callRefreshApisFromDashboard = async (rules, quoteTrackingNumber, renewalData, companyProfile, userName, premium, isDecide = false, source = '', memberGroupId, renewalQuoteId, platform, allowPrimeRefreshCall = false, renewAsIs = false, isAutomateMMR = false) => {

    const { isEnrolled, renewalCaseData } = renewalData;

    if (isEnrolled && isEnrolled === true) {
        let contractOptList = {};
        const marketType = getMarketType();
        const res = await axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${renewalData.renewalData.memberGroup.memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=N`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                    marketType, quoteTrackingNumber, priorYearDataSelected: 'N'
                }
                logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'RenewalEnrollmentUtils.callRefreshApisFromDashboard', reqBody, err);
            }
            throw err;
        });
        if (res.data) {
            const { employees, employerEnrollment } = res.data;
            if (employees.length === 0 || (employees.length > 0 && employees[0].length === 0) || JSON.stringify(employerEnrollment) === '{}' || JSON.stringify(employerEnrollment.employer) === '{}' || JSON.stringify(employerEnrollment.employer.generalInformation) === '{}' || !employerEnrollment.employer.generalInformation.requestedEffectiveDate || !employerEnrollment.employer.generalInformation.requestedEffectiveDate === '') {
                const { responseEnrolledGroup, responseEnrolledEmployees } = await recallEnrollEmployeeAndgroupEnrollmentDetailsApis(quoteTrackingNumber, renewalData, allowPrimeRefreshCall);
                if (responseEnrolledGroup.data && responseEnrolledEmployees.data) {
                    const { companyProfileUpdatedResponse, renewalDataUpdatedResponse } = await updateContractChange(rules, responseEnrolledGroup.data, responseEnrolledEmployees.data, quoteTrackingNumber, renewalData, userName, premium, isDecide)
                    return { companyProfileUpdatedResponse, renewalDataUpdatedResponse };
                }
            }
        }
        if (renewalData) {
            const contractOptionObj = { data: renewalData.contractOptions ? renewalData.contractOptions : {} }
            contractOptList = await getContractOptData(contractOptionObj, renewalCaseData);
        }
        return {
            companyProfileUpdatedResponse: companyProfile,
            renewalDataUpdatedResponse: renewalData,
            contractOptList: contractOptList
        };
    }

    try {
        const responseRenewalUpdate = await createRenewalUpdateAndContractChange(source, renewalData, userName, quoteTrackingNumber);
        const { responseEnrolledGroup, responseEnrolledEmployees, contractOptionData } = await refreshCensusGroupContractOptions(quoteTrackingNumber, responseRenewalUpdate, allowPrimeRefreshCall, renewAsIs, 'N', isAutomateMMR);
        const contractOptList = await getContractOptData(contractOptionData, renewalCaseData);
        if (responseEnrolledGroup.data && responseEnrolledEmployees.data) {
            const { companyProfileUpdatedResponse, renewalDataUpdatedResponse } = await updateContractChange(rules, responseEnrolledGroup.data, responseEnrolledEmployees.data, quoteTrackingNumber, responseRenewalUpdate, userName, premium, isDecide, memberGroupId, renewalQuoteId, platform, contractOptList)
            return { companyProfileUpdatedResponse, renewalDataUpdatedResponse, contractOptList };
        }

        return {
            companyProfileUpdatedResponse: companyProfile,
            renewalDataUpdatedResponse: responseRenewalUpdate,
            contractOptList: contractOptList
        };

    } catch (err) {
        throw err;
    }
}

const createRenewalUpdateAndContractChange = (source = '', renewalData, userName, quoteTrackingNumber) => new Promise(async (resolve, reject) => {
    if (source === 'dashboard' && !renewalData.renewalData.isCompanyProfileUpdated) {
        const reqParams = await saveReviewDetailContractChange(renewalData, false, true);
        reqParams.quoteTrackingNumber = quoteTrackingNumber;
        const marketType = getMarketType()
        const renewalUpdateRoute = marketType === MARKET_TYPES.UHC ? '/uhcRenewal/renewalUpdateUhc/' : '/renewalUpdate/'
        axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, reqParams)
            .then(async response => {
                const stateCode = renewalData?.renewalCaseData?.situsState;
                let rulesResponse = isUhcRenewals() ? await axios.get(`${apiRoutes.mongo}/atne-rules/${stateCode}`).catch(err => {
                    logError(KEY_NAME, UHC_REN_APIS.ATNE_RULES, 'renewalEnrollmentUtils.createRenewalUpdateAndContractChange', {stateCode}, err);
                }) : ""
                const renewalCompanyProfileNewFormat = generateRenewalCompanyProfileIntial(renewalData, quoteTrackingNumber, rulesResponse?.data?.rule || "");
                renewalCompanyProfileNewFormat.marketType = getMarketType();
                axios.post(`${apiRoutes.mongo}/profiles?userName=${userName}`, renewalCompanyProfileNewFormat)
                    .then((responseofPostRequest) => {
                        resolve(reqParams)
                    });
            })
    } else {
        resolve(renewalData)
    }
})

const coverageMapping = {
    "EO": "EE",
    "EC": "EE/CH",
    "ES": "EE/SP",
    "EF": "EE/FAM",
}

export const getDVLRatesDetailFetcher = (fullProps, service, requestData, queryData) => {

    let reqBody = {},
        groupSize, zipCode, stateCode, countyCode, location;
    const employeeClasses = {};
    let identifiedPlatform = '';
    let finalPlatform = '';
    let existingCoverage = [];
    let quoteId = '';
    if (queryData && queryData.renewalData) {
        identifiedPlatform = identifyPlatform(queryData.renewalData);
        finalPlatform = identifiedPlatform === 'DUAL' ? 'PRIME' : identifiedPlatform;
        existingCoverage = getExistingCoverageForSpecialty(queryData.renewalData);
        if (isUhcRenewals()) {
            quoteId = requestData.quoteId;
        } else {
            quoteId = getQuoteIdForSpecialty(queryData, service);
        }
    }
    if(isUndefinedOrNullOrEmpty(quoteId) && isUhcRenewals() && requestData.quoteStatus === 'Installation' ){
        let rateObject = '';
        let serviceType = '';
        // if(requestData.quoteStatus === 'Installation'){
        //     rateObject = 'CloseEnrollment';
        // }else if(requestData.quoteStatus === 'CloseEnrollment'){
        //     rateObject = 'MoveToEnroll';
        // }

        if(service === 'DENTAL'){
            serviceType = 'dentalRates';
        }else if(service === 'VISION'){
            serviceType = 'visionRates'
        }else if(service === 'BASICLIFE'){
            serviceType = 'lifeRates'
        }
    }
    if (service === 'DENTAL') {
        reqBody.enrolledDentalPlans = fullProps.rules.enrollment.enrolledDentalPlans;
        reqBody.dentalPlanCodes = fullProps.rules.enrollment.enrolledDentalPlans.map(plan => { return plan.code });
        if (identifiedPlatform === 'PRIME' || identifiedPlatform === 'DUAL') {
            if (isUhcRenewals()) {
                let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(queryData.renewalData, service.toUpperCase())
                reqBody.arqRatio = fullProps.rules.enrollment.enrolledDentalPlans.map(_ => renewingPlanARQRatio)
            } else {
                reqBody.arqRatio = fullProps.rules.enrollment.enrolledDentalPlans.map(plan => { return getARQRatioForSpecialty(queryData.renewalData, service, plan.code) })
            }
        }
    }
    if (service === 'VISION') {
        reqBody.enrolledVisionPlans = fullProps.rules.enrollment.enrolledVisionPlans;
        reqBody.visionPlanCodes = fullProps.rules.enrollment.enrolledVisionPlans.map(plan => { return plan.code });
        if (identifiedPlatform === 'PRIME' || identifiedPlatform === 'DUAL') {
            if (isUhcRenewals()) {
                let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(queryData.renewalData, service.toUpperCase())
                reqBody.arqRatio = fullProps.rules.enrollment.enrolledVisionPlans.map(_ => renewingPlanARQRatio)
            } else {
                reqBody.arqRatio = fullProps.rules.enrollment.enrolledVisionPlans.map(plan => { return getARQRatioForSpecialty(queryData.renewalData, service, plan.code) })
            }
        }
    }

    if (isUhcRenewals() && (service === 'DENTAL' || service === 'VISION')) {
        let enrolledPlans = service === 'DENTAL' ? 
            reqBody.enrolledDentalPlans : reqBody.enrolledVisionPlans;

        if(enrolledPlans && enrolledPlans.length > 0) {
            const tierStructureId = enrolledPlans[0].tierStructureId;

            if(isPlanTwoTiered(tierStructureId)) {
                reqBody.ratingTier = '2 Tier'
            }

            if(isPlanThreeTiered(tierStructureId)) {
                reqBody.ratingTier = '3 Tier'
            }
        }
    }

    if (service === 'BASICLIFE') {
        return getBasicLifeDetailRequest(fullProps, finalPlatform, existingCoverage, requestData.quoteStatus, quoteId, requestData)
    }
        
    const renewalCaseDataObj = fullProps.renewalEnrollment && fullProps.renewalEnrollment.renewalCaseData ? fullProps.renewalEnrollment.renewalCaseData: fullProps.decide && fullProps.decide.renewalCaseData ? fullProps.decide.renewalCaseData: fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.renewalCaseData ? fullProps.renewalShoppingHelper.renewalCaseData: fullProps.review && fullProps.review.renewalCaseData ? fullProps.review.renewalCaseData: fullProps.renewals && fullProps.renewals.renewalCaseData ? fullProps.renewals.renewalCaseData:{};
    const dentalArray = fullProps.renewalEnrollment && fullProps.renewalEnrollment.dental && fullProps.renewalEnrollment.dental.set  ? fullProps.renewalEnrollment.dental.set: fullProps.decide && fullProps.decide.dental && fullProps.decide.dental.set  ? fullProps.decide.dental.set: fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.dental && fullProps.renewalShoppingHelper.dental.set  ? fullProps.renewalShoppingHelper.dental.set: fullProps.review && fullProps.review.dental && fullProps.review.dental.set ? fullProps.review.dental.set: fullProps.renewals && fullProps.renewals.dental && fullProps.renewals.dental.set ? fullProps.renewals.dental.set: [];
    const visionArray = fullProps.renewalEnrollment && fullProps.renewalEnrollment.vision && fullProps.renewalEnrollment.vision.set  ? fullProps.renewalEnrollment.vision.set: fullProps.decide && fullProps.decide.vision && fullProps.decide.vision.set  ? fullProps.decide.vision.set: fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.vision && fullProps.renewalShoppingHelper.vision.set  ? fullProps.renewalShoppingHelper.vision.set: fullProps.review && fullProps.review.vision && fullProps.review.vision.set ? fullProps.review.vision.set: fullProps.renewals && fullProps.renewals.vision && fullProps.renewals.vision.set ? fullProps.renewals.vision.set: [];

    if (service === 'DENTAL' && fullProps.rules.enrollment.enrolledDentalPlans.length > 0 || service === 'VISION' && fullProps.rules.enrollment.enrolledVisionPlans.length > 0) {
        // US3947255: Rate Guarantee - Get Rates API Updates
        const { optumId } = fullProps.renewals.logInData[0];
        reqBody.optumId = optumId;
        const isExistingPlanSelected = identifiedPlatform !== 'PRIME' ? getExistingPlanSelectedVal(queryData.renewalData, fullProps.rules.enrollment, service) : null;

        const identifiedProduct = identifySpecialtyProductInCirrus(renewalCaseDataObj.product, service.toUpperCase());
        const plansSet = service.toUpperCase() === 'DENTAL' && dentalArray ? dentalArray: service.toUpperCase() === 'VISION' && visionArray ? visionArray: [];
        const planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
        
        reqBody.existingPlanRates = planRates;
        reqBody.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
        reqBody.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : '';
        reqBody.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : '';
        if(isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
            reqBody.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
            reqBody.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
            reqBody.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded: ''; 
        } else {
            if(identifiedProduct) {
                const getProductAttribute = queryData && queryData.renewalData && queryData.renewalData.productAttributes && queryData.renewalData.productAttributes.find((productType) => service.toUpperCase() === productType.product.toUpperCase())
                reqBody.existingProductSelected = 'true';
                reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
            }else if(!identifiedProduct) {
                reqBody.newProductAdded = 'true';
            }
        }
    }
    reqBody.requestType = 'renewal';

    employeeClasses.id = fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses ? fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses[0]._id : '';
    employeeClasses.name = fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses ? fullProps.renewalEnrollment.renewalData.memberGroup.employeeClasses[0].name : '';

    const effectiveDate = isoStringToMMDDYYYY(fullProps.renewalEnrollment.renewalCaseData.effectiveDate)
    let updatedCensus = []
    let employees = deepCopy(fullProps.companyProfile.employees.flat())

    let counter = 1
    employees.flat().map((employee, index) => {
        // if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0][service.toLowerCase()]) {
        // if (!['', '-', 'select', 'waive'].includes(employee.productSelection[0][service.toLowerCase()])) {
        let updatedDeps = []
        if (employee.dependents && employee.dependents.length > 0) {
            employee.dependents.map((dependent, i) => {
                let depAge = dependent.dob ? getAgeFromDOB(dependent.dob, effectiveDate) : dependent.age ? dependent.age : 0
                if (employee.productSelection[i + 1] && !['', '-', 'select', 'waive'].includes(employee.productSelection[i + 1][service.toLowerCase()])) {
                    dependent.age = parseInt(depAge) === 0 ? 1 : depAge
                    dependent.gender = dependent.gender ? dependent.gender.charAt(0) : ''
                    dependent.relationship = dependent.relationship ? dependent.relationship : ''
                    updatedDeps.push(dependent)
                }
            })
        }
        let employeeAge = employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : employee.age ? employee.age : 15
        employee['dependents'] = updatedDeps
        // employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['gender'] = employee.gender ? employee.gender.charAt(0) : 'F'
        employee['age'] = parseInt(employeeAge) === 0 ? 15 : parseInt(employeeAge)

        if (employee.employeeInfo) {
            employee.employeeInfo['gender'] = employee.gender.charAt(0)
        }

        let reqState = isUhcRenewals() ? fullProps.renewalEnrollment?.renewalCaseData?.situsState : fullProps.companyProfile?.locations[0]?.zipData?.stateCode
        if((employee.employeeStatus !== '1099 Independent Contractors' && reqState === 'CA') || reqState !== 'CA'){
            if(isUhcRenewals() && ['CloseEnrollment', 'Installation','PostEnrollPreInstall','PreInstallCensusChange','FinalizeEnrollment'].includes(requestData.quoteStatus)){
                if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0][service.toLowerCase()] && !['', '-', 'select', 'waive'].includes(employee.productSelection[0][service.toLowerCase()]))
                updatedCensus.push(employee)
            }else{
                updatedCensus.push(employee)
            }
        }
        
        // } 
        // else {
        //     let employeeAge = employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : employee.age ? employee.age : 10
        //     employee['dependents'] = []
        //     employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        //     employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        //     employee['age'] = parseInt(employeeAge) === 0 ? 10 : parseInt(employeeAge)
        //     if (employee.employeeInfo) {
        //         employee.employeeInfo['gender'] = employee.gender.charAt(0)
        //     }
        //     updatedCensus.push(employee)

        // }
        // }
    })

    const originalSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode !== '' ? fullProps.renewalEnrollment.renewalData.memberGroup.industryCode : null
    const updatedSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.userEnteredSic && fullProps.renewalEnrollment.renewalData.userEnteredSic !== '' ? fullProps.renewalEnrollment.renewalData.userEnteredSic : null
    const sicCode = originalSIC ? originalSIC : updatedSIC ? updatedSIC : '9900'

    location = fullProps.companyProfile.locations[0]
    if(isUhcRenewals()){
        let renewalData = fullProps.renewalEnrollment.renewalData ? fullProps.renewalEnrollment.renewalData : fullProps.decide.renewalData;
        requestData.groupSize = getEligibleCountFromIbr(renewalData);
    }
    reqBody.groupSize = isUhcRenewals() ? requestData.groupSize : updatedCensus ? updatedCensus.length : location.totalEmps
    reqBody.zipCode = location.zipData.zipCode
    reqBody.stateCode = location.zipData.stateCode
    reqBody.countyCode = !isUndefinedOrNullOrEmpty(location.zipData.county) ? location.zipData.county.slice(-3) : ' '
    // reqBody.effectiveDate = '2021-02-01T00:00:00.000Z'
    reqBody.effectiveDate = fullProps.renewalEnrollment.renewalCaseData.effectiveDate ? fullProps.renewalEnrollment.renewalCaseData.effectiveDate : '';
    reqBody.platform = finalPlatform
    reqBody.sicCode = sicCode;
    reqBody.employees = updatedCensus;
    reqBody.empCensus = updatedCensus;
    reqBody.employeeClasses = employeeClasses;
    reqBody.quoteStatus = requestData.quoteStatus;
    reqBody.memberGroupId = requestData.memberGroupId;
    reqBody.platformRatingIDs = requestData.platformRatingIDs;
    reqBody.existingCoverage = existingCoverage.includes(service.toLowerCase());
    reqBody.quoteId = quoteId;
    reqBody.companyName = requestData.companyName;
    reqBody.isUhcRenewals = isUhcRenewals();
    if (isUhcRenewals()) {
        reqBody.rateIds = requestData.rateIds;
        reqBody.eligibleCount = requestData.eligibleCount
    }
    reqBody.parentId = requestData.parentId;
    return reqBody;
}

export const getBasicLifeDetailRequest = (fullProps, platform, existingCoverage, quoteStatus, quoteId, requestData = {}) => {
    let reqBody = {},
        groupSize, zipCode, stateCode, countyCode, location;
    const employeeClasses = {}

    if (platform === 'PRIME' || platform === 'CIRRUS') {
        const {enrolledLifePlans,enrolledDependentLifePlans}=fullProps.rules.enrollment
        const combinedPlans=enrolledLifePlans.concat(enrolledDependentLifePlans)
        const rateRequestArrays=combinedPlans.map((plan) => plan.primePlanCode)
        const blRequestArrays=combinedPlans.map((plan) => plan.code)
        const combinedRequestArrays=combinedPlans.map((plan) => plan.code + "--" + plan.ADandDCode)
        const childBenefitAmount=combinedPlans.map((plan) => plan.childBenefitAmount != null ? plan.childBenefitAmount : "")
        const spouseBenefitAmount=combinedPlans.map((plan) => plan.spouseBenefitAmount != null ? plan.spouseBenefitAmount : "")
        
        const basicLifePlanCodes=[]
        for (let i = 0; i < rateRequestArrays.length; i++) {
            let newPlan = {
                planCode: rateRequestArrays[i],
                depLifeSpouseAmount: spouseBenefitAmount[i],
                depLifeChildAmount: childBenefitAmount[i]
            }
            basicLifePlanCodes.push(newPlan)
        }

        reqBody.basicLifePlanCodes = basicLifePlanCodes
        if (isUhcRenewals()) {
            let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(fullProps.renewalEnrollment.renewalData, 'BASIC_LIFE_EE')
            reqBody.arqRatio = combinedPlans.map(_ => renewingPlanARQRatio)
        } else {
            reqBody.arqRatio = combinedPlans.map(plan => { return getARQRatioForSpecialty(fullProps.renewalEnrollment.renewalData, 'BASIC_LIFE_EE', plan.code) })
        }
        reqBody.blPlanCode = blRequestArrays
        reqBody.combinedCodes = combinedRequestArrays
        const originalSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode !== '' ? fullProps.renewalEnrollment.renewalData.memberGroup.industryCode : null
        const updatedSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.userEnteredSic && fullProps.renewalEnrollment.renewalData.userEnteredSic !== '' ? fullProps.renewalEnrollment.renewalData.userEnteredSic : null
        const sicCode = originalSIC ? originalSIC : updatedSIC ? updatedSIC : '9900'
        reqBody.sicCode = sicCode;
    }  
    // else {
    //     reqBody.basicLifePlanCodes = fullProps.rules.enrollment.enrolledLifePlans.map(plan => { return plan.primePlanCode });
    //     reqBody.blPlanCode = fullProps.rules.enrollment.enrolledLifePlans.map(plan => { return plan.code });
    //     reqBody.combinedCodes = fullProps.rules.enrollment.enrolledLifePlans.map(plan => { return plan.code + "--" + plan.ADandDCode });
    // }
    reqBody.requestType = 'renewal';

    if (fullProps.renewalEnrollment && fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup) {
        location = fullProps.renewalEnrollment.renewalData.memberGroup.locations.location;
        groupSize = fullProps.renewalEnrollment.renewalData.memberGroup.locations.employeeCounts ? fullProps.renewalEnrollment.renewalData.memberGroup.locations.employeeCounts[0].count : '';
        zipCode = (location && location.zipCode) ? location.zipCode : '';
        stateCode = (location && location.state) ? location.state : '';
        countyCode = (location && location.county) ? location.county.slice(-3) : ' ';
    }

    const effectiveDate = isoStringToMMDDYYYY(fullProps.renewalEnrollment.renewalCaseData.effectiveDate)

    let updatedCensus = [];
    let counter = 1
    let employees = deepCopy(fullProps.companyProfile.employees.flat())
    let service = 'life'

    employees.flat().map((employee, index) => {
        let updatedDeps = []
        if (employee.dependents && employee.dependents.length > 0) {
            employee.dependents.map((dependent, i) => {
                let depAge = dependent.dob ? getAgeFromDOB(dependent.dob, effectiveDate) : dependent.age ? dependent.age : 0
                if (employee.productSelection[i + 1] && !['', '-', 'select', 'waive'].includes(employee.productSelection[i + 1][service.toLowerCase()])) {
                    dependent.age = parseInt(depAge) === 0 ? 1 : depAge
                    dependent.gender = dependent.gender ? dependent.gender.charAt(0) : ''
                    dependent.relationship = dependent.relationship && dependent.relationship === 'Life Partner' ? 'Spouse' : dependent.relationship
                    updatedDeps.push(dependent)
                }
            })
        }
        let employeeAge = employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : employee.age ? employee.age : 10
        employee['dependents'] = updatedDeps
        employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        employee['age'] = parseInt(employeeAge) === 0 ? 10 : parseInt(employeeAge)

        if (employee.employeeInfo) {
            employee.employeeInfo['gender'] = employee.gender.charAt(0)
        }

        let reqState = isUhcRenewals() ? fullProps.renewalEnrollment?.renewalCaseData?.situsState : fullProps.companyProfile?.locations[0]?.zipData?.stateCode
        if((employee.employeeStatus !== '1099 Independent Contractors' && reqState === 'CA') || reqState !== 'CA'){
            if(isUhcRenewals() && ['CloseEnrollment', 'Installation','PostEnrollPreInstall','PreInstallCensusChange'].includes(quoteStatus)){
                if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0][service.toLowerCase()] && !['', '-', 'select', 'waive'].includes(employee.productSelection[0][service.toLowerCase()]))
                updatedCensus.push(employee)
            }else{
                updatedCensus.push(employee)
            }
        }
    })
    reqBody.groupSize = isUhcRenewals() ? requestData.groupSize : updatedCensus ? updatedCensus.length : location.totalEmps
    reqBody.zipCode = zipCode
    reqBody.stateCode = stateCode
    reqBody.countyCode = countyCode
    reqBody.effectiveDate = fullProps.renewalEnrollment.renewalCaseData.effectiveDate ? fullProps.renewalEnrollment.renewalCaseData.effectiveDate : ''
    reqBody.platform = platform
    reqBody.empCensus = updatedCensus
    reqBody.employees = updatedCensus
    reqBody.adjustRates = false
    reqBody.existingCoverage = existingCoverage.includes('life');
    reqBody.quoteStatus = quoteStatus;
    reqBody.memberGroupId = fullProps.renewalEnrollment?.renewalData?.memberGroupId ? fullProps.renewalEnrollment?.renewalData?.memberGroupId : fullProps.renewalEnrollment?.renewalData?.memberGroup?.memberGroupId;
    reqBody.companyName = requestData.companyName;
    reqBody.parentId = requestData.parentId;
    reqBody.platformRatingIDs = requestData.platformRatingIDs;
    if(isUhcRenewals()) {
        reqBody.rateIds = requestData && requestData.rateIds ? requestData.rateIds : {};
        reqBody.eligibleCount = requestData && requestData.eligibleCount ? requestData.eligibleCount : null;
    } else {
        const products = fullProps.review.renewalCaseData && fullProps.review.renewalCaseData.product ? fullProps.review.renewalCaseData.product : fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.renewalCaseData ? fullProps.renewalShoppingHelper.renewalCaseData.product : "";
        const lifeArray = fullProps.renewalEnrollment && fullProps.renewalEnrollment.life && fullProps.renewalEnrollment.life.set  ? fullProps.renewalEnrollment.life.set: fullProps.decide && fullProps.decide.life && fullProps.decide.life.set  ? fullProps.decide.life.set: fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.life && fullProps.renewalShoppingHelper.life.set  ? fullProps.renewalShoppingHelper.life.set: fullProps.review && fullProps.review.life && fullProps.review.life.set ? fullProps.review.life.set: fullProps.renewals && fullProps.renewals.life && fullProps.renewals.life.set ? fullProps.renewals.life.set: [];
        const renewalDataArray = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.renewalData ? fullProps.renewalShoppingHelper.renewalData: fullProps.review && fullProps.review.renewalData ? fullProps.review.renewalData: fullProps?.renewalEnrollment?.renewalData ? fullProps.renewalEnrollment.renewalData : {};
        const basicLifeAddService = 'BASICLIFEADD';
        reqBody.enrolledLifePlans = fullProps?.rules?.enrollment?.enrolledLifePlans ? fullProps.rules.enrollment.enrolledLifePlans : [];
        if (fullProps.rules.enrollment.enrolledLifePlans.length > 0) {
            const { optumId } = fullProps.renewals.logInData[0];
            reqBody.optumId = optumId;
            const isExistingPlanSelected = platform !== 'PRIME' ? getExistingPlanSelectedVal(renewalDataArray, fullProps.rules.enrollment, basicLifeAddService) : null;
    
            const identifiedProduct = identifySpecialtyProductInCirrus(products, 'LIFE');
            const plansSet = lifeArray && lifeArray.length > 0 ? lifeArray : [];
            const planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
            
            reqBody.existingPlanRates = planRates;
            reqBody.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
            reqBody.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : '';
            reqBody.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : '';
            if(isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
                reqBody.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
                reqBody.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
                reqBody.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded: ''; 
            } else {
                if(identifiedProduct) {
                    const getProductAttribute = renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                    reqBody.existingProductSelected = 'true';
                    reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                }else if(!identifiedProduct) {
                    reqBody.newProductAdded = 'true';
                }
            }
        }
    }
    if(!isUndefinedOrNullOrEmpty(quoteId)) reqBody.quoteId = quoteId;
    reqBody.isUhcRenewals = isUhcRenewals()
    
    const originalSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.memberGroup && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode && fullProps.renewalEnrollment.renewalData.memberGroup.industryCode !== '' ? fullProps.renewalEnrollment.renewalData.memberGroup.industryCode : null
    const updatedSIC = fullProps.renewalEnrollment.renewalData && fullProps.renewalEnrollment.renewalData.userEnteredSic && fullProps.renewalEnrollment.renewalData.userEnteredSic !== '' ? fullProps.renewalEnrollment.renewalData.userEnteredSic : null
    reqBody.sicCode = originalSIC ? originalSIC : updatedSIC ? updatedSIC : '9900'
    return reqBody;
}

export const getProposalSpecialtyRequest = (service, ratesRequest, plans, proposalQueryData = {}, rules = {}, renewals = {}, store = {}, isMMRCase = false) => {
    let identifiedPlatform = identifyPlatform(proposalQueryData.renewalData)
    const finalPlatform = identifiedPlatform === 'DUAL' ? 'PRIME' : identifiedPlatform

    let existingCoverage = getExistingCoverageForSpecialty(proposalQueryData.renewalData)
    let quoteId = '';
    if (isUhcRenewals()) {
        quoteId = ratesRequest.quoteId;
        if (isMMRCase) {
            quoteId = plans.length > 0 ? plans[0].quoteId : ''
        }
    } else {
        quoteId = getQuoteIdForSpecialty(proposalQueryData, service);
    }
    
    if (service === 'life' && ratesRequest.employees) {
        //Added Platform Check UNtill SW is not ready with BL Cirrus Plans
        const basicLifeRateRequest = {
            groupSize: ratesRequest.groupSize,
            zipCode: ratesRequest.zipCode,
            stateCode: ratesRequest.stateCode,
            countyCode: ratesRequest.countyFIPSCode,
            effectiveDate: ratesRequest.effectiveDate,
            platform: finalPlatform,
            basicLifePlanCodes: ratesRequest.planCodes,
            blPlanCode: ratesRequest.blPlanCodes,
            empCensus: ratesRequest.employees,
            employees: ratesRequest.employees,
            combinedCodes: ratesRequest.combinedCodes,
            sicCode: ratesRequest.sicCode,
        };
        basicLifeRateRequest.memberGroupId = ratesRequest.memberGroupId;
        basicLifeRateRequest.quoteStatus = ratesRequest.quoteStatus;
        basicLifeRateRequest.existingCoverage = existingCoverage.includes('life');
        basicLifeRateRequest.quoteId = quoteId;
        basicLifeRateRequest.companyName = ratesRequest.companyName;

        if (finalPlatform === 'CIRRUS') {
            const lifePlanCodes = [...new Set(plans.map((plan) => plan.code))];
            const dependentLifePlanCodes = proposalQueryData?.dependentLife?.selectedPlans?.length>0 ? proposalQueryData.dependentLife.selectedPlans.map((plan) => plan.code) : [];
            const lifePlan = store.life ? store.life.plans.concat(plans,  store.lifeDep.plans, (proposalQueryData?.dependentLife?.selectedPlans || [])) : [];
            appendBasicLifeDetailsInReqBody(lifePlan, lifePlanCodes.concat(dependentLifePlanCodes),basicLifeRateRequest);
            basicLifeRateRequest.existingCoverage = existingCoverage.includes('life');
            basicLifeRateRequest.arqRatio = plans.map(plan => { return getARQRatioForSpecialty(proposalQueryData.renewalData, 'BASIC_LIFE_EE', plan.code) });
            basicLifeRateRequest.enrolledLifePlans = (ratesRequest.quoteStatus !== 'Shopping' ) ? rules && rules.enrollment && rules.enrollment.enrolledLifePlans && rules.enrollment.enrolledLifePlans.length > 0 ? rules.enrollment.enrolledLifePlans: store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledLifePlans && store.renewalShoppingHelper.enrolledLifePlans.length > 0 ? store.renewalShoppingHelper.enrolledLifePlans:  []: [];

            const enrolLifePlans = rules && rules.enrollment && rules.enrollment.enrolledLifePlans && rules.enrollment.enrolledLifePlans.length > 0 ? rules.enrollment.enrolledLifePlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledLifePlans && store.renewalShoppingHelper.enrolledLifePlans.length > 0 ? store.renewalShoppingHelper.enrolledLifePlans: [];
            const renewalDataArray = store.renewalEnrollment && store.renewalEnrollment.renewalData ? store.renewalEnrollment.renewalData: store.decide && store.decide.renewalData ? store.decide.renewalData: store.renewalShoppingHelper && store.renewalShoppingHelper.renewalData ? store.renewalShoppingHelper.renewalData: store.review && store.review.renewalData ? store.review.renewalData: store.renewals && store.renewals.renewalData ? store.renewals.renewalData: {};
            const renewalCaseDataObj = store.renewalEnrollment && store.renewalEnrollment.renewalCaseData ? store.renewalEnrollment.renewalCaseData: store.decide && store.decide.renewalCaseData ? store.decide.renewalCaseData: store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper.renewalCaseData: store.review && store.review.renewalCaseData ? store.review.renewalCaseData: store.renewals && store.renewals.renewalCaseData ? store.renewals.renewalCaseData:{};
            const lifeArray = store.renewalEnrollment && store.renewalEnrollment.life && store.renewalEnrollment.life.set  ? store.renewalEnrollment.life.set: store.decide && store.decide.life && store.decide.life.set  ? store.decide.life.set: store.renewalShoppingHelper && store.renewalShoppingHelper.life && store.renewalShoppingHelper.life.set  ? store.renewalShoppingHelper.life.set: store.review && store.review.life && store.review.life.set ? store.review.life.set: store.renewals && store.renewals.life && store.renewals.life.set ? store.renewals.life.set: [];
            const basicLifeAddService = 'BASICLIFEADD';
            if(lifeArray) {
                let plansSet = lifeArray && lifeArray.length > 0 ? lifeArray : [];
                let planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
                ratesRequest.existingPlanRatesRL = planRates;
            }
            if(ratesRequest && ratesRequest.existingPlanRatesRL && ratesRequest.existingPlanRatesRL.length > 0 && (ratesRequest.quoteStatus === 'Shopping' || ratesRequest.quoteStatus === 'Proposed')) {
                const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
                basicLifeRateRequest.optumId = optumId;
                const identifiedProduct = identifySpecialtyProductInCirrus(proposalQueryData.renewalCaseData.product, service.toUpperCase());
                const isExistingPlanAvailable = getExistingRatesedVal(proposalQueryData.renewalData, rules && rules.enrollment ? rules.enrollment : {}, basicLifeAddService, ratesRequest.existingPlanRatesRL);
                if(identifiedProduct) {
                    const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                    basicLifeRateRequest.existingProductSelected = 'true';
                    basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                }else if(!identifiedProduct) {
                    basicLifeRateRequest.newProductAdded = 'true';
                }
                basicLifeRateRequest.existingPlanSelected = isExistingPlanAvailable && isExistingPlanAvailable.selectedExistingPlan ? isExistingPlanAvailable.selectedExistingPlan : false;
                basicLifeRateRequest.rateGuarBegin = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarBegin) : '';
                basicLifeRateRequest.rateGuarEnd = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarEnd) : '';
                basicLifeRateRequest.existingPlanRates = ratesRequest.existingPlanRatesRL;
            } else if (enrolLifePlans.length > 0) {    
                const isExistingPlanSelected = getExistingPlanSelectedVal(proposalQueryData.renewalData, rules && rules.enrollment ? rules.enrollment : {}, basicLifeAddService, enrolLifePlans.length > 0 ? enrolLifePlans : null);
                const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
                basicLifeRateRequest.optumId = optumId;
                const identifiedProduct = identifySpecialtyProductInCirrus(renewalCaseDataObj.product, service.toUpperCase());
                if(isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
                    basicLifeRateRequest.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
                    basicLifeRateRequest.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
                    basicLifeRateRequest.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded: ''; 
                } else {
                    if(identifiedProduct) {
                        const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                        basicLifeRateRequest.existingProductSelected = 'true';
                        basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                    }else if(!identifiedProduct) {
                        basicLifeRateRequest.newProductAdded = 'true';
                    }
                }
                basicLifeRateRequest.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
                basicLifeRateRequest.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : '';
                basicLifeRateRequest.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : '';
                basicLifeRateRequest.existingPlanRates = ratesRequest.existingPlanRatesRL;
            } else {
                const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
                basicLifeRateRequest.optumId = optumId;
                const identifiedProduct = identifySpecialtyProductInCirrus(proposalQueryData.renewalCaseData.product, service.toUpperCase());
                if(identifiedProduct) {
                    const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                    basicLifeRateRequest.existingProductSelected = 'true';
                    basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                }else if(!identifiedProduct) {
                    basicLifeRateRequest.newProductAdded = 'true';
                }
            }
        } else {
            basicLifeRateRequest.basicLifePlanCodes = plans.map(plan => { return plan.code });
            if (isUhcRenewals()) {
                let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(proposalQueryData.renewalData, 'BASIC_LIFE_EE')
                basicLifeRateRequest.arqRatio = plans.map(_ => renewingPlanARQRatio)
            } else {
                basicLifeRateRequest.arqRatio = plans.map(plan => { return getARQRatioForSpecialty(proposalQueryData.renewalData, 'BASIC_LIFE_EE', plan.code) })
            }
            basicLifeRateRequest.blPlanCode = []
            basicLifeRateRequest.combinedCodes = []
        }
        basicLifeRateRequest.isUhcRenewals = isUhcRenewals();
        //if (isUhcRenewals()) {
            const planCodeDetails = [];
            for (let i = 0; i < plans.length; i++) {
                const planCode = {
                    planCode: plans[i].primePlanCode,
                    depLifeSpouseAmount: plans[i].spouseBenefitAmount,
                    depLifeChildAmount: plans[i].childBenefitAmount,
                }
                planCodeDetails.push(planCode);
            }
            basicLifeRateRequest.basicLifePlanCodes = planCodeDetails;
            basicLifeRateRequest.blPlanCode = plans.map(plan => { return plan.code });
            basicLifeRateRequest.combinedCodes = plans.map(plan => { return plan.code + "--" + plan.ADandDCode });
            basicLifeRateRequest.requestType = 'renewal';
            basicLifeRateRequest.rateIds = ratesRequest.rateIds;
            basicLifeRateRequest.eligibleCount = ratesRequest.eligibleCount;
            basicLifeRateRequest.parentId = ratesRequest.parentId;
        //}
        return basicLifeRateRequest;
    } else if (service !== 'life' && (service === 'dental' || service === 'vision')) {
        const reqBody = {
            groupSize: ratesRequest.groupSize,
            zipCode: ratesRequest.zipCode,
            stateCode: ratesRequest.stateCode,
            countyCode: ratesRequest.countyFIPSCode,
            effectiveDate: ratesRequest.effectiveDate,
            platform: finalPlatform,
            sicCode: ratesRequest.sicCode,
        };

        if (service.toUpperCase() === 'DENTAL') reqBody.enrolledDentalPlans = (ratesRequest.quoteStatus !== 'Shopping' ) ? rules && rules.enrollment && rules.enrollment.enrolledDentalPlans && rules.enrollment.enrolledDentalPlans.length > 0 ? rules.enrollment.enrolledDentalPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledDentalPlans && store.renewalShoppingHelper.enrolledDentalPlans.length > 0 ? store.renewalShoppingHelper.enrolledDentalPlans: []: [];
        if (service.toUpperCase() === 'VISION') reqBody.enrolledVisionPlans = (ratesRequest.quoteStatus !== 'Shopping' ) ? rules && rules.enrollment && rules.enrollment.enrolledVisionPlans && rules.enrollment.enrolledVisionPlans.length > 0 ? rules.enrollment.enrolledVisionPlans: store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledVisionPlans && store.renewalShoppingHelper.enrolledVisionPlans.length > 0 ? store.renewalShoppingHelper.enrolledVisionPlans:  []: [];
        if (service.toUpperCase() === 'DENTAL') reqBody.dentalPlanCodes = plans.map(plan => { return plan.code });
        if (service.toUpperCase() === 'VISION') reqBody.visionPlanCodes = plans.map(plan => { return plan.code });

        if(isUhcRenewals()) {
            reqBody.eligibleCount = ratesRequest.eligibleCount;
            let enrolledPlans = service.toUpperCase() === 'DENTAL' ? 
                reqBody.enrolledDentalPlans : reqBody.enrolledVisionPlans;

            if(enrolledPlans && enrolledPlans.length > 0) {
                const tierStructureId = enrolledPlans[0].tierStructureId;

                if(isPlanTwoTiered(tierStructureId)) {
                    reqBody.ratingTier = '2 Tier'
                }

                if(isPlanThreeTiered(tierStructureId)) {
                    reqBody.ratingTier = '3 Tier'
                }
            }
        }

        if (finalPlatform === 'PRIME' || finalPlatform === 'DUAL') {
            if (isUhcRenewals()) {
                let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(proposalQueryData.renewalData, service.toUpperCase())
                reqBody.arqRatio = plans.map(_ => renewingPlanARQRatio)
            } else {
                reqBody.arqRatio = plans.map(plan => { return getARQRatioForSpecialty(proposalQueryData.renewalData, service, plan.code) })
            }
        }

        reqBody.requestType = 'renewal';
        reqBody.employees = ratesRequest.employees;
        reqBody.empCensus = ratesRequest.employees;
        reqBody.employeeClasses = ratesRequest.employeeClasses;
        reqBody.memberGroupId = ratesRequest.memberGroupId;
        reqBody.quoteStatus = ratesRequest.quoteStatus;
        reqBody.existingCoverage = existingCoverage.includes(service);
        reqBody.quoteId = quoteId;
        reqBody.companyName = ratesRequest.companyName;
        reqBody.parentId = ratesRequest.parentId;
        
        const enrolDenPlans = rules && rules.enrollment && rules.enrollment.enrolledDentalPlans && rules.enrollment.enrolledDentalPlans.length > 0 ? rules.enrollment.enrolledDentalPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledDentalPlans && store.renewalShoppingHelper.enrolledDentalPlans.length > 0 ? store.renewalShoppingHelper.enrolledDentalPlans: [];
        const enrolVisPlans = rules && rules.enrollment && rules.enrollment.enrolledVisionPlans && rules.enrollment.enrolledVisionPlans.length > 0 ? rules.enrollment.enrolledVisionPlans: store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledVisionPlans && store.renewalShoppingHelper.enrolledVisionPlans.length > 0 ? store.renewalShoppingHelper.enrolledVisionPlans: [];
        const renewalDataArray = store.renewalEnrollment && store.renewalEnrollment.renewalData ? store.renewalEnrollment.renewalData: store.decide && store.decide.renewalData ? store.decide.renewalData: store.renewalShoppingHelper && store.renewalShoppingHelper.renewalData ? store.renewalShoppingHelper.renewalData: store.review && store.review.renewalData ? store.review.renewalData: store.renewals && store.renewals.renewalData ? store.renewals.renewalData: {};
        const renewalCaseDataObj = store.renewalEnrollment && store.renewalEnrollment.renewalCaseData ? store.renewalEnrollment.renewalCaseData: store.decide && store.decide.renewalCaseData ? store.decide.renewalCaseData: store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper.renewalCaseData: store.review && store.review.renewalCaseData ? store.review.renewalCaseData: store.renewals && store.renewals.renewalCaseData ? store.renewals.renewalCaseData:{};
        const dentalArray = store.renewalEnrollment && store.renewalEnrollment.dental && store.renewalEnrollment.dental.set  ? store.renewalEnrollment.dental.set: store.decide && store.decide.dental && store.decide.dental.set  ? store.decide.dental.set: store.renewalShoppingHelper && store.renewalShoppingHelper.dental && store.renewalShoppingHelper.dental.set  ? store.renewalShoppingHelper.dental.set: store.review && store.review.dental && store.review.dental.set ? store.review.dental.set: store.renewals && store.renewals.dental && store.renewals.dental.set ? store.renewals.dental.set: [];
        const visionArray = store.renewalEnrollment && store.renewalEnrollment.vision && store.renewalEnrollment.vision.set  ? store.renewalEnrollment.vision.set: store.decide && store.decide.vision && store.decide.vision.set  ? store.decide.vision.set: store.renewalShoppingHelper && store.renewalShoppingHelper.vision && store.renewalShoppingHelper.vision.set  ? store.renewalShoppingHelper.vision.set: store.review && store.review.vision && store.review.vision.set ? store.review.vision.set: store.renewals && store.renewals.vision && store.renewals.vision.set ? store.renewals.vision.set: [];
        if(service === 'dental' && dentalArray || service === 'vision' && visionArray) {
            let plansSet = service.toUpperCase() === 'DENTAL' && dentalArray ? dentalArray: service.toUpperCase() === 'VISION' && visionArray ? visionArray: [];
            let planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
            ratesRequest.existingPlanRatesRL = planRates;
        }
        if(finalPlatform !== 'PRIME' && ratesRequest && ratesRequest.existingPlanRatesRL && ratesRequest.existingPlanRatesRL.length > 0 && (service === 'dental' && (ratesRequest.quoteStatus === 'Shopping' || ratesRequest.quoteStatus === 'Proposed') || service === 'vision' && (ratesRequest.quoteStatus === 'Shopping' || ratesRequest.quoteStatus === 'Proposed'))) {
            const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
            reqBody.optumId = optumId;
            const identifiedProduct = identifySpecialtyProductInCirrus(proposalQueryData.renewalCaseData.product, service.toUpperCase());
            const isExistingPlanAvailable = getExistingRatesedVal(proposalQueryData.renewalData, rules && rules.enrollment ? rules.enrollment : {}, service.toUpperCase(), ratesRequest.existingPlanRatesRL);
            if(identifiedProduct) {
                const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => service.toUpperCase() === productType.product.toUpperCase())
                reqBody.existingProductSelected = 'true';
                reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
            }else if(!identifiedProduct) {
                reqBody.newProductAdded = 'true';
            }
            reqBody.existingPlanSelected = isExistingPlanAvailable && isExistingPlanAvailable.selectedExistingPlan ? isExistingPlanAvailable.selectedExistingPlan : false;
            reqBody.rateGuarBegin = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarBegin) : '';
            reqBody.rateGuarEnd = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarEnd) : '';
            reqBody.existingPlanRates = ratesRequest.existingPlanRatesRL;
        } else if (finalPlatform !== 'PRIME' && (service === 'dental' && enrolDenPlans.length > 0 || service === 'vision' && enrolVisPlans.length > 0 )) {    
            // US3947255: Rate Guarantee - Get Rates API Updates
            const isExistingPlanSelected = finalPlatform !== 'PRIME' ? getExistingPlanSelectedVal(proposalQueryData.renewalData, rules && rules.enrollment ? rules.enrollment : {}, service.toUpperCase(), service === 'dental' && enrolDenPlans.length > 0 ? enrolDenPlans: enrolVisPlans): null;
            const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
            reqBody.optumId = optumId;
            const identifiedProduct = identifySpecialtyProductInCirrus(renewalCaseDataObj.product, service.toUpperCase());
            if(isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
                reqBody.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
                reqBody.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
                reqBody.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded: ''; 
            } else {
                if(identifiedProduct) {
                    const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => service.toUpperCase() === productType.product.toUpperCase())
                    reqBody.existingProductSelected = 'true';
                    reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                }else if(!identifiedProduct) {
                    reqBody.newProductAdded = 'true';
                }
            }
            reqBody.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
            reqBody.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : '';
            reqBody.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : '';
            reqBody.existingPlanRates = ratesRequest.existingPlanRatesRL;
        } else if (finalPlatform !== 'PRIME' && (service === 'dental' || service === 'vision')) {
            const { optumId } = store && store.renewals && store.renewals.logInData[0] ? store.renewals.logInData[0] : renewals && renewals.logInData[0] ? renewals.logInData[0] : {};
            reqBody.optumId = optumId;
            const identifiedProduct = identifySpecialtyProductInCirrus(proposalQueryData.renewalCaseData.product, service.toUpperCase());
            if(identifiedProduct) {
                const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => service.toUpperCase() === productType.product.toUpperCase())
                reqBody.existingProductSelected = 'true';
                reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
            }else if(!identifiedProduct) {
                reqBody.newProductAdded = 'true';
            }
        }
        reqBody.isUhcRenewals = isUhcRenewals();
        if (isUhcRenewals) {
            reqBody.rateIds = ratesRequest.rateIds;
        }   
        return reqBody;
    }
}

//#region Supporing Methods
/**
 * Supporitng method helps to refresh the census, group, contract options.  
 * Fetches the CIRRUS, PRIME or Both platform employee or employer data.
 * @param {*} quoteTrackingNumber : QuoteTrackingNumber to refresh the details of member group
 * @param {*} renewalData : renewal data of the member group.
 * @returns Fetches the CIRRUS, PRIME or Both platform employee or employer data.
 */
const refreshCensusGroupContractOptions = async (quoteTrackingNumber, renewalData, allowPrimeRefreshCall = false, renewAsIs = false, priorYearDataSelected = 'N', isAutomateMMR = false) => {
    const effectiveDate = (new Date(renewalData.renewalCaseData.effectiveDate)).toISOString().split('T')[0];
    // const inquiryDate = (new Date()).toISOString().split('T')[0];
    const inDate = new Date(effectiveDate); // Passing inquiryDate as expiration date
    isUhcRenewals()?inDate.setDate(inDate.getDate()):inDate.setDate(inDate.getDate() - 1)
    
    let inquiryDate = JSON.stringify(inDate)
    inquiryDate = inquiryDate.slice(1, 11)
    
    const memberStatus = 'active';
    const addressType = 'HOME';
    const { quickQuotes } = apiRoutes;

    const reqParamMemberGroupId = `memberGroupId=${renewalData.renewalData.memberGroup.memberGroupId}`;
    const reqParamQuoteTrackingNumber = `quoteTrackingNumber=${quoteTrackingNumber}`;
    const reqParamInquiryDate = `inquiryDate=${inquiryDate}`;
    const reqParamAddressType = `addressType=${addressType}`;
    const reqParamEffectiveDate = `effectiveDate=${effectiveDate}`;
    const reqParamMemberStatus = `memberStatus=${memberStatus}`;
    const uhcCaseFinalizing = `caseFinalize=false`;
    const allowPrimeCall = `allowPrimeRefreshCall=${allowPrimeRefreshCall}`;
    const renewAsIsFlag = `renewAsIs=${renewAsIs}`;
    const reqParamPriorYearDataSelected = `priorYearDataSelected=${priorYearDataSelected}`
    const isAutomateMMRScenario = `isAutomateMMRScenario=${isAutomateMMR}`

    const marketType = getMarketType();
    const isInternalGrp = renewalData.renewalCaseData.samxInternalGrp === 'Y';

    const reqParamMarketType = `marketType=${marketType}`

    const casePlatform = identifyPlatform(renewalData.renewalData);
    const stateCode = renewalData.renewalCaseData?.situsState
    const memberGroupId = renewalData.renewalData?.Ibr?.quote?.clientDefinedData?.data.find((doc) => doc.key === "CirrusMemberGroupID")?.value
    let contractOptionData;

    // CensusRefresh, GroupRefresh and ContractOptions api calls are applicable only for the CIRRUS and PRIME
    if(marketType === MARKET_TYPES.UHC && !isInternalGrp && allowPrimeRefreshCall && !(renewalData.renewalCaseData.custType === 'NICE' || renewalData.renewalCaseData.custType === 'DUAL')){
        await axios.get(`${quickQuotes}/censusRefreshPRIME?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}`).catch(err => {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber, inquiryDate, effectiveDate
            }
            logError(KEY_NAME, UHC_REN_APIS.CENSUS_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            throw err;
        });
        await axios.get(`${quickQuotes}/groupRefreshPRIME?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}`).catch(err => {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber, inquiryDate, effectiveDate
            }
            logError(KEY_NAME, UHC_REN_APIS.GROUP_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            throw err;
        });
    }
    
    if (isUhcRenewals() && isInternalGrp && stateCode === 'CA' && memberGroupId !== null && memberGroupId !== " " && renewalData.renewalCaseData.custType === 'DUAL') {   
        await axios.get(`${quickQuotes}/censusRefresh?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamInquiryDate}&${reqParamAddressType}&${reqParamEffectiveDate}&${reqParamMemberStatus}&${reqParamMarketType}`)
        .catch(err => {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber, inquiryDate, effectiveDate
            }
            logError(KEY_NAME, UHC_REN_APIS.CENSUS_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            throw err;
        });

        await axios.get(`${quickQuotes}/groupRefresh?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}`).catch(err => {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber
            }
            logError(KEY_NAME, UHC_REN_APIS.GROUP_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            throw err;
        });
        // contractOptions
        contractOptionData = await axios.get(`${quickQuotes}/contractOptions?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${reqParamEffectiveDate}`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                    marketType, quoteTrackingNumber, effectiveDate
                }
                logError(KEY_NAME, UHC_REN_APIS.CONTRACT_OPTIONS, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            }
            throw err;
        });
    }

    if ((marketType === MARKET_TYPES.OXFORD && casePlatform !== 'PRIME') || (marketType === MARKET_TYPES.UHC && isInternalGrp && !(renewalData.renewalCaseData.custType === 'NICE' || renewalData.renewalCaseData.custType === 'DUAL'))) {
        // census refresh     
        await axios.get(`${quickQuotes}/censusRefresh?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamInquiryDate}&${reqParamAddressType}&${reqParamEffectiveDate}&${reqParamMemberStatus}&${reqParamMarketType}`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                    marketType, quoteTrackingNumber, inquiryDate, effectiveDate
                }
                logError(KEY_NAME, UHC_REN_APIS.CENSUS_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            }
            throw err;
        });
        // Member Group.
        await axios.get(`${quickQuotes}/groupRefresh?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                    marketType, quoteTrackingNumber
                }
                logError(KEY_NAME, UHC_REN_APIS.GROUP_REFRESH, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            }
            throw err;
        });
        // contractOptions
        contractOptionData = await axios.get(`${quickQuotes}/contractOptions?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${reqParamEffectiveDate}`).catch(err => {
            if(isUhcRenewals()) {
                const reqBody = {
                    memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                    marketType, quoteTrackingNumber, effectiveDate
                }
                logError(KEY_NAME, UHC_REN_APIS.CONTRACT_OPTIONS, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
            }
            throw err;
        });
    }

    // Enrolled Employees from PRIME and CIRRUS
    const responseEnrolledEmployees = await axios.get(`${quickQuotes}/enrollEmployee?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${uhcCaseFinalizing}&${allowPrimeCall}&${renewAsIsFlag}&${reqParamEffectiveDate}&${reqParamPriorYearDataSelected}&${isAutomateMMRScenario}`).catch(err => {
        if(isUhcRenewals()) {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber
            }
            logError(KEY_NAME, UHC_REN_APIS.ENROLL_EMPLOYEE, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
        }
        throw err;
    });

    // Enrolled Employer details from PRIME and CIRRUS
    const responseEnrolledGroup = await axios.get(`${quickQuotes}/groupEnrollmentDetails?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${allowPrimeCall}&${reqParamEffectiveDate}&${reqParamPriorYearDataSelected}`).catch(err => {
        if(isUhcRenewals()) {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber
            }
            logError(KEY_NAME, UHC_REN_APIS.GROUP_ENROLLMENT_DETAILS, 'RenewalEnrollmentUtils.refreshCensusGroupContractOptions', reqBody, err);
        }
        throw err;
    });

    return { responseEnrolledGroup, responseEnrolledEmployees, contractOptionData };
}

const recallEnrollEmployeeAndgroupEnrollmentDetailsApis = async (quoteTrackingNumber, renewalData, allowPrimeRefreshCall = false) => {
    const { quickQuotes } = apiRoutes;

    const reqParamMemberGroupId = `memberGroupId=${renewalData.renewalData.memberGroup.memberGroupId}`;
    const reqParamQuoteTrackingNumber = `quoteTrackingNumber=${quoteTrackingNumber}`;

    const marketType = getMarketType();
    const reqParamMarketType = `marketType=${marketType}`
    const uhcCaseFinalizing = `caseFinalize=false`;
    const allowPrimeCall = `allowPrimeRefreshCall=${allowPrimeRefreshCall}`;

    // Enrolled Employees from PRIME and CIRRUS
    const responseEnrolledEmployees = await axios.get(`${quickQuotes}/enrollEmployee?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${uhcCaseFinalizing}&${allowPrimeCall}`).catch(err => {
        if(isUhcRenewals()) {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber
            }
            logError(KEY_NAME, UHC_REN_APIS.ENROLL_EMPLOYEE, 'RenewalEnrollmentUtils.recallEnrollEmployeeAndgroupEnrollmentDetailsApis', reqBody, err);
        }
        throw err;
    });

    // Enrolled Employer details from PRIME and CIRRUS
    const responseEnrolledGroup = await axios.get(`${quickQuotes}/groupEnrollmentDetails?${reqParamMemberGroupId}&${reqParamQuoteTrackingNumber}&${reqParamMarketType}&${allowPrimeCall}`).catch(err => {
        if(isUhcRenewals()) {
            const reqBody = {
                memberGroupId: renewalData?.renewalData?.memberGroup?.memberGroupId,
                marketType, quoteTrackingNumber
            }
            logError(KEY_NAME, UHC_REN_APIS.GROUP_ENROLLMENT_DETAILS, 'RenewalEnrollmentUtils.recallEnrollEmployeeAndgroupEnrollmentDetailsApis', reqBody, err);
        }
        throw err;
    });

    return { responseEnrolledGroup, responseEnrolledEmployees };
}

const getContractOptData = (contractOptionData, renewalCaseData) => {
    let planList = [];
    if (renewalCaseData && renewalCaseData.effectiveDate && contractOptionData && contractOptionData.data && contractOptionData.data.contractList && contractOptionData.data.contractList && contractOptionData.data.contractList[0] && contractOptionData.data.contractList[0].contractOptionsList) {
        const coverageOption = contractOptionData.data.contractList[0].contractOptionsList.filter((contract) => contract.contractOptionEffectiveDate >= YYYYMMDDtoIsoString(renewalCaseData.effectiveDate));
        coverageOption && coverageOption.forEach(coverages => {
            coverages && coverages.coverageOptionList.forEach((coverage) => {
                let planObj = {};
                const planCode = ((coverages.contractOptType === 'DN' || coverages.contractOptType === 'VS') && coverage && coverage.coverageOptionPlansList && coverage.coverageOptionPlansList[0] && coverage.coverageOptionPlansList[0].planID) ? coverage.coverageOptionPlansList[0].planID : coverage.externalMarketingName;
                if (coverage.externalMarketingName) {
                    planObj.externalMarketingName = planCode;
                    planObj.coverageOptionID = coverage.coverageOptionID;
                    planObj.planOptionID = coverage.planOptionID;
                    planObj.previousPlanOptionID = coverage && coverage.previousPlanOptionID ? coverage.previousPlanOptionID : '';
                    planObj.contractOptionID = coverages.contractOptionID;
                    planObj.contractOptID = coverages.contractOptID;
                    planObj.planYearMonth = coverages.planYearMonth;
                    planList.push(planObj);
                }
            });
        })
        return planList;
    }
    return planList;
}

//Check if case has single or multiple accumulator(s)
export const getAccumulators = (contractOptions, renewalCaseData) => {
    if (renewalCaseData.platform === "PRIME") {
        return [];
    }
    const contractOptionObj = { data: contractOptions ? contractOptions : {} };
    let contractOptList = getContractOptData(contractOptionObj, renewalCaseData);
    let accumulatorList = [];
    for (let item of contractOptList) {
        if (item.planYearMonth === '00' || item.planYearMonth === '01') {
            item.medicalBenefitPlanOption = 'calenderYear';
        } else {
            item.medicalBenefitPlanOption = 'policyYear';
        }
    }
    for (const item of contractOptList) {
        if (accumulatorList.includes(item.medicalBenefitPlanOption) === false) {
            accumulatorList.push(item.medicalBenefitPlanOption);
        }
    }
    return accumulatorList
}
