import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import apiRoutes from '../utils/api/apiRouteConfig';
import { augmentNonConfigurableFieldDataInFormSections } from '../reducers/rules/enrollmentFormHelper';
import { getConditionEvaluators } from '../components/shared/ConfigurableForms/helpers/conditionCheckers';
import { getMarketType, isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { determineMinContribLevel, minLifeContribLevel } from '../utils/businessLogic/calculateContributions';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { fiftyStates } from '../reducers/rules/JSONconsts';
import { getNBCaseProgress, CASE_PROGRESS } from '../reducers/products/helpers/companyProfileConsts';

export function getEnrollmentForm(stateCode, workflow, renewalConditionEvaluator = {}, forcefullyInitialize = false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch({
            type: types.REQUEST_ENROLLMENT_FORM,
        });
        const fullProps = getState();
        const {
            overall, quotes, rules: { enrollment, enrollmentForm: { shouldInitializeForm } }, companyProfile, life, caseTracking
        } = getState();
        stateCode = (stateCode === "CA" && workflow === 'renewals') ? "CAA" : stateCode;
        const caseProgress = getNBCaseProgress(fullProps);

        const { enrolledPlans,enrolledLifePlans } = enrollment;
        const flatMedicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
        const { platform } = overall;
        const marketType = getMarketType();
        const { b2b = false } = overall;

        let conditionEvaluators = {};
        if (Object.keys(renewalConditionEvaluator).length === 0) {
            conditionEvaluators = getConditionEvaluators(overall, enrollment, fullProps);
        } else {
            conditionEvaluators = renewalConditionEvaluator;
        }

        const eligibilityInfo = {
            ...quotes,
             companyProfile, 
             overall
        };
        let isUHCNB = workflow !== 'renewals' && marketType === 'UHC';
        axios.get(
            `${apiRoutes.mongo}/enrollment-form?`
                + `state=${stateCode}&workflow=${workflow}&platform=${workflow === 'renewals' ? marketType : platform}`,
        )
            .then((result) => {
                let { formSections } = result.data;
                formSections = augmentNonConfigurableFieldDataInFormSections(formSections, isUhcRenewals() ? enrolledLifePlans :life, platform, stateCode, isUHCNB);
                const employer = formSections.filter((fs) => fs.sectionParentPage === 'Employer Information');
                const eligibility = formSections.filter((fs) => fs.sectionParentPage === 'Eligibility Information');
                const producer = formSections.filter((fs) => fs.sectionParentPage === 'Producer / GA');
                const attestation = formSections.filter((fs) => fs.sectionParentPage === 'Attestation');
                const employee = formSections.filter((fs) => fs.sectionParentPage === 'Employee Information');
                const standardHRA = formSections.filter((fs) => fs.sectionParentPage === 'Standard HRA');

                if(isUhcRenewals()) {
                    employer.map(item => {
                        if(item.sectionName === 'billingAddress' || item.sectionName === 'primaryLocation') {
                            item.fields.map(field => {
                                if(field.fieldName === 'billingState' || field.fieldName === 'state') {
                                    field.dropdownOptions = [
                                        {
                                            options: fiftyStates,
                                        },
                                    ]
                                }
                            })
                        }
                    })
                }
                // For Renewals, numberOfHoursWorked must be non-required
                if (workflow === 'renewals' && employee && employee[1]) { // Added check for employee[1] because of pre-existing compilation errors
                    employee[1].fields.map((field) => {
                        if (field.fieldName === 'numberOfHoursWorked') {
                            field.isRequired = false;
                        }
                    });
                }
                const employeeAttestation = formSections.filter((fs) => fs.sectionParentPage === 'Employee Attestation');
                const employerCertification = formSections.filter((fs) => fs.sectionParentPage === 'Employer Certification');

                if(isUHCNB && stateCode === "CA" && flatMedicalPlans && flatMedicalPlans.length > 0){
                    const { fields: contributionFields } = formSections.find((section) => section.sectionName === 'contribution');
                    //const conrtibutionType = application.eligibility?.contribution?.medicalEmployerContributionType;
                    const employerContributionFieldDollar = contributionFields?.find((field) => field.fieldNameDisplay === 'Medical Employer Contribution for Employee in $');
                    const dependentContributionFieldDollar = contributionFields?.find((field) => field.fieldNameDisplay === 'Medical Employer Contribution for Dependents in $');
                    const contributionValidation = employerContributionFieldDollar?.validations;
                    const contributionValidationdep = dependentContributionFieldDollar?.validations;
                    let minPremium = flatMedicalPlans[0]?.quote;
                    for(let i = 0; i<flatMedicalPlans?.length; i++){
                        if(minPremium>=flatMedicalPlans[i].quote)
                           minPremium = flatMedicalPlans[i].quote;
                    }
                    const index = flatMedicalPlans?.findIndex(item => item.quote === minPremium);
                    const minPremiumEmployeeRates = flatMedicalPlans[index]?.employeeRates;
                    const minRate = Math.min(...minPremiumEmployeeRates?.map(item => item.total));
                    for(let i = 0;i<contributionValidation?.length;i++){
                        if(minRate>=100 && contributionValidation[i].validationType === "zip")
                            contributionValidation[i].regexDescription = "Minimum 100$";
                        else contributionValidation[i].regexDescription = "Minimum 0";
                        if(minRate>=100 && contributionValidation[i].validationType === "min") 
                           contributionValidation[i].value = 100;
                        else contributionValidation[i].value = 0;
                        if(contributionValidation[i].validationType === "max")
                           contributionValidation[i].value = minRate;
                   }
                   for(let i = 0;i<contributionValidationdep?.length;i++){
                    if(minRate>=100 && contributionValidationdep[i].validationType === "zip")
                        contributionValidationdep[i].regexDescription = "Minimum 100$";
                    else contributionValidationdep[i].regexDescription = "Minimum 0";
                    if(minRate>=100 && contributionValidationdep[i].validationType === "min") 
                        contributionValidationdep[i].value = 100;
                    else contributionValidationdep[i].value = 0;
                    if(contributionValidationdep[i].validationType === "max")
                        contributionValidationdep[i].value = minRate;
                    }
                }

                if (flatMedicalPlans.some((plan) => plan.healthyNYPlan)) {
                    const { fields: contributionFields } = formSections.find((section) => section.sectionName === 'contribution');
                    const employerContributionField = contributionFields.find((field) => field.fieldName === 'medicalEmployerContribution');
                    employerContributionField.defaultValue[0].value = '50';
                } else { // Medical min contribution only?
                    if(isUhcRenewals())
                    {
                        const { fields: contributionFields } = formSections.find((section) => section.sectionName === 'contribution');
                        if(stateCode === "CA" && flatMedicalPlans && flatMedicalPlans.length > 0){
                            //const conrtibutionType = application.eligibility?.contribution?.medicalEmployerContributionType;
                            const employerContributionFieldDollar = contributionFields?.find((field) => field.fieldNameDisplay === 'Medical Employer Contribution for Employee in $');
                            const dependentContributionFieldDollar = contributionFields?.find((field) => field.fieldNameDisplay === 'Medical Employer Contribution for Dependents in $');
                            const contributionValidation = employerContributionFieldDollar?.validations;
                            const contributionValidationdep = dependentContributionFieldDollar?.validations;
                            let minPremium = flatMedicalPlans[0]?.quote;
                            for(let i = 0; i<flatMedicalPlans?.length; i++){
                                if(minPremium>=flatMedicalPlans[i].quote)
                                    minPremium = flatMedicalPlans[i].quote;
                            }
                            const index = flatMedicalPlans?.findIndex(item => item.quote === minPremium);
                            const minPremiumEmployeeRates = flatMedicalPlans[index]?.employeeRates;
                            const minRate = Math.min(...minPremiumEmployeeRates?.map(item => item.total));
                            for(let i = 0;i<contributionValidation?.length;i++){
                                if(minRate>=100 && contributionValidation[i].validationType === "zip")
                                     contributionValidation[i].regexDescription = "Minimum 100$";
                                else contributionValidation[i].regexDescription = "Minimum 0";
                                if(minRate>=100 && contributionValidation[i].validationType === "min") 
                                    contributionValidation[i].value = 100;
                                else contributionValidation[i].value = 0;
                                if(contributionValidation[i].validationType === "max")
                                    contributionValidation[i].value = minRate;
                            }
                            const ind = contributionValidationdep?.findIndex(item => item.validationType === "max");
                            contributionValidationdep[ind].value = minRate;
                        }
                        const employerContributionField = contributionFields.find((field) => field.fieldName === 'medicalEmployerContribution');
                        const minAllowed = determineMinContribLevel(companyProfile.locations[0].zipData.stateCode,companyProfile.locations[0].totalEligEmps);
                        employerContributionField.defaultValue[0].value = minAllowed === 0 ? '0' : minAllowed.toString();
                    }
                }

                // Life min contribution
                if (companyProfile.selectedProducts.life) {
                    const { fields: contributionFields } = formSections.find((section) => section.sectionName === 'contribution');
                    const lifeContributionField = contributionFields.find((field) => field.fieldName === 'basicLifeEmployerContribution');
                    const minAllowed = minLifeContribLevel(companyProfile.locations[0].zipData.stateCode,companyProfile.locations[0].totalEligEmps);
                    if (lifeContributionField.defaultValue && lifeContributionField.defaultValue.length > 0) {
                        lifeContributionField.defaultValue[0].value = minAllowed;
                    }
                }

                dispatch({
                    type: types.RECEIVE_ENROLLMENT_FORM,
                    employer,
                    eligibility,
                    producer,
                    attestation,
                    employee,
                    employeeAttestation,
                    employerCertification,
                    standardHRA
                });

                // Don't reinitialise enrollment states for B2B cases
                if (!b2b && (workflow === 'renewals' || (shouldInitializeForm && caseProgress === CASE_PROGRESS.QUOTING) || forcefullyInitialize)) {
                    if (workflow !== 'renewals') {
                        dispatch({
                            type: types.INITIALIZE_EMPLOYER_INFO,
                            payload: employer,
                            companyProfile,
                            conditionEvaluators,
                        });
                    }
    
                    dispatch({
                        type: types.INITIALIZE_ELIGIBILITY_INFO,
                        payload: eligibility,
                        eligibilityInfo,
                        quotes,
                        conditionEvaluators,
                    });
    
                    dispatch({
                        type: types.INITIALIZE_PRODUCER_INFO,
                        payload: producer,
                        conditionEvaluators,
                    });
    
                    dispatch({
                        type: types.INITIALIZE_ATTESTATION_INFO,
                        payload: attestation,
                        conditionEvaluators,
                    });
    
                    dispatch({
                        type: types.INITIALIZE_ATTESTATION_INFO,
                        payload: employeeAttestation,
                        conditionEvaluators,
                    });
    
                    dispatch({
                        type: types.INITIALIZE_EMPLOYEE_INFO,
                        payload: employee,
                        conditionEvaluators,
                    });
                    if (workflow !== 'renewals') {
                        dispatch({
                            type: types.INITIALIZE_STANDARD_HRA,
                            payload: standardHRA,
                            conditionEvaluators,
                            caseTracking
                        });
                    }
    
                    dispatch({
                        type: types.INITIALIZE_EMPLOYER_CERTIFICATION_INFO,
                        payload: employerCertification,
                        conditionEvaluators,
                    });
                }
                dispatch({
                    type: types.SET_SHOULD_INITIALIZE_ENROLLMENT_FORM,
                    flag: false
                })
                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.ERROR_ENROLLMENT_FORM,
                    payload: err,
                });
                if(isUhcRenewals()) {
                    const reqBody = {
                        state: stateCode,
                        workflow,
                        platform: workflow === 'renewals' ? marketType : platform
                    }
                    logError(KEY_NAME, UHC_REN_APIS.ENROLLMENT_FORM, 'enrollmentFormActions.getEnrollementForm', reqBody, err);
                }
                reject();
            });
    });
}

export function getEnrollmentFormEligibilityOnly(stateCode, workflow, renewalConditionEvaluator = {}) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch({
            type: types.REQUEST_ENROLLMENT_FORM_ELIGIBILTY_ONLY,
        });

        const { platform } = getState().overall;
        const { companyProfile,overall } = getState();
        const marketType = getMarketType();

        const conditionEvaluators = renewalConditionEvaluator;
        stateCode = (stateCode === "CA" && workflow === 'renewals') ? "CAA" : stateCode;
        axios.get(
            `${apiRoutes.mongo}/enrollment-form?`
                + `state=${stateCode}&workflow=${workflow}&platform=${workflow === 'renewals' ? marketType : platform}`,
        )
            .then((result) => {
                let { formSections } = result.data;
                formSections = augmentNonConfigurableFieldDataInFormSections(formSections);
                const eligibility = formSections.filter((fs) => fs.sectionParentPage === 'Eligibility Information' || fs.sectionName === 'contribution');

                dispatch({
                    type: types.INITIALIZE_ELIGIBILITY_INTO_EMPLOYER_INFO,
                    payload: eligibility,
                    conditionEvaluators,
                    companyProfile,
                    isUhcRenewals: isUhcRenewals(),
                    overall
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERROR_ENROLLMENT_FORM_ELIGIBILTY_ONLY,
                    payload: err,
                });
                if(isUhcRenewals()) {
                    const reqBody = {
                        state: stateCode,
                        workflow,
                        platform: workflow === 'renewals' ? marketType : platform
                    }
                    logError(KEY_NAME, UHC_REN_APIS.ENROLLMENT_FORM, 'enrollmentFormActions.getEnrollmentFormEligibilityOnly', reqBody, err);
                }
                reject();
            });
    });
}

export function updateEligibilityInfofields() {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const fullProps = getState();
        const { locations } = fullProps.companyProfile;
        let eligibleCount = 0;
        let atneCount = 0; let
            fteCount = 0;
        locations.forEach((location) => {
            const { totalEligEmps, fte, atne } = location;
            eligibleCount += parseInt(totalEligEmps, 10);
            atneCount += parseInt(atne, 10);
            fteCount += parseInt(fte, 10);
        });
        const field = 'numEligibleEmployees';

        dispatch({
            type: types.UPDATE_ELIGIBILITY_INFO,
            group: 'participation',
            update: {
                [field]: eligibleCount.toString(),
            },
            isError: false,

        });
        const atneField = 'priorCalendarYearATNE';
        dispatch({
            type: types.UPDATE_ELIGIBILITY_INFO,
            group: 'participation',
            update: {
                [atneField]: atneCount.toString(),
            },
            isError: false,

        });
        const fteField = 'priorCalendarYearFTE';
        dispatch({
            type: types.UPDATE_ELIGIBILITY_INFO,
            group: 'participation',
            update: {
                [fteField]: fteCount.toString(),
            },
            isError: false,

        });
        const classesExcluded = 'classesExcluded';
        dispatch({
            type: types.UPDATE_ELIGIBILITY_INFO,
            group: 'participation',
            update: {
                [classesExcluded]: "No",
            },
            isError: false,

        });
    });
}

export function updatePrimaryLocationEnrollment(zipData) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const zipCode = zipData.zipCode;
        const stateCode = zipData.stateCode;
        const city = zipData.city;
        const zipCode4 = zipData.zipCode4;

        const zipCodeField = 'zipCode';
        const stateField = 'state';
        const cityField = 'city';
        const zipCode4Field = 'zipCode4';
        
        if (zipCode){
            dispatch({
                type: types.UPDATE_EMPLOYER_INFO,
                group: 'primaryLocation',
                update: {
                    [zipCodeField]: zipCode.toString(),
                },
                isError: false,

            });
        }
        if (stateCode){
            dispatch({
                type: types.UPDATE_EMPLOYER_INFO,
                group: 'primaryLocation',
                update: {
                    [stateField]: stateCode.toString(),
                },
                isError: false,

            });
        }
        if (city){
            dispatch({
                type: types.UPDATE_EMPLOYER_INFO,
                group: 'primaryLocation',
                update: {
                    [cityField]: city.toString(),
                },
                isError: false,

            });
        }
        if (zipCode4){
            dispatch({
                type: types.UPDATE_EMPLOYER_INFO,
                group: 'primaryLocation',
                update: {
                    [zipCode4Field]: zipCode4.toString(),
                },
                isError: false,

            });
        }
        
    });
}
