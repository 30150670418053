import moment from "moment";
import { convertDateString, isoStringToMMDDYYYY } from '../../../../utils/formatters/dates';
import { formComparePlanObject, updateExchangeIndicator } from './ComparePlans/RenewalComparePlans';
import { shoppingRequest, getRenewingMedicalPlanRates } from '../RenewalShopping/ShoppingUtils';
import deepCopy from '../../../../utils/deepCopy';
import { getAgeFromDOB } from '../../../../utils/formatters/numbers';
import { FilterRenewalsMedicalData } from './FilterRenewalsMedicalData';
import { identifyPlatform } from "../Review/reviewUtils";
import { findMaxCoverage, findHighestCoverage, FilterIBR } from '../Review/reviewComponents/FilterIBR'
import { getValueOrEmptyString } from "../../../../utils/validation/commonValidations";
import { FilterACAPlans } from "../Review/reviewComponents/FilterACA/FilterACAPlans"
import { getMarketType, MARKET_TYPES, isUhcRenewals, isMmr, isOxfordRenewals } from '../../../../utils/businessLogic/platformUtils';
import { generateIbrFormattedPlan, updateIbrPlanDetails } from "../../../../actions/actionHelpers/mmrHelper";
import { getCoverageTypeFromSelectedProducts } from "../../FullShopping/Census/helpers/validationHelpers";
import { getSpecialtyMembersEnrolledInPlanRenewals } from "../../QuickQuotes/Specialty/SpecialtyPlanDetails/SpecialtyPlanDetailsTabs/SpecialtyEmployeeCostHelper";
import { getReduxState } from "../../../../utils/commonUtils";

const emptyCustomPlanState = {
    medical: {},
    dental: {},
    vision: {},
    life: {},
    depLife: {},
};

const products = ['medical', 'dental', 'vision', 'life', 'dependentLife'];

export const deletePlanSetForProducts = (query, saveAltRates = false) => {
    products.forEach(product => {
        if (query[product] && query[product].set) {
            if (query[product].set.length > 0) {
                let tempSet = [];
                for (let setItem of query[product].set) {
                    let temp = [];
                    for (let item of setItem) {
                        if (saveAltRates && item.renewalPlanType === 'renewing') {
                            temp.push({ ...item });
                        } else {
                            temp.push({ selected: item.selected });
                        }
                    }
                    tempSet.push(temp);
                }
                query[product].set = [...tempSet]
                query.productSetDeleted = true;
            }
        }
    });
}

export const deleteProductsFromQuery = (query) => {
    products.forEach(product => {
        if (query[product]) delete query[product];
    });
}

export const deleteExtrasFromQuery = (query) => {
    delete query.alternate
    delete query.renewalData.members
    delete query.renewalData.Ibr
    delete query.renewalCaseData

    //TODO: Confirm with Chanukya if these are updated on UI anywhere
    delete query.salesRepData
    delete query.agentData
}

export const addToShoppingPlans = (st) => {
    let shoppingPlans = [];
    const formattedPlan = [];
    let finalShoppingPlans = [];

    if (st.review.shoppingPlans && st.review.shoppingPlans.length > 0) {
        shoppingPlans = st.review.shoppingPlans;
        shoppingPlans = updateExchangeIndicator(shoppingPlans)
    }
    let caseStatus = ''
    if (st.renewals.globalSearchFlag) {
        caseStatus = st.renewals.renewalSearchCase.caseProgressUI;
    } else {
        if (caseStatus === '' && st.caseTracking && st.caseTracking.caseTrack[0] && st.caseTracking.caseTrack[0].caseStatus !== '') {
            caseStatus = st.caseTracking.caseTrack[0].caseStatus;
        } else {
            const currentRenewal = st.renewals.renewals.find((plan) => plan.memberGroupId === st.renewals.memberGroupId);
            caseStatus = currentRenewal ? currentRenewal.caseProgressUI : "";
        }
    }
    if (st.review.renewalData && st.review.renewalData.planRates && st.review.renewalData.planRates.length > 0) {
        const renewalSelected = st.review.renewalData.planRates.filter((plan) => ((plan.renewalPlanType === 'Renewal' && plan.productType === "Medical") && (plan.isChecked === true || !plan.hasOwnProperty('isChecked'))));
        renewalSelected.forEach((renewalPlan) => {
            let plan = formComparePlanObject(renewalPlan.planCode, st.review.renewalData)
            if (caseStatus !== 'In Progress' && caseStatus !== 'Not Started') {
                plan.selected = true
            }
            if (shoppingPlans.length > 0) {
                let matchingPlan = shoppingPlans.find(shopPlan => shopPlan.code === plan.code)
                if (matchingPlan) {
                    formattedPlan.push({
                        ...plan,
                        ...matchingPlan
                    });
                } else formattedPlan.push(plan);
            }
            else formattedPlan.push(plan);
        });
    }

    let additionalBool = false;
    shoppingPlans.map((shopPlan) => {
        if (!shopPlan.hasOwnProperty('renewalPlan') && !shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
    });

    if (!additionalBool) {
        finalShoppingPlans = formattedPlan;

    } else {
        shoppingPlans.map((shopPlan, index) => {
            if (!shopPlan.hasOwnProperty('renewalPlan') && !shopPlan.hasOwnProperty('renewalPlanType')) {
                finalShoppingPlans.push(shopPlan);
            } else {
                let checkRenewalPlan = formattedPlan.find(plan => plan.code === shopPlan.code)
                if (checkRenewalPlan && (checkRenewalPlan.isChecked === true || !checkRenewalPlan.hasOwnProperty('isChecked') || checkRenewalPlan.selected === true)) {
                    finalShoppingPlans.push(shopPlan)
                }
            }
        });
    }
    return finalShoppingPlans;
};

export const saveReviewDetailContractChange = async (review, reviewPage = false, renewAsIs = false) => {

    let platform = identifyPlatform(review.renewalData)
    const fullProps=getReduxState()
    let isAutomateMMR = fullProps.renewals.automateMMR ? true : false;
    let ibrObject
    if (review.renewalData.Ibr) {
        ibrObject = await FilterIBR(review.renewalData.Ibr, review);
    }

    const medicalPlans = review.renewalData.Ibr ? ibrObject.medical : [];
    const dentalPlans = review.renewalData.Ibr ? ibrObject.dental : [];
    const visionPlans = review.renewalData.Ibr ? ibrObject.vision : [];
    const lifePlans = review.renewalData.Ibr ? ibrObject.basicLife : [];
    const dependentLifePlans = review.renewalData.Ibr ? ibrObject.dependentLife : [];
    const stdPlans = review.renewalData.Ibr ? ibrObject.shortTermDisability : [];
    const ltdPlans = review.renewalData.Ibr ? ibrObject.ltd : [];
    const suppLifePlans = review.renewalData.Ibr ? ibrObject.suppLife : [];
    const alternate = review.renewalData.Ibr ? ibrObject.alternate : [];
    const isAlternatePlanAvailable = review.renewalData.Ibr ? ibrObject.isAlternatePlanAvailable : null;

    let medicalSelected = getSelectedPlansFromIbrSets(medicalPlans)
    let dentalSelected = getSelectedPlansFromIbrSets(dentalPlans)
    let visionSelected = getSelectedPlansFromIbrSets(visionPlans)
    let lifeSelected = getSelectedPlansFromIbrSets(lifePlans)
    let dependentLifeSelected = getSelectedPlansFromIbrSets(dependentLifePlans)
    let stdSelected = getSelectedPlansFromIbrSets(stdPlans)
    let ltdSelected = getSelectedPlansFromIbrSets(ltdPlans)
    let suppLifeSelected = getSelectedPlansFromIbrSets(suppLifePlans)

    const renewalDataCopy = {
        ...review.renewalData,
        isCompanyProfileUpdated: true,
    };
    let planArr=[]
    let mmrDentalPlans = [];
    let mmrVisionPlans = [];
    let mmrLifePlans = [];
    let mmrDepLifePlans = [];

    if(isAutomateMMR){
        dentalPlans.forEach((itr)=> {
            let plan = itr[0];
            planArr.push(plan);
            planArr.push(plan);
            mmrDentalPlans.push(planArr)
        })
        planArr = [];
        visionPlans.forEach((itr)=> {
            let plan = itr[0];
            planArr.push(plan);
            planArr.push(plan);
            mmrVisionPlans.push(planArr)
        })
        planArr = [];
        lifePlans.forEach((itr)=> {
            let plan = itr[0];
            planArr.push(plan);
            planArr.push(plan);
            mmrLifePlans.push(planArr)
        })
        planArr = [];
        dependentLifePlans.forEach((itr)=> {
            let plan = itr[0];
            planArr.push(plan);
            planArr.push(plan);
            mmrDepLifePlans.push(planArr)
        })

    }
    const query = {
        mdvContribution: review.mdvContribution,
        salesRepData: review.salesRepData,
        agentData: review.agentData,
        memberGroupId: review.renewalCaseData && review.renewalCaseData.memberGroupId ? review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: review.val && review.val.primeCustomerNumber ? review.val.primeCustomerNumber : null,
        renewalCaseData: review.renewalCaseData,
        renewalData: renewalDataCopy,
        medical: { set: medicalPlans, selectedPlans: medicalSelected },
        dental: { set: isAutomateMMR ? mmrDentalPlans : dentalPlans, selectedPlans: isAutomateMMR ? dentalPlans[0] : dentalSelected },
        vision: { set: isAutomateMMR ? mmrVisionPlans : visionPlans, selectedPlans: isAutomateMMR ? visionPlans[0] : visionSelected },
        life: { set: isAutomateMMR ? mmrLifePlans : lifePlans, selectedPlans: isAutomateMMR ? lifePlans[0] : lifeSelected },
        ltd: { set: ltdPlans, selectedPlans: ltdSelected },
        shoppingPlans: review.shoppingPlans,
        dependentLife: { set: isAutomateMMR ? mmrDepLifePlans : dependentLifePlans, selectedPlans: isAutomateMMR ? dependentLifePlans[0] : dependentLifeSelected },
        std: {set: stdPlans, selectedPlans: stdSelected},
        suppLife: {set: suppLifePlans, selectedPlans: suppLifeSelected},
        alternate: alternate,
        caseSubmitted: (review && review.caseSubmitted) ? review.caseSubmitted : false,
        customPlanNames: review.customPlanNames ? review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: review.enrolledPlansMed ? review.enrolledPlansMed : {},
        employeesEnrollStatus: review.employeesEnrollStatus ? review.employeesEnrollStatus : 'In Progress',
        isMMRCase: review.renewalCaseData.isMMRCase ? review.renewalCaseData.isMMRCase : false,
        enrolledPlans: medicalSelected,
        enrolledDentalPlans: dentalSelected,
        enrolledVisionPlans: visionSelected,
        enrolledLifePlans: lifeSelected,
        enrolledDependentLifePlans: dependentLifeSelected,
        enrolledStdPlans: stdSelected,
        enrolledLtdPlans : ltdSelected
    };

    if (isUhcRenewals()) {
        query.renewalClassingOpted = review.renewalClassingOpted ? review.renewalClassingOpted : false;
        query.isMMRCase = isMmr()
        const groupTierStructureId = review.renewalData.Ibr ? ibrObject.tierStructureId : null;
        query.groupTierStructureId = groupTierStructureId;
        query.isAlternatePlanAvailable = isAlternatePlanAvailable
        if (!query.isMMRCase && !renewAsIs) {
            query.duplicatePlans = true
            deleteExtrasFromQuery(query);
            delete query.enrolledPlans
            delete query.enrolledDentalPlans
            delete query.enrolledVisionPlans
            delete query.enrolledLifePlans
            delete query.enrolledDependentLifePlans
            delete query.enrolledStdPlans
            delete query.enrolledLtdPlans
        }
    }

    return query;
};
export const makeCirrusSpecialyACAQuery = (review) => {


    const dentalPlans = FilterACAPlans(review.renewalData).specialtyDentalPlans;
    const visionPlans = FilterACAPlans(review.renewalData).specialtyVisionPlans;
    const lifePlans = [];
    const dependentLifePlans = [];
    const alternate = [];

    let dentalSelected = getSelectedPlansFromIbrSets(dentalPlans)
    let visionSelected = getSelectedPlansFromIbrSets(visionPlans)
    let lifeSelected = []
    let dependentLifeSelected = []

    const renewalDataCopy = {
        ...review.renewalData,
        isCompanyProfileUpdated: true,
    };

    const query = {
        mdvContribution: review.mdvContribution,
        salesRepData: review.salesRepData,
        agentData: review.agentData,
        memberGroupId: review.renewalCaseData && review.renewalCaseData.memberGroupId ? review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: review.val && review.val.primeCustomerNumber ? review.val.primeCustomerNumber : null,
        renewalCaseData: review.renewalCaseData,
        renewalData: renewalDataCopy,
        dental: { set: dentalPlans, selectedPlans: dentalSelected },
        vision: { set: visionPlans, selectedPlans: visionSelected },
        life: { set: lifePlans, selectedPlans: lifeSelected },
        shoppingPlans: review.shoppingPlans,
        dependentLife: { set: dependentLifePlans, selectedPlans: dependentLifeSelected },
        alternate: alternate,
        caseSubmitted: (review && review.caseSubmitted) ? review.caseSubmitted : false,
        customPlanNames: review.customPlanNames ? review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: review.enrolledPlansMed ? review.enrolledPlansMed : {},
        employeesEnrollStatus: review.employeesEnrollStatus ? review.employeesEnrollStatus : 'In Progress',
    };

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            delete query.renewalData.Ibr
            delete query.renewalData.members
        }
    }

    return query;
};

const handleMoreToLessUhcRenewals = (medicalPlans) => {
    let medicalPlansMap = new Map();
    for (let medicalPlan of medicalPlans) {
        const key = {
            code: medicalPlan.code,
            pkg: medicalPlan.selectedPackages[0]
        }

        let mappingFound = false;

        for (let [mapKey, mappedPlan] of medicalPlansMap) {
            if (mapKey.code === key.code && mapKey.pkg === key.pkg) {
                medicalPlansMap.set(mapKey, {
                    ...medicalPlan,
                    //TODO: might need the below code in future for scenarios when enrolled members are different
                    // employeeRates: [...mappedPlan.employeeRates, ...medicalPlan.employeeRates],
                    // member: [...mappedPlan.member, ...medicalPlan.member],
                    // enrolledCount: parseInt(mappedPlan.enrolledCount) + parseInt(medicalPlan.enrolledCount),
                    // finalMonthlyPremium: parseFloat(mappedPlan.finalMonthlyPremium) + parseFloat(medicalPlan.finalMonthlyPremium),
                    // quote: parseFloat(mappedPlan.quote) + parseFloat(medicalPlan.quote)
                });
                mappingFound = true;
                break;
            }
        }

        if (!mappingFound) {
            medicalPlansMap.set(key, { ...medicalPlan });
        }
    }

    let mergedMedicalPlan = [];
    for (let [key, medicalPlan] of medicalPlansMap) {
        mergedMedicalPlan.push(medicalPlan);
    }
    return mergedMedicalPlan;
}

export const saveReviewDetailQuery = (st, proposalSave = false) => {
    const { keepMedical, keepDental, keepVision, keepLife, keepDepLife, keepLtd, keepStd, keepSuppLife } = st.premium;

    const renewalDataCopy = {
        ...st.review.renewalData,
        keepMedical: keepMedical ? keepMedical : false,
        keepDental: keepDental ? keepDental : false,
        keepVision: keepVision ? keepVision : false,
        keepLife: keepLife ? keepLife : false,
        keepLTD : keepLtd ? keepLtd : false,
        keepStd : keepStd ? keepStd :false,
        keepSuppLife: keepSuppLife ? keepSuppLife :false,
        keepDepLife: keepDepLife ? keepDepLife : false,
        callingPage: 'review',
        renewAsIs: false,
    };

    const query = {
        mdvContribution: st.review.mdvContribution,
        salesRepData: st.review.salesRepData,
        agentData: st.review.agentData,
        memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.review.renewalCaseData,
        renewalData: renewalDataCopy,
        dental: st.review.dental ? deepCopy(st.review.dental) : [],
        vision: st.review.vision ? deepCopy(st.review.vision) : [],
        life: st.review.life ? deepCopy(st.review.life) : [],
        dependentLife: st.review.dependentLife ? deepCopy(st.review.dependentLife) : [],
        ltd: st.review.ltd ? deepCopy(st.review.ltd) : [],
        std: st.review.std ? deepCopy(st.review.std) : [],
        suppLife : st.review.suppLife ? deepCopy(st.review.suppLife):[],
        shoppingPlans: st.review.shoppingPlans ? st.review.shoppingPlans : [],
        alternate: st.review.alternate ? st.review.alternate : [],
        caseSubmitted: (st && st.review && st.review.caseSubmitted) ? st.review.caseSubmitted : false,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            reviewDetail: true,
        },
        customPlanNames: st.review.customPlanNames ? st.review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.review.enrolledPlansMed ? st.review.enrolledPlansMed : {},
        employeesEnrollStatus: st.review.employeesEnrollStatus ? st.review.employeesEnrollStatus : 'In Progress',
    };

    if (getMarketType() === MARKET_TYPES.UHC) {
        query.renewalClassingOpted = st.review.renewalClassingOpted ? st.review.renewalClassingOpted : false,
        query.medical = deepCopy(st.review.medical);
        query.members = st.review.members;
        query.shoppingPlans = handleMoreToLessUhcRenewals(st.review.medical && st.review.medical.selectedPlans ? st.review.medical.selectedPlans : query.shoppingPlans);
        // query.shoppingPlans = st.review.medical && st.review.medical.selectedPlans ? st.review.medical.selectedPlans : query.shoppingPlans;
        query.shoppingDentalPlans = st.review.dental ? st.review.dental.selectedPlans ? st.review.dental.selectedPlans : [] : [];
        query.shoppingVisionPlans = st.review.vision ? st.review.vision.selectedPlans ? st.review.vision.selectedPlans : [] : [];
        query.shoppingLifePlans = st.review.life ? st.review.life.selectedPlans ? st.review.life.selectedPlans : [] : [];
        query.shoppingDepLifePlans = st.review.dependentLife ? st.review.dependentLife.selectedPlans ? st.review.dependentLife.selectedPlans : [] : [];
        query.shoppingLTDPlans = st.review.ltd ? st.review.ltd.selectedPlans ? st.review.ltd.selectedPlans : [] : [];
        query.shoppingSTDPlans = st.review.std ? st.review.std.selectedPlans ? st.review.std.selectedPlans : [] : [];
        query.shoppingSuppLifePlans = st.review.suppLife ? st.review.suppLife.selectedPlans ? st.review.suppLife.selectedPlans : [] : [];
        query.isMMRCase = isMmr();
        query.groupTierStructureId = st.review.groupTierStructureId

        if (!proposalSave && !query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query);
        }
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    return query;
};

const generateIbrPlanMatch = (productSet, renewingPlans, finalPlanMatch) => {
    productSet.set.forEach((planSet, planIndex) => {
        let planCodeField = planSet[1].planType.toLowerCase() === 'life' || planSet[1].planType.toLowerCase() === 'life_dep_basic' ? 'primePlanCode' : 'code'
        finalPlanMatch.push({
            currentPlanXref: planSet[1][planCodeField] + (planSet[1].planType === 'Medical' ? '/' + planSet[1].rxPlanCode : ''),
            renewingPlanXref: renewingPlans.length > planIndex ?
                renewingPlans[planIndex][planCodeField] + (renewingPlans[planIndex].planType === 'Medical' ? '/' + renewingPlans[planIndex].rxPlanCode : '') :
                renewingPlans.length > 0 ? renewingPlans[renewingPlans.length - 1][planCodeField] + (renewingPlans[renewingPlans.length - 1].planType === 'Medical' ? '/' + renewingPlans[renewingPlans.length - 1].rxPlanCode : '') : ''
        })
    })
    if (productSet.set.length < renewingPlans.length) {    
        let planCodeField = renewingPlans[0].planType.toLowerCase() === 'life' || renewingPlans[0].planType.toLowerCase() === 'life_dep_basic' ? 'primePlanCode' : 'code'
        for (let i = productSet.set.length; i < renewingPlans.length; i++) {
            finalPlanMatch.push({
                currentPlanXref: '',
                renewingPlanXref: renewingPlans[i][planCodeField] + (renewingPlans[i].planType === 'Medical' ? '/' + renewingPlans[i].rxPlanCode : '')
            })
        }
    }
}
const createLifePlanDetailsObj = (plan)=>{
    let obj ={
        ADandDCode: plan.ADandDCode,   //for DepLife it's null
        isMultiStatePlan: plan.isMultiStatePlan,
        planName: plan.name,
        planCode: plan.code,
        fundingType: plan.fundingType,
        productType: plan.productType,
        benefitType: plan.benefitDetails.benefitType,
        primePlanCode: plan.primePlanCode,
    }
    return obj
}
export const addRenewingPlansInIbr = (st, isMMRCase) => {
    let finalIbr = JSON.parse(JSON.stringify(st.renewals.renewalData.Ibr))
    let { medical, dental, vision, life, dependentLife, ltd, std, suppLife} = isMMRCase ? st.decide : st.renewalEnrollment
    let stateCode = finalIbr.group?.primaryLocation?.clientDefinedData?.data?.find(cdd=>cdd.key === 'ContractIssueStCode')?.value || '';
    let existingLifePlanDetails = isMMRCase ? st.renewals.renewalData.lifePlanDetails : [];
    let fppExists = false;
    let finalPlanMatch = []
    let selectedPlans = []
    let lifeClassOpted = false;
    let planPkgFieldName = ''
    let planMatchFieldName = ''
    let lifeClassExists = false;
    let newLifePlanDetails = existingLifePlanDetails
    if (isMMRCase) {
        if (finalIbr.quote.planOfferingData[0]?.newPlanPkg?.plans?.plan?.length > 0 && !st.decide.hwTriggerError) {
            finalIbr.quote.planOfferingData.forEach(planOffer => {
                planOffer.renewingPlanPkg = {
                    plans: {
                        plan: []
                    }
                }
            })
            // finalIbr.quote.planOfferingData[0].renewingPlanPkg = {
            //     plans: {
            //         plan: []
            //     }
            // }
            planPkgFieldName = 'renewingPlanPkg'
            planMatchFieldName = 'renewingPlanMatch'
        } else {
            planPkgFieldName = 'newPlanPkg'
            planMatchFieldName = 'planMatch'
        }
        selectedPlans = []
        if (medical && medical.selectedPlans && medical.set) {
            selectedPlans.push(...medical.selectedPlans)
            medical.set.forEach(planSet => finalPlanMatch.push({
                currentPlanXref: planSet[0].medicalPlanCode,
                renewingPlanXref: planSet[1].medicalPlanCode
            }))
        }
        if (dental && dental.selectedPlans && dental.set) {
            selectedPlans.push(...dental.selectedPlans)
            dental.set.forEach(planSet => finalPlanMatch.push({
                currentPlanXref: planSet[0].code,
                renewingPlanXref: planSet[1].code
            }))
        }
        if (vision && vision.selectedPlans && vision.set) {
            selectedPlans.push(...vision.selectedPlans)
            vision.set.forEach(planSet => finalPlanMatch.push({
                currentPlanXref: planSet[0].code,
                renewingPlanXref: planSet[1].code
            }))
        }
        if (life && life.selectedPlans && life.set) {
            lifeClassExists = life.selectedPlans.length > 1 ? true : false;
            if(lifeClassExists){
                selectedPlans.push(...life.selectedPlans)
                //lifeClassing Group and mmr resubmit
                if(finalIbr.quote.planOfferingData[1]?.newPlanPkg?.plans?.plan?.length > 0 && !st.decide.hwTriggerError && planMatchFieldName === "renewingPlanMatch")
                {
                    for(let i=1; i<finalIbr.quote.planOfferingData.length; i++){
                        finalIbr.quote.planOfferingData[i][planMatchFieldName] = []
                        life.set.forEach(planSet=>{
                            finalIbr.quote.planOfferingData[i][planMatchFieldName].push({
                                currentPlanXref: planSet[0].primePlanCode,
                                renewingPlanXref: ''
                            })
                        })
                    }
                }
                
                life.set.forEach(planSet =>{
                    finalIbr.quote.planOfferingData.forEach(planOffer=>{
                        planOffer.planMatch && planOffer.planMatch.forEach((planMtch, index)=>{
                            if(planMtch.currentPlanXref ===  planSet[0].primePlanCode){
                                planOffer[planMatchFieldName][index]['renewingPlanXref'] = planSet[1].primePlanCode
                                if(planMatchFieldName === "renewingPlanMatch"){
                                    planOffer[planMatchFieldName][index].currentPlanXref = planOffer.planMatch[index].renewingPlanXref
                                }
                            }
                        })
                    })
                })
            }
           else{
                //adding life plan in lifePlanDetails if doesn't exist
                if(existingLifePlanDetails.length>0)
                {
                    const stepWisePlanCode = life.selectedPlans[0].code
                    let lifePlanDetailsCode = existingLifePlanDetails.map(plan=>plan.planCode)
                    if(!lifePlanDetailsCode.includes(stepWisePlanCode))
                    {
                        //different Life Plan
                        newLifePlanDetails.push(createLifePlanDetailsObj(life.selectedPlans[0]))
                    }
                }
                selectedPlans.push(...life.selectedPlans)
                life.set.forEach(planSet => finalPlanMatch.push({
                    currentPlanXref: planSet[0].primePlanCode,
                    renewingPlanXref: planSet[1].primePlanCode
                }))
           }
           
        }
        if (dependentLife && dependentLife.selectedPlans && dependentLife.selectedPlans.length >0 && dependentLife.set) {
            //adding depLife plan in lifePlanDetails if doesn't exist
            if(existingLifePlanDetails.length>1)
            {
                const stepWisePlanCode = dependentLife.selectedPlans[0].code
                let lifePlanDetailsCode = existingLifePlanDetails.map(plan=>plan.planCode)
                if(!lifePlanDetailsCode.includes(stepWisePlanCode))
                {
                    newLifePlanDetails.push(createLifePlanDetailsObj(dependentLife.selectedPlans[0]))
                }
            }
            selectedPlans.push(...dependentLife.selectedPlans)
            dependentLife.set.forEach(planSet => finalPlanMatch.push({
                currentPlanXref: planSet[0].primePlanCode,
                renewingPlanXref: planSet[1].primePlanCode
            }))

            // push same plan for to make dep life child object in IBR
            dependentLife.selectedPlans.forEach(plan => {
                let tempObj = JSON.parse(JSON.stringify(plan))
                tempObj.productType = tempObj.planType = 'BASIC_LIFE_CHILD'
                selectedPlans.push(tempObj)
            })
        }    
        //mmr resubmit  and planMatchField is renewingPlanMatch && fpp exists
        if(finalIbr.quote.planOfferingData[1]?.newPlanPkg?.plans?.plan?.length > 0 && !st.decide.hwTriggerError && planMatchFieldName === "renewingPlanMatch" && (ltd && ltd.selectedPlans && ltd.set || std && std.selectedPlans && std.set || suppLife && suppLife.selectedPlans && suppLife.set)){
            // running loop from 1st as for MDVL it's already set below and loop is for classing
            for(let i=1; i<finalIbr.quote.planOfferingData.length; i++){
                finalIbr.quote.planOfferingData[i][planMatchFieldName] = []
                if(ltd && ltd.selectedPlans && ltd.set && ltd.selectedPlans.length > 0 && ltd.set.length > 0){
                    finalIbr.quote.planOfferingData[i][planMatchFieldName].push(
                        {
                            currentPlanXref: ltd.set[0][0].piaPlanCode,
                            renewingPlanXref: ''
                        }
                    )
                }
                if(std && std.selectedPlans && std.set && std.selectedPlans.length > 0 && std.set.length > 0){
                    finalIbr.quote.planOfferingData[i][planMatchFieldName].push(
                        {
                            currentPlanXref: std.set[0][0].piaPlanCode,
                            renewingPlanXref: ''
                        }
                    )
                }
                // want to add all suppLife plans in finalPlanMatch
                const suppLifeKeys=[  "SLChADDPlan","SLChPlan", "SLEEADDPlan","SLSpPlan","SLSpADDPlan","SLEEPlan"]
                if(suppLife && suppLife.selectedPlans && suppLife.set && suppLife.set.length>0 && suppLife.selectedPlans.length > 0){
                    suppLifeKeys.forEach((suppLifeKey)=>{
                        suppLife.set.forEach(planSet => finalIbr.quote.planOfferingData[i][planMatchFieldName].push({
                            currentPlanXref: planSet[0][suppLifeKey].piaPlanCode,
                            renewingPlanXref: ''
                        }))
                    })
                } 
            }                 
        }   
        if (ltd && ltd.selectedPlans && ltd.set && ltd.selectedPlans.length > 0) {
            fppExists = true
            selectedPlans.push(...ltd.selectedPlans)
            ltd.set.forEach(planSet =>{
                finalIbr.quote.planOfferingData.forEach(planOffer=>{
                    planOffer.planMatch && planOffer.planMatch.forEach((planMtch, index)=>{
                        if(planOffer[planMatchFieldName] && (planMtch.currentPlanXref ===  planSet[0].piaPlanCode)){
                            planOffer[planMatchFieldName][index]['renewingPlanXref'] = planSet[1].piaPlanCode
                            if(planMatchFieldName === "renewingPlanMatch"){
                                planOffer[planMatchFieldName][index].currentPlanXref = planOffer.planMatch[index].renewingPlanXref
                            }
                        }
                    })
                })
            })
        }
        if (std && std.selectedPlans && std.set && std.selectedPlans.length > 0) {
            fppExists = true
            selectedPlans.push(...std.selectedPlans)
            std.set.forEach(planSet =>{
                finalIbr.quote.planOfferingData.forEach(planOffer=>{
                    planOffer.planMatch && planOffer.planMatch.forEach((planMtch, index)=>{
                        if(planOffer[planMatchFieldName] && (planMtch.currentPlanXref ===  planSet[0].piaPlanCode)){
                            planOffer[planMatchFieldName][index]['renewingPlanXref'] = planSet[1].piaPlanCode
                            if(planMatchFieldName === "renewingPlanMatch"){
                                planOffer[planMatchFieldName][index].currentPlanXref = planOffer.planMatch[index].renewingPlanXref
                            }
                        }
                    })
                })
            })
        }
        if (suppLife && suppLife.selectedPlans && std.set && suppLife.selectedPlans.length > 0) {
            fppExists = true
            selectedPlans.push(...suppLife.selectedPlans)
            // suppLife.set.forEach(planSet => finalPlanMatch.push({
            //     currentPlanXref: planSet[0].primePlanCode,
            //     renewingPlanXref: planSet[1].primePlanCode
            // }))
            const suppLifeKeys=[  "SLChADDPlan","SLChPlan", "SLEEADDPlan","SLSpPlan","SLSpADDPlan","SLEEPlan"]
            
            
            suppLife.set.forEach(planSet =>{
                finalIbr.quote.planOfferingData.forEach(planOffer=>{
                    suppLifeKeys.forEach((suppLifeKey)=>{
                        planOffer.planMatch && planOffer.planMatch.forEach((planMtch, index)=>{
                          
                                if(planOffer[planMatchFieldName] && (planMtch.currentPlanXref ===  planSet[0][suppLifeKey].piaPlanCode)){
                                    planOffer[planMatchFieldName][index]['renewingPlanXref'] = suppLifeKey !="SLEEPlan" ? planSet[1][suppLifeKey].piaPlanCode :planSet[1].piaPlanCode
                                    if(planMatchFieldName === "renewingPlanMatch"){
                                        planOffer[planMatchFieldName][index].currentPlanXref = planOffer.planMatch[index].renewingPlanXref
                                    }
                                }
                          
                        })
                    })
                   
                })
            })
        }
        // add renewing plans in census
        let memberRenewingPlans = {}
        const coverageTypeMap = {
            'EE': 'EMP',
            'EE/SP': 'ESP',
            'EE/CH': 'ECH',
            'EE/FAM': 'FAM',
            'W': 'WAV',
        }
        let planToProductTypeMapping = {}
        selectedPlans.forEach(plan => {
            //removing duplicate depLife plans
            if(plan.planType.toLowerCase() === 'basic_life_child')
            {
                return
            }
            //planToProduct mapping
            if (plan.planType === 'Medical') {
                planToProductTypeMapping[plan.medicalPlanCode] =  plan.planType
            }
            else if (plan.planType.toLowerCase() === 'life' || plan.planType.toLowerCase() === 'life_dep_basic' || plan.planType.toLowerCase() === 'basic_life_child') {
                planToProductTypeMapping[plan.primePlanCode] =  plan.planType.toLowerCase()
            } else if(plan.planType.toLowerCase() === 'ltd' || plan.planType.toLowerCase() === 'std' || plan.planType.toLowerCase()==="supplement life" ){
                planToProductTypeMapping[plan.piaPlanCode] =  plan.planType.toLowerCase()
            } else {
                planToProductTypeMapping[plan.code] = plan.planType
            }
            // planToProductTypeMapping.push(obj)
            plan.member.forEach(member => {
                if (!memberRenewingPlans.hasOwnProperty(member.employeeId)) {
                    memberRenewingPlans[member.employeeId] = []
                }
                memberRenewingPlans[member.employeeId].push({
                    planID: plan.planType === 'Medical' ? plan.medicalPlanCode : 
                    ['life', 'life_dep_basic', 'basic_life_child'].includes(plan.planType) ? plan.primePlanCode : 
                    ['LTD', 'STD', "Supplement Life"].includes(plan.planType) ? plan.piaPlanCode : plan.code,
                    coverageType: coverageTypeMap[member.coverages[plan.planType.toLowerCase()]] || 'EMP',
                    rate: member.total
                })
            })
        })
        finalIbr.quote.census.employee = finalIbr.quote.census.employee.map(employee => {
            employee.renewingPlans.plan = memberRenewingPlans[employee.xref]
            if (employee.dependents && employee.dependents.dependent) {
                employee.dependents.dependent.forEach(dependent => {
                    dependent.renewingCoverageElections.plan = JSON.parse(JSON.stringify(memberRenewingPlans[employee.xref])) 
                    //to filterOut plans for dependents excluding life  
                    let newDependentPlans= dependent.renewingCoverageElections.plan.filter(plan=>{
                        let productType= planToProductTypeMapping[plan.planID]
                        return productType!=="life"
                    })     
                    dependent.renewingCoverageElections.plan = newDependentPlans
                    dependent.renewingCoverageElections.plan.forEach(plan => {
                        //for spouse and child plan.rate won't be zero
                        let productType= planToProductTypeMapping[plan.planID]
                        if(productType!=='life_dep_basic' && productType!=='basic_life_child')
                        {
                            plan.rate = "0.00"
                        }     
                        else{    
                            //coverageType same for spouse and child                        
                            plan.coverageType = "SPC"                           
                        }                  
                    })
                })
            }

            employee.renewingPlans?.plan?.forEach(plan=>{
                let productType= planToProductTypeMapping[plan.planID]
                //need to set planRate in dependent.renewingCoverageElections(so changing in employee afterwards)
                if (productType==='life_dep_basic' || productType==='basic_life_child') {
                    plan.coverageType = "WAV"
                    plan.rate = "0.00"
                }
            })
            return employee
        })

        if (planMatchFieldName === "renewingPlanMatch") {
            finalPlanMatch.forEach((planMatchObj, index) => {
                if(finalIbr.quote.planOfferingData[0].planMatch[index]){
                    planMatchObj.currentPlanXref = finalIbr.quote.planOfferingData[0].planMatch[index].renewingPlanXref
                }
            })
            // finalFPPPlanMatch.forEach((planMatchObj, index) => {
            //     planMatchObj.currentPlanXref = finalIbr.quote.planOfferingData[1].planMatch[index].renewingPlanXref
            // })
        }
    } else {
        let enrolledPlans = [];
        let enrolledDentalPlans = [];
        let enrolledVisionPlans = [];
        let enrolledLifePlans = [];
        let enrolledDependentLifePlans = [];
        let enrolledChildLifePlans = [];
        if (st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }

        if (st.rules.enrollment.enrolledDentalPlans && st.rules.enrollment.enrolledDentalPlans.length > 0) { enrolledDentalPlans = st.rules.enrollment.enrolledDentalPlans; }
        if (st.rules.enrollment.enrolledVisionPlans && st.rules.enrollment.enrolledVisionPlans.length > 0) { enrolledVisionPlans = st.rules.enrollment.enrolledVisionPlans; }
        if (st.rules.enrollment.enrolledLifePlans && st.rules.enrollment.enrolledLifePlans.length > 0) { enrolledLifePlans = st.rules.enrollment.enrolledLifePlans; }
        if (st.rules.enrollment.enrolledDependentLifePlans && st.rules.enrollment.enrolledDependentLifePlans.length > 0) { enrolledDependentLifePlans = st.rules.enrollment.enrolledDependentLifePlans; }

      
      
        // remove PRIME plans in case of DUAL platform
        enrolledPlans = enrolledPlans.filter(plan => plan.installPlatform === "CIRRUS" || plan.installPlatform === null || plan.installPlatform === undefined)
        enrolledDentalPlans = enrolledDentalPlans.filter(plan => plan.installPlatform === "CIRRUS" || plan.installPlatform === null || plan.installPlatform === undefined)
        enrolledVisionPlans = enrolledVisionPlans.filter(plan => plan.installPlatform === "CIRRUS" || plan.installPlatform === null || plan.installPlatform === undefined)
        enrolledLifePlans = enrolledLifePlans.filter(plan => plan.installPlatform === "CIRRUS" || plan.installPlatform === null || plan.installPlatform === undefined)
        enrolledDependentLifePlans = enrolledDependentLifePlans.filter(plan => plan.installPlatform === "CIRRUS" || plan.installPlatform === null || plan.installPlatform === undefined)

        // add member and enrolled count in life and dep life plan
        enrolledLifePlans.forEach(plan => {
            plan.member = getSpecialtyMembersEnrolledInPlanRenewals('enrollment', st.renewalEnrollment, st.companyProfile, plan, 'life', 'available')
            plan.enrolledCount = plan?.member.length || 0
        })
        enrolledDependentLifePlans.forEach(plan => {
            plan.member = getSpecialtyMembersEnrolledInPlanRenewals('enrollment', st.renewalEnrollment, st.companyProfile, plan, 'dependentLife', 'available')
            plan.enrolledCount = plan?.member.length || 0
        })

        // push same plan for to make dep life child object in IBR
        enrolledChildLifePlans = JSON.parse(JSON.stringify(enrolledDependentLifePlans))
        enrolledChildLifePlans.forEach(plan => {
            plan.productType = plan.planType = 'BASIC_LIFE_CHILD'
        })

        if(stateCode === "CA"){                     //so in case of DUAL,no need to pass NICE plans
            let updatedMedical = medical.set.filter(planSet => (planSet[0].platform !== "NICE" || planSet[1].platform !== "NICE"))
            medical.set = updatedMedical
        }
        if(stateCode === "CA"){
            enrolledPlans = enrolledPlans.filter(plan => plan.employeeCount > 0)
        }
        
        generateIbrPlanMatch(medical, enrolledPlans, finalPlanMatch)
        generateIbrPlanMatch(dental, enrolledDentalPlans, finalPlanMatch)
        generateIbrPlanMatch(vision, enrolledVisionPlans, finalPlanMatch)
        if(enrolledLifePlans && enrolledLifePlans.length >0) {
            if(!enrolledLifePlans[0].selectedClass){
                generateIbrPlanMatch(life, enrolledLifePlans, finalPlanMatch);
                generateIbrPlanMatch(dependentLife, enrolledDependentLifePlans, finalPlanMatch)
            }
        }
        if(enrolledLifePlans && enrolledLifePlans.length > 0 && enrolledLifePlans[0].selectedClass) {
            lifeClassOpted = true;
            selectedPlans = [...enrolledPlans, ...enrolledDentalPlans, ...enrolledVisionPlans]
        } else {
            selectedPlans = [...enrolledPlans, ...enrolledDentalPlans, ...enrolledVisionPlans, ...enrolledLifePlans, ...enrolledDependentLifePlans, ...enrolledChildLifePlans]
        }
       
        finalIbr.quote.planOfferingData.forEach(planoff=>{
            planoff["renewingPlanPkg"] = {
                plans: {
                    plan: []
                }
            }
            planPkgFieldName = 'renewingPlanPkg'
            planMatchFieldName = 'renewingPlanMatch'
        })
        // finalIbr.quote.planOfferingData[0].existingPlanPkg.plans.plan = finalIbr.quote.planOfferingData[0].newPlanPkg.plans.plan
    }
    let products = [];
    //finalIbr.quote.planOfferingData[0][planPkgFieldName].plans.plan = []
    finalIbr.quote.planOfferingData.forEach(planOffer=>{
        planOffer[planPkgFieldName].plans.plan = []
        if(!isMMRCase){
            planOffer?.newPlanPkg?.plans.plan.forEach(pln =>{
                if(!products.includes(pln.productLineType)){
                    products.push(pln.productLineType);
                }
            });
        }
    })
    if(products.includes("MEDICAL") || products.includes("DENTAL") || products.includes("VISION") || (products.includes("BASIC_LIFE_EE") && !lifeClassOpted)){
                
    } else if(!isMMRCase && products && products.length > 0){
        finalIbr.quote.planOfferingData.unshift({
            planOfferingID:"",
            planOfferingXref:"",
            existingPlanPkg:{
                plans: {
                    plan: []
                }},
            newPlanPkg:{
                plans: {
                    plan: []
                }},
            planMatch:[],
            renewingPlanPkg:{
                plans: {
                    plan: []
                }},
            
        })
    }
    // add renewing plans in newPlanPkg

    let selectedSuppLifePlans = selectedPlans.filter(plan => plan.planType === "Supplement Life");
    selectedPlans = selectedPlans.filter(plan => plan.planType !== "Supplement Life"); 

    let selectedPlansCopy = JSON.parse(JSON.stringify(selectedPlans.map(plan => generateIbrFormattedPlan(plan, isMMRCase))));

    if (selectedSuppLifePlans.length > 0) {
        const modifyRates = (plan, finalRates) => {
            return {
                ...plan,
                finalRates
            }
        }
        const memberSuppLifePlans = [];
        let selectedSupLifePlansCopy
        if(selectedSuppLifePlans[0]?.isSuppLifeDep === false){
            selectedSuppLifePlans.forEach(SlEEPlan =>{
                const {SLEEADDPlan, finalRates}= SlEEPlan;
                memberSuppLifePlans.push(SlEEPlan,modifyRates(SLEEADDPlan, finalRates) )
                selectedSupLifePlansCopy = JSON.parse(JSON.stringify(memberSuppLifePlans.map(plan => generateIbrFormattedPlan(plan, isMMRCase))));
            })
        }else{
            selectedSuppLifePlans.forEach(SLEEPlan => {
                const { SLChADDPlan, SLChPlan, SLEEADDPlan, SLSpADDPlan, SLSpPlan, finalRates } = SLEEPlan;
                memberSuppLifePlans.push(SLEEPlan, modifyRates(SLChADDPlan, finalRates), modifyRates(SLChPlan, finalRates), modifyRates(SLEEADDPlan, finalRates), modifyRates(SLSpADDPlan, finalRates), modifyRates(SLSpPlan, finalRates))
                selectedSupLifePlansCopy = JSON.parse(JSON.stringify(memberSuppLifePlans.map(plan => generateIbrFormattedPlan(plan, isMMRCase))));
            })
        }
        selectedPlansCopy = [...selectedPlansCopy, ...selectedSupLifePlansCopy];
    }

    if(isMMRCase && fppExists){
        // let selectedPlansCopyFPP = selectedPlansCopy.filter(pln=> ['LTD','STD'].includes(pln.productLineType))
        // finalIbr.quote.planOfferingData[1][planPkgFieldName].plans.plan = selectedPlansCopyFPP
        // finalIbr.quote.planOfferingData[1][planMatchFieldName] = finalPlanMatch
        // selectedPlansCopy = selectedPlansCopy.filter(pln=> !['LTD','STD'].includes(pln.productLineType))
        let selectedPlansCopyFPP = selectedPlansCopy.filter(pln=> ['LTD','STD', "SUP_LIFE_EE","SUP_ADD_EE","SUP_LIFE_SPOUSE","SUP_ADD_SPOUSE","SUP_LIFE_CHILD","SUP_ADD_CHILD" ].includes(pln.productLineType))
        selectedPlansCopyFPP.forEach(selectedPlan=>{
            finalIbr.quote.planOfferingData.forEach(planOffering=>{
                planOffering[planMatchFieldName] && planOffering[planMatchFieldName].forEach(plnMatch=>{
                    if(plnMatch.renewingPlanXref === selectedPlan.planID){
                        planOffering[planPkgFieldName].plans.plan.push(selectedPlan)
                    }
                })
            })
        })
        //finalIbr.quote.planOfferingData[1][planPkgFieldName].plans.plan = selectedPlansCopyFPP
        selectedPlansCopy = selectedPlansCopy.filter(pln=> !['LTD','STD',  "SUP_LIFE_EE","SUP_ADD_EE","SUP_LIFE_SPOUSE","SUP_ADD_SPOUSE","SUP_LIFE_CHILD","SUP_ADD_CHILD"].includes(pln.productLineType))
    }
    
    if(isMMRCase && lifeClassExists){
        let selectedPlansCopylifeClass = selectedPlansCopy.filter(pln=> ['BASIC_LIFE_EE'].includes(pln.productLineType))
        
        selectedPlansCopylifeClass.forEach(selectedPlan=>{
            finalIbr.quote.planOfferingData.forEach(planOffering=>{
                planOffering[planMatchFieldName] && planOffering[planMatchFieldName].forEach(plnMatch=>{
                    if(plnMatch.renewingPlanXref === selectedPlan.planID){
                        planOffering[planPkgFieldName].plans.plan.push(selectedPlan)
                    }
                })
            })
        })
        //finalIbr.quote.planOfferingData[1][planPkgFieldName].plans.plan = selectedPlansCopyFPP
        selectedPlansCopy = selectedPlansCopy.filter(pln=> !['BASIC_LIFE_EE'].includes(pln.productLineType))
    }

    if(selectedPlansCopy.length > 0){
        finalIbr.quote.planOfferingData[0][planPkgFieldName].plans.plan = selectedPlansCopy
    }
    if (!isMMRCase) {
        // update Ibr plans cdd/planoption details
        updateIbrPlanDetails(finalIbr, st, planPkgFieldName)
    }
    // add renewing plans in planMatch
    if(finalPlanMatch && finalPlanMatch.length > 0){
        finalIbr.quote.planOfferingData[0][planMatchFieldName] = finalPlanMatch
    }
    return {finalIbr,newLifePlanDetails}
}

export const saveDecideDetailQuery = (st, status, proposalSave = false) => {
    const isMMRCase = st.overall.source === 'mmr'
    const isUhcRenewal = isUhcRenewals()
    const decideDataCopy = {
        ...st.decide.renewalData,
        keepMedical: st.decide.renewalData.keepMedical ? st.decide.renewalData.keepMedical : false,
        keepDental: st.decide.renewalData.keepDental ? st.decide.renewalData.keepDental : false,
        keepVision: st.decide.renewalData.keepVision ? st.decide.renewalData.keepVision : false,
        keepLife: st.decide.renewalData.keepLife ? st.decide.renewalData.keepLife : false,
        keepDepLife: st.decide.renewalData.keepDepLife ? st.decide.renewalData.keepDepLife : false,
        keepLtd: st.decide.renewalData.keepLtd ? st.decide.renewalData.keepLtd : false,
        keepStd: st.decide.renewalData.keepStd ? st.decide.renewalData.keepStd : false,
        keepSuppLife: st.decide.renewalData.keepSuppLife ? st.decide.renewalData.keepSuppLife :false,
        callingPage: 'decide',
        renewAsIs: false,
    };

    if (isUhcRenewal) {
        let newFinalIbr = JSON.parse(JSON.stringify(st.decide.renewalData.Ibr))
        if (isMMRCase && !proposalSave) {           
            let {finalIbr,newLifePlanDetails} = addRenewingPlansInIbr(st, isMMRCase)
            decideDataCopy.lifePlanDetails = newLifePlanDetails
            newFinalIbr = finalIbr
        }
        decideDataCopy.Ibr = JSON.parse(JSON.stringify(newFinalIbr));
    }

    const caseDataCopy = {
        ...st.decide.renewalCaseData,
        caseProgressUI: (status && status === 'Pending Acceptance') ? status : st.decide.renewalCaseData.caseProgressUI,
    };
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    let enrolledPlans = [];
    let enrolledDentalPlans = [];
    let enrolledVisionPlans = [];
    let enrolledLifePlans = [];
    let enrolledDependentLifePlans = [];
    let enrolledLTDPlans = [];
    let enrolledSTDPlans = [];
    let enrolledSuppLifePlans = [];

    if (isUhcRenewal) {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
    } else {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) {
            enrolledPlans = st.rules.enrollment.enrolledPlans.standalone;
        }
    }
    let IBRMedexist = false;
    let isMedAdd = false;
    if (isUhcRenewal) {
        if (st.decide.renewalData && st.decide.renewalData.Ibr && st.decide.renewalData.Ibr.quote &&
            st.decide.renewalData.Ibr.quote.planOfferingData && st.decide.renewalData.Ibr.quote.planOfferingData[0] &&
            st.decide.renewalData.Ibr.quote.planOfferingData[0].newPlanPkg) {
            IBRMedexist = st.decide.renewalData.Ibr.quote.planOfferingData[0].newPlanPkg.plans.plan.find(x => (x.productLineType === "MEDICAL")) ? true : false;
        }
        if (enrolledPlans && enrolledPlans.length > 0 && IBRMedexist === false) {
            isMedAdd = true;
        }
    }

    if (st.rules.enrollment.enrolledDentalPlans && st.rules.enrollment.enrolledDentalPlans.length > 0) { enrolledDentalPlans = st.rules.enrollment.enrolledDentalPlans; }
    if (st.rules.enrollment.enrolledVisionPlans && st.rules.enrollment.enrolledVisionPlans.length > 0) { enrolledVisionPlans = st.rules.enrollment.enrolledVisionPlans; }
    if (st.rules.enrollment.enrolledLifePlans && st.rules.enrollment.enrolledLifePlans.length > 0) { enrolledLifePlans = st.rules.enrollment.enrolledLifePlans; }
    if (st.rules.enrollment.enrolledDependentLifePlans && st.rules.enrollment.enrolledDependentLifePlans.length > 0) { enrolledDependentLifePlans = st.rules.enrollment.enrolledDependentLifePlans; }
    if (st.rules.enrollment.enrolledLTDPlans && st.rules.enrollment.enrolledLTDPlans.length > 0) { enrolledLTDPlans = st.rules.enrollment.enrolledLTDPlans; }
    if (st.rules.enrollment.enrolledSTDPlans && st.rules.enrollment.enrolledSTDPlans.length > 0) { enrolledSTDPlans = st.rules.enrollment.enrolledSTDPlans; }
    if (st.rules.enrollment.enrolledSuppLifePlans && st.rules.enrollment.enrolledSuppLifePlans.length > 0) { enrolledSuppLifePlans = st.rules.enrollment.enrolledSuppLifePlans; }

    const query = {
        isMedAdd,
        mdvContribution: st.decide.mdvContribution,
        salesRepData: st.decide.salesRepData,
        agentData: st.decide.agentData,
        memberGroupId: st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: caseDataCopy,
        renewalData: decideDataCopy,
        shoppingPlans: st.decide.shoppingPlans,
        medical: st.decide.medical ? st.decide.medical : [],
        dental: st.decide.dental ? st.decide.dental : [],
        vision: st.decide.vision ? st.decide.vision : [],
        life: st.decide.life ? st.decide.life : [],
        dependentLife: st.decide.dependentLife ? st.decide.dependentLife : [],
        ltd: st.decide.ltd ? st.decide.ltd : [],
        std: st.decide.std ? st.decide.std : [],
        suppLife:st.decide.suppLife ? st.decide.suppLife:[],
        alternate: st.decide.alternate ? st.decide.alternate : [],
        caseSubmitted: (st && st.decide && st.decide.caseSubmitted) ? st.decide.caseSubmitted : false,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            decide: true,
        },
        enrolledPlans,
        enrolledDentalPlans,
        enrolledLifePlans,
        enrolledVisionPlans,
        enrolledDependentLifePlans,
        enrolledLTDPlans,
        enrolledSTDPlans,
        enrolledSuppLifePlans,
        customPlanNames: st.decide.customPlanNames ? st.decide.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.decide.enrolledPlansMed ? st.decide.enrolledPlansMed : {},
        employeesEnrollStatus: st.decide.employeesEnrollStatus ? st.decide.employeesEnrollStatus : 'In Progress',
        isMMRCase,
        productTypeList: st.decide.productTypeList,
    };

    if (getMarketType() === MARKET_TYPES.UHC) {
        query.hwTriggerError = st.decide.hwTriggerError
        //query.medical = st.review.medical;
        query.members = st.decide.members;
        if (!proposalSave && !query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deleteProductsFromQuery(query);
            query.productsDeleted = true;
            // deletePlanSetForProducts(query);
        }
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    query.priorYearDataSelected = priorYearDataSelected;


    return query;
};
export const saveDecideDetailQueryFromFinalizeDecide = (st, status) => {
    const newPlanRates = st.decide.renewalData.planRates.map((item) => {

        if (st.decide.enrolledPlansMed && st.decide.enrolledPlansMed[item.planCode]) {
            const updatedItem = {
                ...item,
                isEnrolled: st.decide.enrolledPlansMed[item.planCode].isEnrolled
            };
            return updatedItem
        }
        return item
    });

    const newShopPlans = st.decide.shoppingPlans.map((item) => {
        if (st.decide.enrolledPlansMed && st.decide.enrolledPlansMed[item.code] && !item.renewalPlan) {
            const updatedItem = {
                ...item,
                isEnrolled: st.decide.enrolledPlansMed[item.code].isEnrolled
            };
            return updatedItem
        }
        return item
    });
    const decideDataCopy = {
        ...st.decide.renewalData,
        keepMedical: st.decide.renewalData.keepMedical ? st.decide.renewalData.keepMedical : false,
        keepDental: st.decide.renewalData.keepDental ? st.decide.renewalData.keepDental : false,
        keepVision: st.decide.renewalData.keepVision ? st.decide.renewalData.keepVision : false,
        keepLife: st.decide.renewalData.keepLife ? st.decide.renewalData.keepLife : false,
        keepDepLife: st.decide.renewalData.keepDepLife ? st.decide.renewalData.keepDepLife : false,
        callingPage: 'decide',
        renewAsIs: false,
        planRates: newPlanRates
    };
    const caseDataCopy = {
        ...st.decide.renewalCaseData,
        caseProgressUI: (status && status === 'Pending Acceptance') ? status : st.decide.renewalCaseData.caseProgressUI,
    };

    let enrolledPlans = [];
    let enrolledDentalPlans = [];
    let enrolledVisionPlans = [];
    let enrolledLifePlans = [];
    let enrolledDependentLifePlans = [];

    if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) { enrolledPlans = st.rules.enrollment.enrolledPlans.standalone; }
    if (st.rules.enrollment.enrolledDentalPlans && st.rules.enrollment.enrolledDentalPlans.length > 0) { enrolledDentalPlans = st.rules.enrollment.enrolledDentalPlans; }
    if (st.rules.enrollment.enrolledVisionPlans && st.rules.enrollment.enrolledVisionPlans.length > 0) { enrolledVisionPlans = st.rules.enrollment.enrolledVisionPlans; }
    if (st.rules.enrollment.enrolledLifePlans && st.rules.enrollment.enrolledLifePlans.length > 0) { enrolledLifePlans = st.rules.enrollment.enrolledLifePlans; }
    if (st.rules.enrollment.enrolledDependentLifePlans && st.rules.enrollment.enrolledDependentLifePlans.length > 0) { enrolledDependentLifePlans = st.rules.enrollment.enrolledDependentLifePlans; }
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: st.decide.mdvContribution,
        salesRepData: st.decide.salesRepData,
        agentData: st.decide.agentData,
        memberGroupId: st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : '',
        renewalCaseData: caseDataCopy,
        renewalData: decideDataCopy,
        shoppingPlans: newShopPlans,
        dental: st.decide.dental ? deepCopy(st.decide.dental) : [],
        vision: st.decide.vision ? deepCopy(st.decide.vision) : [],
        life: st.decide.life ? deepCopy(st.decide.life) : [],
        dependentLife: st.decide.dependentLife ? deepCopy(st.decide.dependentLife) : [],
        alternate: st.decide.alternate ? st.decide.alternate : [],
        caseSubmitted: true,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            decide: true,
        },
        enrolledPlans,
        enrolledDentalPlans,
        enrolledLifePlans,
        enrolledVisionPlans,
        enrolledDependentLifePlans,
        customPlanNames: st.decide.customPlanNames ? st.decide.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.decide.enrolledPlansMed ? st.decide.enrolledPlansMed : {},
        employeesEnrollStatus: st.decide.employeesEnrollStatus ? st.decide.employeesEnrollStatus : 'In Progress',
    };
    query.priorYearDataSelected = priorYearDataSelected;

    if (isUhcRenewals()) {
        deleteExtrasFromQuery(query);
        deletePlanSetForProducts(query);
    }

    return query;
};
export const saveReviewDetailQueryRenewalStepper = (st) => {
    const renewalDataCopy = {
        ...st.review.renewalData,
        keepMedical: st.premium.keepMedical ? st.premium.keepMedical : false,
        keepDental: st.premium.keepDental ? st.premium.keepDental : false,
        keepVision: st.premium.keepVision ? st.premium.keepVision : false,
        keepLife: st.premium.keepLife ? st.premium.keepLife : false,
        keepDepLife: st.premium.keepDepLife ? st.premium.keepDepLife : false,
        keepLTD : st.premium.keepLTD ? st.premium.keepLTD : false,
        keepSTD : st.premium.keepSTD ? st.premium.keepSTD : false,
        callingPage: 'review',
    };
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: st.review.mdvContribution,
        salesRepData: st.review.salesRepData,
        agentData: st.review.agentData,
        memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.review.renewalCaseData,
        renewalData: renewalDataCopy,
        dental: st.review.dental ? deepCopy(st.review.dental) : [],
        vision: st.review.vision ? deepCopy(st.review.vision) : [],
        life: st.review.life ? deepCopy(st.review.life) : [],
        ltd: st.review.ltd ? deepCopy(st.review.ltd) : [],
        std: st.review.std ? deepCopy(st.review.std) : [],
        dependentLife: st.review.dependentLife ? deepCopy(st.review.dependentLife) : [],
        shoppingPlans: st.review.shoppingPlans ? st.review.shoppingPlans : [],
        alternate: st.review.alternate ? st.review.alternate : [],
        caseSubmitted: (st && st.review && st.review.caseSubmitted) ? st.review.caseSubmitted : false,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            reviewDetail: true,
        },
        customPlanNames: st.review.customPlanNames ? st.review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.review.enrolledPlansMed ? st.review.enrolledPlansMed : {},
        employeesEnrollStatus: st.review.employeesEnrollStatus ? st.review.employeesEnrollStatus : 'In Progress',
        priorYearDataSelected: priorYearDataSelected
    };

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    if (getMarketType() === MARKET_TYPES.UHC) {
        if(query.priorYearDataSelected === 'Y') {
            query.effectiveDate = st.review.renewalCaseData.effectiveDate
        }
        query.renewalClassingOpted = st.review.renewalClassingOpted ? st.review.renewalClassingOpted : false,
        query.medical = deepCopy(st.review.medical);
        query.shoppingPlans = st.review.medical && st.review.medical.selectedPlans ? st.review.medical.selectedPlans : query.shoppingPlans;
        query.shoppingDentalPlans = st.review.dental ? st.review.dental.selectedPlans ? st.review.dental.selectedPlans : [] : [];
        query.shoppingVisionPlans = st.review.vision ? st.review.vision.selectedPlans ? st.review.vision.selectedPlans : [] : [];
        query.shoppingLifePlans = st.review.life ? st.review.life.selectedPlans ? st.review.life.selectedPlans : [] : [];
        query.shoppingDepLifePlans = st.review.dependentLife ? st.review.dependentLife.selectedPlans ? st.review.dependentLife.selectedPlans : [] : [];
        query.shoppingLTDPlans = st.review.ltd ? st.review.ltd.selectedPlans ? st.review.ltd.selectedPlans : [] : [];
        query.shoppingSTDPlans = st.review.std ? st.review.std.selectedPlans ? st.review.std.selectedPlans : [] : [];
        query.shoppingSupLifePlans = st.review.suppLife ? st.review.suppLife.selectedPlans ? st.review.suppLife.selectedPlans : [] : [];
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query);
        }
        query.enrolledPlans = st.review.enrolledPlans || []
    }
    return query;
};

const checkDataInRedux = (source) => {
    return (
        source &&
        source.renewalData &&
        Object.keys(source.renewalData).length > 0 &&
        source.renewalCaseData &&
        Object.keys(source.renewalCaseData).length > 0
    );
};

export const saveDecideDetailQueryRenewalStepper = (st, status, page) => {

    let dataSource = checkDataInRedux(st.decide)
        ? st.decide
        : checkDataInRedux(st.renewalEnrollment)
        ? st.renewalEnrollment
        : st.review;
        
    const decideDataCopy = {
        ...dataSource.renewalData,
        keepMedical: dataSource.renewalData.keepMedical ? dataSource.renewalData.keepMedical : false,
        keepDental: dataSource.renewalData.keepDental ? dataSource.renewalData.keepDental : false,
        keepVision: dataSource.renewalData.keepVision ? dataSource.renewalData.keepVision : false,
        keepLife: dataSource.renewalData.keepLife ? dataSource.renewalData.keepLife : false,
        keepDepLife: dataSource.renewalData.keepDepLife ? dataSource.renewalData.keepDepLife : false,
        callingPage: page === 'renewal-dashboard' ? dataSource.renewalData.callingPage : 'decide',
    };
    const caseDataCopy = {
        ...dataSource.renewalCaseData,
        caseProgressUI: (status && status === 'Pending Acceptance') ? status : dataSource.renewalCaseData.caseProgressUI,
    };
    let enrolledPlans = [];
    let enrolledDentalPlans = [];
    let enrolledVisionPlans = [];
    let enrolledLifePlans = [];
    let enrolledDependentLifePlans = [];
    let enrolledSTDPlans = [];
    let enrolledLTDPlans = [];
    let enrolledSuppLifePlans = [];

    if (isUhcRenewals()) {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
    } else {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) {
            enrolledPlans = st.rules.enrollment.enrolledPlans.standalone;
        }
    }

    if (st.rules.enrollment.enrolledDentalPlans && st.rules.enrollment.enrolledDentalPlans.length > 0) { enrolledDentalPlans = st.rules.enrollment.enrolledDentalPlans; }
    if (st.rules.enrollment.enrolledVisionPlans && st.rules.enrollment.enrolledVisionPlans.length > 0) { enrolledVisionPlans = st.rules.enrollment.enrolledVisionPlans; }
    if (st.rules.enrollment.enrolledLifePlans && st.rules.enrollment.enrolledLifePlans.length > 0) { enrolledLifePlans = st.rules.enrollment.enrolledLifePlans; }
    if (st.rules.enrollment.enrolledDependentLifePlans && st.rules.enrollment.enrolledDependentLifePlans.length > 0) { enrolledDependentLifePlans = st.rules.enrollment.enrolledDependentLifePlans; }
    if (st.rules.enrollment.enrolledSTDPlans && st.rules.enrollment.enrolledSTDPlans.length > 0) { enrolledSTDPlans = st.rules.enrollment.enrolledSTDPlans; }
    if (st.rules.enrollment.enrolledLTDPlans && st.rules.enrollment.enrolledLTDPlans.length > 0) { enrolledLTDPlans = st.rules.enrollment.enrolledLTDPlans; }
    if (st.rules.enrollment.enrolledSuppLifePlans && st.rules.enrollment.enrolledSuppLifePlans.length > 0) { enrolledSuppLifePlans = st.rules.enrollment.enrolledSuppLifePlans; }
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: dataSource.mdvContribution,
        salesRepData: dataSource.salesRepData,
        agentData: dataSource.agentData,
        memberGroupId: dataSource && dataSource.renewalCaseData && dataSource.renewalCaseData.memberGroupId ? dataSource.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: caseDataCopy,
        renewalData: decideDataCopy,
        shoppingPlans: dataSource.shoppingPlans,
        dental: dataSource.dental ? dataSource.dental : [],
        vision: dataSource.vision ? dataSource.vision : [],
        life: dataSource.life ? dataSource.life : [],
        dependentLife: dataSource.dependentLife ? dataSource.dependentLife : [],
        alternate: dataSource.alternate ? dataSource.alternate : [],
        caseSubmitted: (st && dataSource && dataSource.caseSubmitted) ? dataSource.caseSubmitted : false,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            decide: true,
        },
        enrolledPlans,
        enrolledDentalPlans,
        enrolledLifePlans,
        enrolledVisionPlans,
        enrolledDependentLifePlans,
        customPlanNames: dataSource.customPlanNames ? dataSource.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: dataSource.enrolledPlansMed ? dataSource.enrolledPlansMed : {},
        employeesEnrollStatus: dataSource.employeesEnrollStatus ? dataSource.employeesEnrollStatus : 'In Progress',
        priorYearDataSelected: priorYearDataSelected
    };
    if(isUhcRenewals() || isOxfordRenewals()){
        query.enrolledSTDPlans = enrolledSTDPlans;
        query.enrolledLTDPlans = enrolledLTDPlans;
        query.enrolledSuppLifePlans = enrolledSuppLifePlans;
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    if (getMarketType() === MARKET_TYPES.UHC) {
        if(query.priorYearDataSelected === 'Y') {
            query.effectiveDate = caseDataCopy.effectiveDate
        }
        query.medical = dataSource.medical;
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deleteProductsFromQuery(query);
            query.productsDeleted = true;
            // deletePlanSetForProducts(query);
        }
        if (dataSource && dataSource.shoppingDentalPlans && dataSource.shoppingDentalPlans.length > 0)
            query.shoppingDentalPlans = dataSource.shoppingDentalPlans;

        if (dataSource && dataSource.shoppingVisionPlans && dataSource.shoppingVisionPlans.length > 0)
            query.shoppingVisionPlans = dataSource.shoppingVisionPlans;

        if (dataSource && dataSource.shoppingLifePlans && dataSource.shoppingLifePlans.length > 0)
            query.shoppingLifePlans = dataSource.shoppingLifePlans;

        if (dataSource && dataSource.shoppingDepLifePlans && dataSource.shoppingDepLifePlans.length > 0)
            query.shoppingDepLifePlans = dataSource.shoppingDepLifePlans;
    }
    return query;
};
export const saveCaseSubmittedQuery = (st) => {
    const dataCopy = {
        ...st.renewalEnrollment.renewalData,
        keepMedical: st.renewalEnrollment.renewalData.keepMedical ? st.renewalEnrollment.renewalData.keepMedical : false,
        keepDental: st.renewalEnrollment.renewalData.keepDental ? st.renewalEnrollment.renewalData.keepDental : false,
        keepVision: st.renewalEnrollment.renewalData.keepVision ? st.renewalEnrollment.renewalData.keepVision : false,
        keepLife: st.renewalEnrollment.renewalData.keepLife ? st.renewalEnrollment.renewalData.keepLife : false,
        keepDepLife: st.renewalEnrollment.renewalData.keepDepLife ? st.renewalEnrollment.renewalData.keepDepLife : false,
        callingPage: 'enrollment',
        renewAsIs: st.renewalEnrollment.renewalData.renewAsIs ? st.renewalEnrollment.renewalData.renewAsIs : false,
    };
    const query = {
        activeShoppingTab: st.renewalEnrollment.activeShoppingTab,
        agentData: st.renewalEnrollment.agentData,
        alternate: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.alternate : [],
        caseSubmitted: true,
        contractOptions: st.renewalEnrollment.contractOptions ? st.renewalEnrollment.contractOptions : {},
        customPlanNames: st.renewalEnrollment.customPlanNames ? st.renewalEnrollment.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalEnrollment.enrolledPlansMed ? st.renewalEnrollment.enrolledPlansMed : {},
        dental: st.renewalEnrollment.dental ? st.renewalEnrollment.dental : {},
        dependentLife: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.dependentLife : {},
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus ? st.renewalEnrollment.employeesEnrollStatus : 'In Progress',
        enrolledPlans: st.rules.enrollment.enrolledPlans.standalone || [],
        enrolledDentalPlans: st.rules.enrollment.enrolledDentalPlans,
        enrolledLifePlans: st.rules.enrollment.enrolledLifePlans,
        enrolledVisionPlans: st.rules.enrollment.enrolledVisionPlans,
        enrolledDependentLifePlans: st.rules.enrollment.enrolledDependentLifePlans,
        enrollmentRes: st.renewalEnrollment.enrollmentRes,
        errorMessage: st.renewalEnrollment.errorMessage,
        groupDetails: st.renewalEnrollment.groupDetails ? st.renewalEnrollment.groupDetails : {},
        individualMemberList: st.renewalEnrollment.individualMemberList ? st.renewalEnrollment.individualMemberList : [],
        isEnrolled: st.renewalEnrollment.isEnrolled,
        life: st.renewalEnrollment.life ? st.renewalEnrollment.life : [],
        mdvContribution: st.renewalEnrollment.mdvContribution ? st.renewalEnrollment.mdvContribution : {},
        memberGroupId: st.renewalEnrollment.memberGroupId,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        newProducts: st.renewalEnrollment.newProducts ? st.renewalEnrollment.newProducts : [],
        premiumRates: st.renewalEnrollment.premiumRates,
        premiumToggle: st.renewalEnrollment.premiumToggle,
        renewalCaseData: st.renewalEnrollment.renewalCaseData ? st.renewalEnrollment.renewalCaseData : {},
        renewalData: dataCopy,
        requiredDocuments: st.renewalEnrollment.requiredDocuments ? st.renewalEnrollment.requiredDocuments : {},
        salesRepData: st.renewalEnrollment.salesRepData ? st.renewalEnrollment.salesRepData : [],
        selectedProducts: st.renewalEnrollment.selectedProducts ? st.renewalEnrollment.selectedProducts : [],
        shoppingDentalPlans: st.renewalEnrollment.shoppingDentalPlans ? st.renewalEnrollment.shoppingDentalPlans : [],
        shoppingLifePlans: st.renewalEnrollment.shoppingLifePlans ? st.renewalEnrollment.shoppingLifePlans : [],
        shoppingPlans: st.renewalEnrollment.shoppingPlans ? st.renewalEnrollment.shoppingPlans : [],
        shoppingVisionPlans: st.renewalEnrollment.shoppingVisionPlans ? st.renewalEnrollment.shoppingVisionPlans : [],
        stepperAccess: {
            ...st.renewals.stepperAccess,
            enrollment: true,
        },
        vision: st.renewalEnrollment.vision ? st.renewalEnrollment.vision : [],
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus ? st.renewalEnrollment.employeesEnrollStatus : 'In Progress',
    };

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const setShoppingPlanSelection = (st) => {
    const dataCopy = {
        ...st.renewalEnrollment.renewalData,
        callingPage: 'enrollment',
    };

    let enrolledPlans = [];
    let shoppingPlans = [];

    if (isUhcRenewals()) {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlansUhc(st.renewalEnrollment.shoppingPlans, enrolledPlans);
    } else {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) {
            enrolledPlans = st.rules.enrollment.enrolledPlans.standalone;
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlans(st.renewalEnrollment.shoppingPlans, enrolledPlans);
        shoppingPlans=updateExchangeIndicator(shoppingPlans)
    }
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: st.renewalEnrollment.mdvContribution,
        salesRepData: st.renewalEnrollment.salesRepData,
        agentData: st.renewalEnrollment.agentData,
        memberGroupId: st.renewalEnrollment && st.renewalEnrollment.renewalCaseData && st.renewalEnrollment.renewalCaseData.memberGroupId ? st.renewalEnrollment.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalEnrollment.renewalCaseData,
        renewalData: dataCopy,
        shoppingPlans,
        dental: st.renewalEnrollment.dental ? st.renewalEnrollment.dental : [],
        vision: st.renewalEnrollment.vision ? st.renewalEnrollment.vision : [],
        life: st.renewalEnrollment.life ? st.renewalEnrollment.life : [],
        dependentLife: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.dependentLife : [],
        alternate: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.alternate : [],
        caseSubmitted: (st && st.renewalEnrollment && st.renewalEnrollment.caseSubmitted) ? st.renewalEnrollment.caseSubmitted : false,
        enrolledPlans,
        enrolledDentalPlans: st.rules.enrollment.enrolledDentalPlans,
        enrolledLifePlans: st.rules.enrollment.enrolledLifePlans,
        enrolledVisionPlans: st.rules.enrollment.enrolledVisionPlans,
        enrolledDependentLifePlans: st.rules.enrollment.enrolledDependentLifePlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            enrollment: true,
        },
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus,
        customPlanNames: st.renewalEnrollment.customPlanNames ? st.renewalEnrollment.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalEnrollment.enrolledPlansMed ? st.renewalEnrollment.enrolledPlansMed : {},
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus ? st.renewalEnrollment.employeesEnrollStatus : 'In Progress',
        priorYearDataSelected: priorYearDataSelected
    };

    if (isUhcRenewals()) {
        if(query.priorYearDataSelected === 'Y') {
            query.effectiveDate = st.renewalEnrollment.renewalCaseData.effectiveDate
        }
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deleteProductsFromQuery(query);
            query.productsDeleted = true;
            // deletePlanSetForProducts(query);
        }
        query.enrolledLTDPlans = st.renewalEnrollment.enrolledLTDPlans || [];
        query.enrolledSTDPlans = st.renewalEnrollment.enrolledSTDPlans || [];
        query.enrolledSuppLifePlans = st.renewalEnrollment.enrolledSuppLifePlans || [];
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    return query;
};

export const setShoppingPlanSelectionExceptDecide = (st, returnToDB, finalizeFromDecide = false) => {
    const dataCopy = {
        ...st.renewalEnrollment.renewalData,
        callingPage: 'enrollment',
    };
    let enrolledPlans = [];
    let shoppingPlans = [];

    if (isUhcRenewals()) {
        if (st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlansUhc(st.renewalEnrollment.shoppingPlans, enrolledPlans);
    } else {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) {
            enrolledPlans = st.rules.enrollment.enrolledPlans.standalone;
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlans(st.renewalEnrollment.shoppingPlans, enrolledPlans);
    }

    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: st.renewalEnrollment.mdvContribution,
        salesRepData: st.renewalEnrollment.salesRepData,
        agentData: st.renewalEnrollment.agentData,
        memberGroupId: st.renewalEnrollment && st.renewalEnrollment.renewalCaseData && st.renewalEnrollment.renewalCaseData.memberGroupId ? st.renewalEnrollment.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalEnrollment.renewalCaseData,
        renewalData: dataCopy,
        caseSubmitted: returnToDB && returnToDB === true ? true : (st && st.renewalEnrollment && st.renewalEnrollment.caseSubmitted) ? st.renewalEnrollment.caseSubmitted : false,
        shoppingPlans,
        dental: st.renewalEnrollment.dental ? st.renewalEnrollment.dental : [],
        vision: st.renewalEnrollment.vision ? st.renewalEnrollment.vision : [],
        life: st.renewalEnrollment.life ? st.renewalEnrollment.life : [],
        dependentLife: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.dependentLife : [],
        enrolledPlans,
        enrolledDentalPlans: st.rules.enrollment.enrolledDentalPlans,
        enrolledLifePlans: st.rules.enrollment.enrolledLifePlans,
        enrolledVisionPlans: st.rules.enrollment.enrolledVisionPlans,
        enrolledDependentLifePlans: st.rules.enrollment.enrolledDependentLifePlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            enrollment: finalizeFromDecide && finalizeFromDecide === true ? st.renewals.stepperAccess.enrollment : true,
        },
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus,
        customPlanNames: st.renewalEnrollment.customPlanNames ? st.renewalEnrollment.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalEnrollment.enrolledPlansMed ? st.renewalEnrollment.enrolledPlansMed : {},
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus ? st.renewalEnrollment.employeesEnrollStatus : 'In Progress',
        priorYearDataSelected: priorYearDataSelected
    };

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        if(query.priorYearDataSelected === 'Y') {
            query.effectiveDate = st.renewalEnrollment.effectiveDate
        }
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deleteProductsFromQuery(query);
            query.productsDeleted = true;
            // deletePlanSetForProducts(query);
        }
        query.enrolledLTDPlans = st.renewalEnrollment.enrolledLTDPlans || [];
        query.enrolledSTDPlans = st.renewalEnrollment.enrolledSTDPlans || [];
        query.enrolledSuppLifePlans = st.renewalEnrollment.enrolledSuppLifePlans || [];
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    return query;
};

function getTermedPlatform(enrolledPlans, renewals) {
    let payload = "NA";

    if (renewals?.renewalCaseData?.situsState === "CA") {
        let isPrimeOrCirrusSelected = false,
            isNiceSelected = false,
            custType = renewals.renewalCaseData.custType,
            enrolledPlanPlatform = "PRIME";

        if(enrolledPlans && enrolledPlans.length > 0) {
            for (let i = 0; i < enrolledPlans.length; i++) {
                if (
                    (enrolledPlans[i].planType &&
                        enrolledPlans[i].planType.toLowerCase() === "medical" &&
                        enrolledPlans[i].platform &&
                        ["prime","cirrus"].includes(enrolledPlans[i].platform.toLowerCase())) ||
                    (enrolledPlans[i].planType &&
                        enrolledPlans[i].planType.toLowerCase() !== "medical")
                ) {
                    isPrimeOrCirrusSelected = true;
                    enrolledPlanPlatform = enrolledPlans[i].platform;
                } else if (
                    enrolledPlans[i].planType &&
                    enrolledPlans[i].planType.toLowerCase() === "medical" &&
                    enrolledPlans[i].platform &&
                    enrolledPlans[i].platform.toLowerCase() === "nice"
                ) {
                    isNiceSelected = true;
                }
            }

            if (isPrimeOrCirrusSelected && isNiceSelected) payload = "NA";
            else {
                if (
                    isPrimeOrCirrusSelected &&
                    (custType === "NICE" || custType === "DUAL")
                )
                    payload = "NICE";
                else if (isNiceSelected && custType !== "NICE") payload = enrolledPlanPlatform;
                else payload = "NA";
            }
        } else {
            payload = "NICE"
        }
    }

    return payload;
}

export const setCloseEnrollmentSaving = (st, returnToDB) => {
    const dataCopy = {
        ...st.renewalEnrollment.renewalData,
        callingPage: 'enrollment',
    };

    let enrolledPlans = [];
    let shoppingPlans = [];

    if (isUhcRenewals()) {
        if (st.rules.enrollment.enrolledPlans) {
            Object.keys(st.rules.enrollment.enrolledPlans).map((item) => {
                enrolledPlans.push(st.rules.enrollment.enrolledPlans[item]);
            })
            enrolledPlans = enrolledPlans.flat();
        }
        let count = {};
        if (st.companyProfile.employees && st.companyProfile.employees.length > 0) {
            let employees = st.companyProfile.employees[0];

            employees.forEach((member) => {
                if (member.productSelection && member.productSelection[0] && member.productSelection[0].medical && member.productSelection[0].medical !== 'waive') {
                    count[member.productSelection[0].medical] = (count[member.productSelection[0].medical] + 1) || 1
                }
            })
        }
        if (enrolledPlans && enrolledPlans.length > 0) {
            enrolledPlans.map((plan) => {
                if (plan.code in count) {
                    plan.employeeCount = count[plan.code];
                } else {
                    plan.employeeCount = 0;
                }
            })
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlansUhc(st.renewalEnrollment.shoppingPlans, enrolledPlans);
    } else {
        if (st.rules.enrollment.enrolledPlans && st.rules.enrollment.enrolledPlans.standalone && st.rules.enrollment.enrolledPlans.standalone.length > 0) {
            enrolledPlans = st.rules.enrollment.enrolledPlans.standalone;
        }
        shoppingPlans = updateShoppingPlansAccToEnrolledPlans(st.renewalEnrollment.shoppingPlans, enrolledPlans);
    }

    const query = {
        mdvContribution: st.renewalEnrollment.mdvContribution,
        salesRepData: st.renewalEnrollment.salesRepData,
        agentData: st.renewalEnrollment.agentData,
        memberGroupId: st.renewalEnrollment && st.renewalEnrollment.renewalCaseData && st.renewalEnrollment.renewalCaseData.memberGroupId ? st.renewalEnrollment.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalEnrollment.renewalCaseData,
        renewalData: dataCopy,
        caseSubmitted: returnToDB && returnToDB === true ? true : (st && st.renewalEnrollment && st.renewalEnrollment.caseSubmitted) ? st.renewalEnrollment.caseSubmitted : false,
        shoppingPlans,
        dental: st.renewalEnrollment.dental ? st.renewalEnrollment.dental : [],
        vision: st.renewalEnrollment.vision ? st.renewalEnrollment.vision : [],
        life: st.renewalEnrollment.life ? st.renewalEnrollment.life : [],
        dependentLife: st.renewalEnrollment.dependentLife ? st.renewalEnrollment.dependentLife : [],
        enrolledPlans,
        enrolledDentalPlans: st.rules.enrollment.enrolledDentalPlans,
        enrolledLifePlans: st.rules.enrollment.enrolledLifePlans,
        enrolledVisionPlans: st.rules.enrollment.enrolledVisionPlans,
        enrolledDependentLifePlans: st.rules.enrollment.enrolledDependentLifePlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            enrollment: true,
        },
        employeesEnrollStatus: st.renewalEnrollment.employeesEnrollStatus ? st.renewalEnrollment.employeesEnrollStatus : 'In Progress',
        customPlanNames: st.renewalEnrollment.customPlanNames ? st.renewalEnrollment.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalEnrollment.enrolledPlansMed ? st.renewalEnrollment.enrolledPlansMed : {},
        employeesEnrollStatus: 'Completed',
        priorYearDataSelected: st.renewals.priorYearDataSelected
    };
    
    if (isUhcRenewals()) {
        if(query.priorYearDataSelected === 'Y') {
            query.effectiveDate = query.renewalCaseData.effectiveDate
        }
        query.termedPlatform = getTermedPlatform([...query.enrolledPlans, ...query.enrolledDentalPlans, ...query.enrolledVisionPlans, ...query.enrolledLifePlans], st.renewals)
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deleteProductsFromQuery(query);
            query.productsDeleted = true;
            // deletePlanSetForProducts(query);
        }
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const saveShoppingHelperDetailQuery = (st) => {
    let shoppingPlans = [];
    let shoppingDentalPlans = [];
    let shoppingVisionPlans = [];
    let shoppingLifePlans = [];
    let shoppingDepLifePlans = [];
    let shoppingLTDPlans = [];
    let shoppingSTDPlans = [];

    let isUhcRenewal = isUhcRenewals();

    if (st.renewalShopping.selectedPlans && st.renewalShopping.selectedPlans.length > 0) {
        shoppingPlans = st.renewalShopping.selectedPlans;
        if(!isUhcRenewal)
            shoppingPlans = updateExchangeIndicator(shoppingPlans)
    }

    if (st.dental.selectedPlans && st.dental.selectedPlans.length > 0) {
        shoppingDentalPlans = st.dental.selectedPlans;
    }

    if (st.vision.selectedPlans && st.vision.selectedPlans.length > 0) {
        shoppingVisionPlans = st.vision.selectedPlans;
    }

    if (st.life.selectedPlans && st.life.selectedPlans.length > 0) {
        if (isUhcRenewal && st.renewalShoppingHelper.life.selectedPlans && st.renewalShoppingHelper.life.selectedPlans.length > 0) {
            shoppingLifePlans = st.renewalShoppingHelper.life.selectedPlans
        } else {
            shoppingLifePlans = st.life.selectedPlans;
        }
    }

    if (isUhcRenewal && st.renewalShoppingHelper.dependentLife.selectedPlans && st.renewalShoppingHelper.dependentLife.selectedPlans.length > 0) {
        shoppingDepLifePlans = st.renewalShoppingHelper.dependentLife.selectedPlans;
    }

    if (st.ltd.selectedPlans && st.ltd.selectedPlans.length > 0) {
        shoppingLTDPlans = st.ltd.selectedPlans;
    }

    if (st.std.selectedPlans && st.std.selectedPlans.length > 0) {
        shoppingSTDPlans = st.std.selectedPlans;
    }

    const renewalShoppingHelperCopy = {
        ...st.renewalShoppingHelper.renewalData,
        planRates: st.renewalShoppingHelper.renewalData.planRates,
        beenToRenewalShopping: true,
        callingPage: 'ren-shopping',
        renewAsIs: false,
    };
    const query = {
        mdvContribution: st.renewalShoppingHelper.mdvContribution,
        salesRepData: st.renewalShoppingHelper.salesRepData,
        agentData: st.renewalShoppingHelper.agentData,
        memberGroupId: st.renewalShoppingHelper && st.renewalShoppingHelper.renewalCaseData && st.renewalShoppingHelper.renewalCaseData.memberGroupId ? st.renewalShoppingHelper.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalShoppingHelper.renewalCaseData,
        renewalData: renewalShoppingHelperCopy,
        dental: st.renewalShoppingHelper.dental ? deepCopy(st.renewalShoppingHelper.dental) : [],
        vision: st.renewalShoppingHelper.vision ? deepCopy(st.renewalShoppingHelper.vision) : [],
        life: st.renewalShoppingHelper.life ? deepCopy(st.renewalShoppingHelper.life) : [],
        ltd: st.renewalShoppingHelper.ltd ? deepCopy(st.renewalShoppingHelper.ltd) : [],
        std: st.renewalShoppingHelper.std ? deepCopy(st.renewalShoppingHelper.std) : [],
        dependentLife: st.renewalShoppingHelper.dependentLife ? deepCopy(st.renewalShoppingHelper.dependentLife) : [],
        alternate: st.renewalShoppingHelper.alternate ? st.renewalShoppingHelper.alternate : [],
        caseSubmitted: (st && st.renewalShoppingHelper && st.renewalShoppingHelper.caseSubmitted) ? st.renewalShoppingHelper.caseSubmitted : false,
        shoppingPlans,
        shoppingDentalPlans,
        shoppingLifePlans,
        shoppingVisionPlans,
        shoppingLTDPlans,
        shoppingSTDPlans,
        shoppingDepLifePlans: isUhcRenewal ? shoppingDepLifePlans : null,
        stepperAccess: st.renewals.stepperAccess,
        customPlanNames: st.renewalShoppingHelper.customPlanNames ? st.renewalShoppingHelper.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalShoppingHelper.enrolledPlansMed ? st.renewalShoppingHelper.enrolledPlansMed : {},
        employeesEnrollStatus: st.renewalShoppingHelper.employeesEnrollStatus ? st.renewalShoppingHelper.employeesEnrollStatus : 'In Progress',
    };

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;
    if (isUhcRenewal) {
        query.members = st.renewalShoppingHelper.members;
        query.medical = deepCopy(st.renewalShoppingHelper.medical);
        if (st.renewalShoppingHelper.medical.set) {
            query.medical = {
                set: st.renewalShoppingHelper.medical.set,
                selectedPlans: st.renewalShopping.selectedPlans
            }
        }
        query.isMMRCase = isMmr()
        query.eligibilityCountsEntered = st.renewalShopping.eligibilityCountsEntered || false;
        query.eligibilityCounts = st.renewalShopping.eligibilityCounts || {};
        query.enableMedical = st.renewalShopping.enableMedical || false;
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query, true);
        }
    }

    return query;
};
export const saveShoppingHelperDetailQueryRenewalStepper = (st) => {
    let shoppingPlans = [];
    let shoppingDentalPlans = [];
    let shoppingVisionPlans = [];
    let shoppingLifePlans = [];

    if (st.renewalShopping.selectedPlans && st.renewalShopping.selectedPlans.length > 0) {
        shoppingPlans = st.renewalShopping.selectedPlans;
        if (getMarketType() !== MARKET_TYPES.UHC) 
            shoppingPlans=updateExchangeIndicator(shoppingPlans)
    }

    if (st.dental.selectedPlans && st.dental.selectedPlans.length > 0) {
        shoppingDentalPlans = st.dental.selectedPlans;
    }

    if (st.vision.selectedPlans && st.vision.selectedPlans.length > 0) {
        shoppingVisionPlans = st.vision.selectedPlans;
    }

    if (st.life.selectedPlans && st.life.selectedPlans.length > 0) {
        shoppingLifePlans = st.life.selectedPlans;
    }

    const renewalShoppingHelperCopy = {
        ...st.renewalShoppingHelper.renewalData,
        planRates: st.renewalShoppingHelper.renewalData.planRates,
        beenToRenewalShopping: true,
        callingPage: 'ren-shopping',
    };
    const priorYearDataSelected = st && st.renewals && st.renewals.priorYearDataSelected ? st.renewals.priorYearDataSelected : 'N';
    const query = {
        mdvContribution: st.renewalShoppingHelper.mdvContribution,
        salesRepData: st.renewalShoppingHelper.salesRepData,
        agentData: st.renewalShoppingHelper.agentData,
        memberGroupId: st.renewalShoppingHelper && st.renewalShoppingHelper.renewalCaseData && st.renewalShoppingHelper.renewalCaseData.memberGroupId ? st.renewalShoppingHelper.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalShoppingHelper.renewalCaseData,
        renewalData: renewalShoppingHelperCopy,
        dental: st.renewalShoppingHelper.dental ? deepCopy(st.renewalShoppingHelper.dental) : [],
        vision: st.renewalShoppingHelper.vision ? deepCopy(st.renewalShoppingHelper.vision) : [],
        life: st.renewalShoppingHelper.life ? deepCopy(st.renewalShoppingHelper.life) : [],
        dependentLife: st.renewalShoppingHelper.dependentLife ? deepCopy(st.renewalShoppingHelper.dependentLife) : [],
        alternate: st.renewalShoppingHelper.alternate ? st.renewalShoppingHelper.alternate : [],
        caseSubmitted: (st && st.renewalShoppingHelper && st.renewalShoppingHelper.caseSubmitted) ? st.renewalShoppingHelper.caseSubmitted : false,
        shoppingPlans,
        shoppingDentalPlans,
        shoppingLifePlans,
        shoppingVisionPlans,
        stepperAccess: st.renewals.stepperAccess,
        customPlanNames: st.renewalShoppingHelper.customPlanNames ? st.renewalShoppingHelper.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalShoppingHelper.enrolledPlansMed ? st.renewalShoppingHelper.enrolledPlansMed : {},
        employeesEnrollStatus: st.renewalShoppingHelper.employeesEnrollStatus ? st.renewalShoppingHelper.employeesEnrollStatus : 'In Progress'
    };

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    if (getMarketType() === MARKET_TYPES.UHC) {
        query.medical = deepCopy(st.renewalShoppingHelper.medical);
        query.members = st.renewalShoppingHelper.members;
        query.isMMRCase = isMmr()
        query.eligibilityCountsEntered = st.renewalShopping.eligibilityCountsEntered || false;
        query.eligibilityCounts = st.renewalShopping.eligibilityCounts || {};
        query.enableMedical = st.renewalShopping.enableMedical || false;
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query, true);
        }
    }
    query.priorYearDataSelected = priorYearDataSelected;
    return query;
};

export const getDecideDetailQuery = (st) => {
    const query = {
        memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
    };
    return query;
};
export const getRenewalShoppingQuery = (st) => {
    const query = {
        memberGroupId: st.renewalShoppingHelper && st.renewalShoppingHelper.renewalCaseData && st.renewalShoppingHelper.renewalCaseData.memberGroupId ? st.renewalShoppingHelper.renewalCaseData.memberGroupId : null,
    }
    return query;
};

export const getDecideDetailAfterFinalizeQuery = (st) => {
    const query = {
        memberGroupId: st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : null,
    }
    return query;
};

export const getDecideDetailFromEnrollment = (st) => {
    const query = {
        memberGroupId: st.renewalEnrollment && st.renewalEnrollment.renewalCaseData && st.renewalEnrollment.renewalCaseData.memberGroupId ? st.renewalEnrollment.renewalCaseData.memberGroupId : null
    }
    return query;
};

export const getMemberGroupIdDataReview = (st) => {
    return st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null
}

export const getMemberGroupIdDataRenewalShoppingHelper = (st) => {
    return st.renewalShoppingHelper && st.renewalShoppingHelper.renewalCaseData && st.renewalShoppingHelper.renewalCaseData.memberGroupId ? st.renewalShoppingHelper.renewalCaseData.memberGroupId : null
}

export const saveReviewDetailQueryWithBeenToRenewalShopping = (st, activeIndex = 0) => {
    const renewalDataCopy = {
        ...st.review.renewalData,
        keepMedical: st.premium.keepMedical ? st.premium.keepMedical : false,
        keepDental: st.premium.keepDental ? st.premium.keepDental : false,
        keepVision: st.premium.keepVision ? st.premium.keepVision : false,
        keepLife: st.premium.keepLife ? st.premium.keepLife : false,
        keepDepLife: st.premium.keepDepLife ? st.premium.keepDepLife : false,
        callingPage: 'review',
        beenToRenewalShopping: true,
    };
    const query = getMarketType() === MARKET_TYPES.OXFORD ? {
        mdvContribution: st.review.mdvContribution,
        salesRepData: st.review.salesRepData,
        agentData: st.review.agentData,
        memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.review.renewalCaseData,
        renewalData: renewalDataCopy,
        shoppingPlans: st.review.shoppingPlans,
        dental: st.review.dental ? st.review.dental : [],
        vision: st.review.vision ? st.review.vision : [],
        life: st.review.life ? st.review.life : [],
        std : st.review.std ? st.review.std : [],
        ltd : st.review.ltd ? st.review.ltd : [],
        suppLife: st.review.suppLife ? st.review.suppLife : [],
        dependentLife: st.review.dependentLife ? st.review.dependentLife : [],
        alternate: st.review.alternate ? st.review.alternate : [],
        caseSubmitted: (st && st.review && st.review.caseSubmitted) ? st.review.caseSubmitted : false,
        stepperAccess: st.renewals.stepperAccess,
        activeShoppingTab: activeIndex,
        customPlanNames: st.review.customPlanNames ? st.review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.review.enrolledPlansMed ? st.review.enrolledPlansMed : {},
        employeesEnrollStatus: st.review.employeesEnrollStatus ? st.review.employeesEnrollStatus : 'Not Started',
    } :
        {
            renewalClassingOpted : st.review.renewalClassingOpted ? st.review.renewalClassingOpted : false,
            mdvContribution: st.review.mdvContribution,
            salesRepData: st.review.salesRepData,
            agentData: st.review.agentData,
            memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
            primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
            renewalCaseData: st.review.renewalCaseData,
            renewalData: renewalDataCopy,
            shoppingPlans: st.review.shoppingPlans,
            shoppingDentalPlans: st.decide?.shoppingDentalPlans || [],
            shoppingVisionPlans: st.decide?.shoppingVisionPlans || [],
            shoppingLifePlans: st.decide?.shoppingLifePlans || [],
            shoppingDepLifePlans: st.decide?.shoppingDepLifePlans || [],
            medical: st.review.medical ? st.review.medical : [],
            dental: st.review.dental ? st.review.dental : [],
            vision: st.review.vision ? st.review.vision : [],
            life: st.review.life ? st.review.life : [],
            suppLife: st.review.suppLife ? st.review.suppLife : [],
            dependentLife: st.review.dependentLife ? st.review.dependentLife : [],
            // alternate: st.review.alternate ? st.review.alternate : [],
            caseSubmitted: (st && st.review && st.review.caseSubmitted) ? st.review.caseSubmitted : false,
            stepperAccess: st.renewals.stepperAccess,
            activeShoppingTab: activeIndex,
            customPlanNames: st.review.customPlanNames ? st.review.customPlanNames : emptyCustomPlanState,
            enrolledPlansMed: st.review.enrolledPlansMed ? st.review.enrolledPlansMed : {},
            employeesEnrollStatus: st.review.employeesEnrollStatus ? st.review.employeesEnrollStatus : 'In Progress',
            members: st.review.members,
            isMMRCase: isMmr(),
        };

    if (isUhcRenewals() && !query.isMMRCase) {
        delete query.renewalData.Ibr
        delete query.renewalData.members
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const saveDecideDetailQueryWithBeenToRenewalShopping = (st) => {
    const decideDataCopy = {
        ...st.decide.renewalData,
        keepMedical: st.decide.renewalData.keepMedical ? st.decide.renewalData.keepMedical : false,
        keepDental: st.decide.renewalData.keepDental ? st.decide.renewalData.keepDental : false,
        keepVision: st.decide.renewalData.keepVision ? st.decide.renewalData.keepVision : false,
        keepLife: st.decide.renewalData.keepLife ? st.decide.renewalData.keepLife : false,
        keepDepLife: st.decide.renewalData.keepDepLife ? st.decide.renewalData.keepDepLife : false,
        callingPage: 'decide',
        beenToRenewalShopping: true,
    };
    let query = {
        mdvContribution: st.decide.mdvContribution,
        salesRepData: st.decide.salesRepData,
        agentData: st.decide.agentData,
        memberGroupId: st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.decide.renewalCaseData,
        renewalData: decideDataCopy,
        caseSubmitted: (st && st.decide && st.decide.caseSubmitted) ? st.decide.caseSubmitted : false,
        shoppingPlans: st.decide.shoppingPlans,
        stepperAccess: st.renewals.stepperAccess,
        customPlanNames: st.decide.customPlanNames ? st.decide.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.decide.enrolledPlansMed ? st.decide.enrolledPlansMed : {},
        employeesEnrollStatus: st.decide.employeesEnrollStatus ? st.decide.employeesEnrollStatus : 'In Progress',
    };

    if (isUhcRenewals()) {
        query = {
            ...query,
            medical: st.decide.medical ? st.decide.medical : [],
            dental: st.decide.dental ? st.decide.dental : [],
            vision: st.decide.vision ? st.decide.vision : [],
            life: st.decide.life ? st.decide.life : [],
            dependentLife: st.decide.dependentLife ? st.decide.dependentLife : [],
            // alternate: st.decide.alternate ? st.decide.alternate : [],
            members: st.decide.members ? st.decide.members : [],
            isMMRCase: isMmr(),
        }
        if (!query.isMMRCase) {
            delete query.renewalData.members
            delete query.renewalData.Ibr
        }
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const saveCensusRefreshRenewPlanAsIs = async (rules, st, premium, isDecide = false, quoteTrackingNumber = '', contractOptList) => {

    let dentalPlans = [], visionPlans = [], lifePlans = [], dependentLifePlans = [], alternate = []
    let dentalSelected = [], visionSelected = [], lifeSelected = [], dependentLifeSelected = []
    let ltdPlans = [], stdPlans = [], suppLifePlans = []
    let ltdSelected = [], stdSelected = [], suppLifeSelected = []

    let medicalPlans = [], medicalSelected = [];
    const fullProps = getReduxState();
    let isAutomateMMR = fullProps.renewals.automateMMR ? true : false;
    let mmrDentalPlans=[]
    let mmrVisionPlans=[]
    let mmrLifePlans=[]
    let mmrDependentLifePlans=[]
    let mmrSTDPlans = [];
    let mmrLTDPlans = [];
    let mmrSuppLifePlans = [];

    if (isUhcRenewals()) {
        if (rules && rules.enrollment && rules.enrollment.enrolledPlans) {
            Object.keys(rules.enrollment.enrolledPlans).map((item) => {
                medicalPlans.push(rules.enrollment.enrolledPlans[item]);
            })
            medicalPlans = medicalPlans.flat();
        }
    } else {
        medicalPlans = rules && rules.enrollment && rules.enrollment.enrolledPlans && rules.enrollment.enrolledPlans.standalone ? rules.enrollment.enrolledPlans.standalone : [];
    }

    let enrolledPlansRL = medicalPlans;
    if (contractOptList && contractOptList.length > 0 && enrolledPlansRL && enrolledPlansRL.length > 0) {
        let renewalPlanList = enrolledPlansRL.filter(plan => plan.renewalPlan && plan.renewalPlan === true);
        let newPlanList = enrolledPlansRL.filter(plan => !plan.renewalPlan);
        renewalPlanList.forEach((plan) => {
            const matchedPlansWithContractList = contractOptList.find((contract) => contract.externalMarketingName === plan.codeDisplay)
            if (matchedPlansWithContractList) {
                plan.coverageOptionID = matchedPlansWithContractList.coverageOptionID;
                plan.planOptionID = matchedPlansWithContractList.planOptionID;
                plan.previousPlanOptionID = matchedPlansWithContractList && matchedPlansWithContractList.previousPlanOptionID ? matchedPlansWithContractList.previousPlanOptionID : '';
                plan.contractOptionID = matchedPlansWithContractList.contractOptionID;
                plan.contractOptID = matchedPlansWithContractList.contractOptID;
                plan.planYearMonth = matchedPlansWithContractList.planYearMonth;
                plan.medicalBenefitPlanOption = plan.medicalBenefitPlanOption ? plan.medicalBenefitPlanOption : matchedPlansWithContractList.planYearMonth === '00' || matchedPlansWithContractList.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                plan.disableAccumulator = true;
            }
        })
        let accumulatorChoice = '';
        contractOptList.forEach((contract) => {
            if (accumulatorChoice === '') {
                accumulatorChoice = contract.planYearMonth === '00' || contract.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
            } else {
                const currentChoice = contract.planYearMonth === '00' || contract.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                if (accumulatorChoice !== currentChoice) {
                    accumulatorChoice = 'choose';
                }
            }
        })
        if (accumulatorChoice === 'policyYear' || accumulatorChoice === 'calenderYear') {
            newPlanList.forEach(plan => {
                if (!plan.medicalBenefitPlanOption) {
                    plan.medicalBenefitPlanOption = accumulatorChoice;
                    plan.disableAccumulator = true;
                }
            })
        }
        enrolledPlansRL = renewalPlanList.concat(newPlanList);
    }

    let enrolledDentalPlansRL = rules && rules.enrollment && rules.enrollment.enrolledDentalPlans ? rules.enrollment.enrolledDentalPlans : [];
    if (contractOptList && contractOptList.length > 0 && enrolledDentalPlansRL && enrolledDentalPlansRL.length > 0) {
        enrolledDentalPlansRL.forEach((plan) => {
            const matchedPlansWithContractList = contractOptList.find((contract) => contract.externalMarketingName === plan.code)
            if (matchedPlansWithContractList) {
                plan.coverageOptionID = matchedPlansWithContractList.coverageOptionID;
                plan.planOptionID = matchedPlansWithContractList.planOptionID;
                plan.contractOptionID = matchedPlansWithContractList.contractOptionID;
                plan.contractOptID = matchedPlansWithContractList.contractOptID;
                plan.planYearMonth = matchedPlansWithContractList.planYearMonth;
                plan.medicalBenefitPlanOption = matchedPlansWithContractList.planYearMonth === '00' || matchedPlansWithContractList.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                // plan.disableAccumulator = true;
            }
        })
    }

    let enrolledVisionPlansRL = rules && rules.enrollment && rules.enrollment.enrolledVisionPlans ? rules.enrollment.enrolledVisionPlans : [];
    if (contractOptList && contractOptList.length > 0 && enrolledVisionPlansRL && enrolledVisionPlansRL.length > 0) {
        enrolledVisionPlansRL.forEach((plan) => {
            const matchedPlansWithContractList = contractOptList.find((contract) => contract.externalMarketingName === plan.code)
            if (matchedPlansWithContractList) {
                plan.coverageOptionID = matchedPlansWithContractList.coverageOptionID;
                plan.planOptionID = matchedPlansWithContractList.planOptionID;
                plan.contractOptionID = matchedPlansWithContractList.contractOptionID;
                plan.contractOptID = matchedPlansWithContractList.contractOptID;
                plan.planYearMonth = matchedPlansWithContractList.planYearMonth;
                plan.medicalBenefitPlanOption = matchedPlansWithContractList.planYearMonth === '00' || matchedPlansWithContractList.planYearMonth === '01' ? 'calenderYear' : 'policyYear';
                // plan.disableAccumulator = true;
            }
        })
    }


    if (!isDecide) {
        if (st.renewalData && st.renewalData.specialtyProducts && st.renewalData.specialtyProducts.length > 0) {
            dentalPlans = FilterACAPlans(st.renewalData).specialtyDentalPlans;
            visionPlans = FilterACAPlans(st.renewalData).specialtyVisionPlans;
            lifePlans = FilterACAPlans(st.renewalData).specialtyBasicLifePlans;
            dependentLifePlans = FilterACAPlans(st.renewalData).specialtyDepLifePlans;
        }
        else {
            let ibrObject
            if (st.renewalData.Ibr) { ibrObject = await FilterIBR(st.renewalData.Ibr, st); }
            medicalPlans = st.renewalData.Ibr ? ibrObject.medical : [];
            dentalPlans = st.renewalData.Ibr ? ibrObject.dental : [];
            visionPlans = st.renewalData.Ibr ? ibrObject.vision : [];
            lifePlans = st.renewalData.Ibr ? ibrObject.basicLife : [];
            dependentLifePlans = st.renewalData.Ibr ? ibrObject.dependentLife : [];
            ltdPlans = st.renewalData.Ibr ? ibrObject.ltd : [];
            stdPlans = st.renewalData.Ibr ? ibrObject.shortTermDisability : [];
            suppLifePlans = st.renewalData.Ibr ? ibrObject.suppLife : [];
            alternate = st.renewalData.Ibr ? ibrObject.alternate : [];
        }

        let planArr=[]
        if(isAutomateMMR){
            dentalPlans.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                planArr.push(plan);
                mmrDentalPlans.push(planArr)
            })
            planArr=[]
            visionPlans.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                plan.re
                planArr.push(plan);
                mmrVisionPlans.push(planArr)
            })
            planArr=[]
            lifePlans.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                planArr.push(plan);
                mmrLifePlans.push(planArr)
            })
            planArr=[]
            dependentLifePlans.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                planArr.push(plan);
                mmrDependentLifePlans.push(planArr)
            })
            planArr=[]
            ltdPlans?.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                //planArr.push(plan);
                mmrLTDPlans.push(planArr)
            })
            planArr=[]
            stdPlans?.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                //planArr.push(plan);
                mmrSTDPlans.push(planArr)
            })
            planArr=[]
            suppLifePlans?.forEach((itr)=> {
                let plan = itr[0];
                planArr.push(plan);
                //planArr.push(plan);
                mmrSuppLifePlans.push(planArr)
            })
        }

        medicalSelected = getSelectedPlansFromIbrSets(medicalPlans);
        dentalSelected = getSelectedPlansFromIbrSets(dentalPlans);
        visionSelected = getSelectedPlansFromIbrSets(visionPlans);
        lifeSelected = getSelectedPlansFromIbrSets(lifePlans);
        dependentLifeSelected = getSelectedPlansFromIbrSets(dependentLifePlans);
        ltdSelected = getSelectedPlansFromIbrSets(ltdPlans);
        stdSelected = getSelectedPlansFromIbrSets(stdPlans);
        suppLifeSelected = getSelectedPlansFromIbrSets(suppLifePlans);
    }
    else {
        medicalPlans = st.medical ? st.medical.set ? st.medical.set : [] : [];
        dentalPlans = st.dental ? st.dental.set ? st.dental.set : [] : [];
        visionPlans = st.vision ? st.vision.set ? st.vision.set : [] : []
        lifePlans = st.life ? st.life.set ? st.life.set : [] : []
        dependentLifePlans = st.dependentLife ? st.dependentLife.set ? st.dependentLife.set : [] : []
        alternate = st.alternate ? st.alternate ? st.alternate : [] : []

        medicalSelected = st.medical ? st.medical.selectedPlans ? st.medical.selectedPlans : [] : [];
        dentalSelected = st.dental ? st.dental.selectedPlans ? st.dental.selectedPlans : [] : [];
        visionSelected = st.vision ? st.vision.selectedPlans ? st.vision.selectedPlans : [] : []
        lifeSelected = st.life ? st.life.selectedPlans ? st.life.selectedPlans : [] : []
        dependentLifeSelected = st.dependentLife ? st.dependentLife.selectedPlans ? st.dependentLife.selectedPlans : [] : []
    }

    const renewalDataCopy = {
        ...st.renewalData,
        keepMedical: premium.keepMedical ? premium.keepMedical : st.renewalData.keepMedical || false,
        keepDental: premium.keepDental ? premium.keepDental : st.renewalData.keepDental || false,
        keepVision: premium.keepVision ? premium.keepVision : st.renewalData.keepVision || false,
        keepLife: premium.keepLife ? premium.keepLife : st.renewalData.keepLife || false,
        keepDepLife: premium.keepDepLife ? premium.keepDepLife : st.renewalData.keepDepLife || false,
        renewAsIs: !isDecide,
    };

    const query = {
        mdvContribution: st.mdvContribution,
        salesRepData: st.salesRepData,
        agentData: st.agentData,
        memberGroupId: st.renewalCaseData && st.renewalCaseData.memberGroupId ? st.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.val && st.val.primeCustomerNumber ? st.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalCaseData,
        renewalData: renewalDataCopy,
        isEnrolled: true,
        medical: { set: medicalPlans, selectedPlans: medicalSelected },
        dental: { set: isAutomateMMR ? mmrDentalPlans : dentalPlans, selectedPlans: isAutomateMMR ? dentalPlans[0] : dentalSelected },
        vision: { set: isAutomateMMR ? mmrVisionPlans : visionPlans, selectedPlans: isAutomateMMR ? visionPlans[0] : visionSelected },
        life: { set: isAutomateMMR ? mmrLifePlans : lifePlans, selectedPlans: isAutomateMMR ? lifePlans[0] : lifeSelected },
        ltd:{ set: isAutomateMMR ? mmrLTDPlans : [], selectedPlans: isAutomateMMR ? mmrLTDPlans[0] : [] },
        std:{ set: isAutomateMMR ? mmrSTDPlans : [], selectedPlans: isAutomateMMR ? mmrSTDPlans[0] : [] },
        suppLife:{ set: isAutomateMMR ? mmrSuppLifePlans : [], selectedPlans: isAutomateMMR ? mmrSuppLifePlans[0] : [] },
        dependentLife: { set: isAutomateMMR ? mmrDependentLifePlans : dependentLifePlans, selectedPlans: isAutomateMMR ? dependentLifePlans[0] : dependentLifeSelected },
        alternate: alternate,
        stepperAccess: st.stepperAccess,
        customPlanNames: st.customPlanNames ? st.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.enrolledPlansMed ? st.enrolledPlansMed : {},
        shoppingPlans: st.shoppingPlans,
        employeesEnrollStatus: st.employeesEnrollStatus ? st.employeesEnrollStatus : 'In Progress',
        enrolledPlans: enrolledPlansRL,
    };

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        delete query.alternate
        query.enrolledPlans = medicalSelected
        query.enrolledDentalPlans = dentalSelected
        query.enrolledVisionPlans = visionSelected
        query.enrolledLifePlans = lifeSelected
        query.enrolledDependentLifePlans = dependentLifeSelected
        // delete query.renewalData.members
        // delete query.renewalData.Ibr
    }

    query.quoteTrackingNumber = quoteTrackingNumber;

    return query;
};

export const getMemberGroupIdDataDecide = (st) => {
    return st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : null
}

const locationFormatInitial = (incomingState, rulesResponse) => {
    let groupLocation = {
        zipCode: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        state: '',
        isPrimary: '',
    };
    let employeeCounts = [];
    if (incomingState.renewalData.memberGroup && incomingState.renewalData.memberGroup.locations && incomingState.renewalData.memberGroup.locations.location) {
        groupLocation = incomingState.renewalData.memberGroup.locations.location;
    }
    if (incomingState.renewalData.memberGroup && incomingState.renewalData.memberGroup.locations && incomingState.renewalData.memberGroup.locations.employeeCounts && incomingState.renewalData.memberGroup.locations.employeeCounts.length > 0) {
        employeeCounts = incomingState.renewalData.memberGroup.locations.employeeCounts;
    }
    return [{
        totalEmps: isUhcRenewals() ? incomingState?.renewalData?.Ibr?.group?.primaryLocation?.totalEmployed : incomingState.renewalData.members.length,
        totalEligEmps: employeeCounts.find((count) => count.type === 'Eligible') ? employeeCounts.find((count) => count.type === 'Eligible').count : 0,
        atne: employeeCounts.find((count) => count.type === 'ATNE') ? employeeCounts.find((count) => count.type === 'ATNE').count : 0,
        fte: employeeCounts.find((count) => count.type === 'FTE') ? employeeCounts.find((count) => count.type === 'FTE').count : 0,
        fteRule: isUhcRenewals() ? rulesResponse : 'FTE',
        primaryInd: true,
        zipData: {
            zipCode: groupLocation.zipCode,
            city: groupLocation.city,
            county: groupLocation.county,
            countyFIPSCode: '',
            stateCode: groupLocation.state,
            state: '',
            stateFIPSCode: '',
            display: `${groupLocation.zipCode} - ${groupLocation.city
                }${groupLocation.state}(Hartford County)`,
        },
    },];
};

const primaryContactIntialFormat = (renewalData) => ({
    firstName: renewalData.memberGroup.locations.contact.firstName,
    lastName: renewalData.memberGroup.locations.contact.lastName,
    email: isUhcRenewals() ? renewalData.memberGroup.locations.contact.email.trim() : renewalData.memberGroup.locations.contact.email,
    fullName: `${renewalData.memberGroup.locations.contact.firstName} ${renewalData.memberGroup.locations.contact.lastName}`,
});

const addOneYear = (effectiveDate) => {
    let today = new Date(effectiveDate)
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var oneYear = new Date(year + 1, month, day);
    let formateddate = moment(oneYear).format("MM/DD/YYYY")
    let convertedDate = convertDateString(formateddate, '/', 'MMDDYYYY', '-', 'YYYYMMDD');
    return convertedDate
};

export const generateRenewalCompanyProfileIntial = (incomingState, quoteTrackingNumber = "", rulesResponse) => {
    const newState = {};
    const primarySetLocations = locationFormatInitial(incomingState, rulesResponse);
    const resolvedEmployees = [];
    newState.primaryContact = primaryContactIntialFormat(incomingState.renewalData);

    newState.companyName = incomingState.renewalData.memberGroup.memberGroupName;
    newState.locations = primarySetLocations;
    newState.sicCode = incomingState.renewalData.memberGroup.industryCode;
    newState.franchiseCode = '';
    newState.effectiveDate = isoStringToMMDDYYYY(incomingState.renewalData.memberGroup.renewalEffectiveDate);
    newState.employees = resolvedEmployees;
    newState.memberGroupId = incomingState.renewalData && incomingState.renewalData.memberGroup && incomingState.renewalData.memberGroup.memberGroupId ? (incomingState.renewalData.memberGroup.memberGroupId).toString() : null;
    newState.primeCustomerNumber = incomingState.renewalData && incomingState.renewalData.primeCustomerNumber ? (incomingState.renewalData.primeCustomerNumber).toString() : null;
    newState.selectedMedicalPlans = incomingState.selectedMedicalPlans || [];
    newState.lastUpdatedDate = new Date().toISOString();
    newState.renewal = true;
    newState.primaryLocationIdx = 0;
    newState.quoteTrackingNumber = quoteTrackingNumber;
    newState.employerEnrollment = {};
    newState.enrollment = {
        requiredDoc: {}
    }
    return newState;
};

const locationNewFormat = (incomingState, renewalData) => [{
    totalEmps: incomingState.employee && incomingState.employee.length > 0 ? incomingState.employee.length : 0,
    totalEligEmps: incomingState.employee.length,
    atne: incomingState.employee.length,
    fte: incomingState.employee.length,
    fteRule: 'FTE',
    primaryInd: true,
    zipData: {
        zipCode: renewalData.memberGroup.locations.location.zipCode,
        city: renewalData.memberGroup.locations.location.city,
        county: renewalData.memberGroup.locations.location.county,
        countyFIPSCode: '',
        stateCode: renewalData.memberGroup.locations.location.state,
        state: 'Connecticut',
        stateFIPSCode: '',
        display: `${renewalData.memberGroup.locations.location.zipCode} - ${renewalData.memberGroup.locations.location.city
            }${renewalData.memberGroup.locations.location.state}()`,
    },
},];

const primaryContactNewFormat = (renewalData) => ({
    firstName: renewalData.memberGroup.locations.contact.firstName,
    lastName: renewalData.memberGroup.locations.contact.lastName,
    email: isUhcRenewals() ? renewalData.memberGroup.locations.contact.email.trim() : renewalData.memberGroup.locations.contact.email,
    fullName: `${renewalData.memberGroup.locations.contact.firstName} ${renewalData.memberGroup.locations.contact.lastName}`,
});

export const refineEmployeeCensus = (employeeCensus) => {
    const employeeMember = [];
    const finalemployeeCensus = [];
    employeeCensus.map((emp) => {
        const { enrollmentEmployee: enrolledEmployee, enrollmentDependent: enrolledEmployeeDependent } = emp;
        const { contactInfo, employeeInfo, pcpAssignment, productSelection, waiveCoverage } = enrolledEmployee;
        contactInfo.county = contactInfo?.zipCode?.data?.county || '';
        const empInfo = deepCopy(employeeInfo);
        const indEmployee = deepCopy(employeeInfo);
        indEmployee.coverages = {
            medical: 'EE',
            dental: 'EE',
            vision: 'EE',
            basicLife: 'EE',
            std: 'EE',
            ltd: 'W',
        };

        indEmployee.ssnTin = getValueOrEmptyString(employeeInfo.ssnTin);
        indEmployee.numberOfHoursWorked = getValueOrEmptyString(employeeInfo.numberOfHoursWorked);
        indEmployee.zipCode = contactInfo.zipCode && contactInfo.zipCode.data && contactInfo.zipCode.data.zipCode ?
            contactInfo.zipCode.data.zipCode : contactInfo.zipCode;
        indEmployee.streetAddress = contactInfo.streetAddress;
        indEmployee.city = contactInfo.city;
        indEmployee.telephone = contactInfo.telephone;
        indEmployee.stateCode = contactInfo.state;
        //indEmployee.existingEmpStatus = empInfo.employeeStatus;
        indEmployee.medicarePrimary = getValueOrEmptyString(employeeInfo.medicarePrimary);
        indEmployee.anyOtherCoverage = getValueOrEmptyString(employeeInfo.anyOtherCoverage);
        indEmployee.nameOtherCarrier = getValueOrEmptyString(employeeInfo.nameOtherCarrier);
        indEmployee.employeeInfo = empInfo;
        indEmployee.contactInfo = contactInfo;
        indEmployee.pcpAssignment = pcpAssignment;
        indEmployee.productSelection = productSelection;
        indEmployee.dependents = enrolledEmployeeDependent;
        indEmployee.employeeInfo.newEmployee = false;
        if (waiveCoverage.isWaivingAll === 'N') {
            waiveCoverage.isWaivingAll = 'No'
        } else if (waiveCoverage.isWaivingAll === 'Yes') {
            waiveCoverage.isWaivingAll = 'Yes'
        }
        indEmployee.waiveCoverage = waiveCoverage;

        if (waiveCoverage && waiveCoverage.medicalWaiverReason === 'Other (reason not listed)') {
            indEmployee.medicalCoverageInfo = {
                ...indEmployee.medicalCoverageInfo,
                medicalWaived: true,
                medicalWaiverReason: "Other (reason not listed)"
            }
        }

        if (isUhcRenewals() || isOxfordRenewals()) {
            indEmployee.coverages = getCoverageTypeFromSelectedProducts(indEmployee.dependents, indEmployee.productSelection, {}, indEmployee.coverages)
            indEmployee.waiveCoverageCopy = { ...waiveCoverage };
        }

        indEmployee.errors = {
            firstName: null,
            lastName: null,
            dob: null,
            age: null,
            gender: null,
            employeeStatus: null,
            coverages: null,
            outOfArea: null,
            salary: null,
            zipCode: null,
        };
        employeeMember.push(indEmployee);
    });
    finalemployeeCensus.push(employeeMember);
    return finalemployeeCensus;
};
//From Group Enroll API call
export const generateRenewalEmployerEnrollmentNewFormat = (employerData) => {
    const employerEnrollment = {
        employer: employerData.employerEnrollment && employerData.employerEnrollment.employer ? employerData.employerEnrollment.employer : {},
        eligibility: employerData.employerEnrollment && employerData.employerEnrollment.eligibility ? employerData.employerEnrollment.eligibility : {},
        standardHRA: employerData.employerEnrollment && employerData.employerEnrollment.standardHRA ? employerData.employerEnrollment.standardHRA : {},
    };
    return employerEnrollment;
};

export const generateRenewalCompanyProfileNewFormat = (incomingState, renewalData, employerEnrollment) => {
    const newState = {};
    const primarySetLocations = locationNewFormat(incomingState, renewalData);
    const resolvedEmployees = refineEmployeeCensus(incomingState.employee);

    newState.primaryContact = primaryContactNewFormat(renewalData);

    newState.companyName = renewalData.memberGroup.memberGroupName;
    newState.locations = primarySetLocations;
    newState.sicCode = '';
    newState.franchiseCode = '';
    newState.effectiveDate = isoStringToMMDDYYYY(renewalData.memberGroup.renewalEffectiveDate);
    newState.employees = resolvedEmployees;
    newState.memberGroupId = renewalData.memberGroup && renewalData.memberGroup.memberGroupId ? (renewalData.memberGroup.memberGroupId).toString() : null;
    newState.selectedMedicalPlans = incomingState.selectedMedicalPlans || [];
    newState.lastUpdatedDate = new Date().toISOString();
    newState.renewal = true;
    newState.primaryLocationIdx = 0;
    newState.employerEnrollment = employerEnrollment;
    return newState;
};

export const saveReviewDetailQueryRenewAsIs = (st) => {
    const platform = identifyPlatform(st.review.renewalData)
    let newPlanRates;
    if (platform !== "PRIME") {//po
        newPlanRates = st.review.renewalData.planRates.map((item) => {
            if (item.renewalPlanType === 'Renewal') {
                const updatedItem = {
                    ...item,
                    isChecked: true,
                };
                return updatedItem;
            }
            return item;
        });
    }

    const renewalDataCopy = {
        ...st.review.renewalData,
        keepMedical: st.premium.keepMedical ? st.premium.keepMedical : false,
        keepDental: st.premium.keepDental ? st.premium.keepDental : false,
        keepVision: st.premium.keepVision ? st.premium.keepVision : false,
        keepLife: st.premium.keepLife ? st.premium.keepLife : false,
        keepDepLife: st.premium.keepDepLife ? st.premium.keepDepLife : false,
        callingPage: 'review',
        renewAsIs: true,
        planRates: newPlanRates,
    };
    const query = {
        mdvContribution: st.review.mdvContribution,
        salesRepData: st.review.salesRepData,
        agentData: st.review.agentData,
        memberGroupId: st.review && st.review.renewalCaseData && st.review.renewalCaseData.memberGroupId && st.review.renewalCaseData.memberGroupId ? st.review.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.review.renewalCaseData,
        renewalData: renewalDataCopy,
        medical: st.review.medical ? st.review.medical : [],
        dental: st.review.dental ? st.review.dental : [],
        vision: st.review.vision ? st.review.vision : [],
        life: st.review.life ? st.review.life : [],
        dependentLife: st.review.dependentLife ? st.review.dependentLife : [],
        alternate: st.review.alternate ? st.review.alternate : [],
        caseSubmitted: (st && st.review && st.review.caseSubmitted) ? st.review.caseSubmitted : false,
        shoppingPlans: st.review.shoppingPlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            reviewDetail: true,
        },
        customPlanNames: st.review.customPlanNames ? st.review.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.review.enrolledPlansMed ? st.review.enrolledPlansMed : {},
        employeesEnrollStatus: st.review.employeesEnrollStatus ? st.review.employeesEnrollStatus : 'In Progress',
    };

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        delete query.alternate
        // delete query.renewalData.members
        // delete query.renewalData.Ibr
        query.enrolledPlans = [];
        query.enrolledDentalPlans = [];
        query.enrolledVisionPlans = [];
        query.enrolledLifePlans = [];
        query.enrolledDependentLifePlans = [];

        if (st.review.medical && st.review.medical.set && st.review.medical.set.length > 0) {
            st.review.medical.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledPlans.push(setPlan[1]);
            });
        }

        if (st.review.dental && st.review.dental.set && st.review.dental.set.length > 0) {
            st.review.dental.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledDentalPlans.push(setPlan[1]);
            });
        }

        if (st.review.vision && st.review.vision.set && st.review.vision.set.length > 0) {
            st.review.vision.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledVisionPlans.push(setPlan[1]);
            });
        }

        if (st.review.life && st.review.life.set && st.review.life.set.length > 0) {
            st.review.life.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledLifePlans.push(setPlan[1]);
            });
        }

        if (st.review.dependentLife && st.review.dependentLife.set && st.review.dependentLife.set.length > 0) {
            st.review.dependentLife.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledDependentLifePlans.push(setPlan[1]);
            });
        }
    }

    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const saveDecideDetailQueryRenewAsIs = (st) => {
    let newPlanRates
    const platform = identifyPlatform(st.decide.renewalData)
    if (platform !== 'PRIME') {//po
        newPlanRates = st.decide.renewalData.planRates.map((item) => {
            if (item.renewalPlanType === 'Renewal') {
                const updatedItem = {
                    ...item,
                    isChecked: true,
                };
                return updatedItem;
            }
            return item;
        });
    }

    const decideDataCopy = {
        ...st.decide.renewalData,
        keepMedical: st.decide.renewalData.keepMedical ? st.decide.renewalData.keepMedical : false,
        keepDental: st.decide.renewalData.keepDental ? st.decide.renewalData.keepDental : false,
        keepVision: st.decide.renewalData.keepVision ? st.decide.renewalData.keepVision : false,
        keepLife: st.decide.renewalData.keepLife ? st.decide.renewalData.keepLife : false,
        keepDepLife: st.decide.renewalData.keepDepLife ? st.decide.renewalData.keepDepLife : false,
        callingPage: 'decide',
        renewAsIs: true,
        planRates: newPlanRates,
    };

    const query = {
        mdvContribution: st.decide.mdvContribution,
        salesRepData: st.decide.salesRepData,
        agentData: st.decide.agentData,
        memberGroupId: st.decide && st.decide.renewalCaseData && st.decide.renewalCaseData.memberGroupId ? st.decide.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.decide.renewalCaseData,
        renewalData: decideDataCopy,
        dental: st.decide.dental ? deepCopy(st.decide.dental) : [],
        vision: st.decide.vision ? deepCopy(st.decide.vision) : [],
        life: st.decide.life ? deepCopy(st.decide.life) : [],
        dependentLife: st.decide.dependentLife ? deepCopy(st.decide.dependentLife) : [],
        alternate: st.decide.alternate ? st.decide.alternate : [],
        caseSubmitted: (st && st.decide && st.decide.caseSubmitted) ? st.decide.caseSubmitted : false,
        shoppingPlans: st.decide.shoppingPlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            decide: true,
        },
        customPlanNames: st.decide.customPlanNames ? st.decide.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.decide.enrolledPlansMed ? st.decide.enrolledPlansMed : {},
        employeesEnrollStatus: st.decide.employeesEnrollStatus ? st.decide.employeesEnrollStatus : 'In Progress',
    };
    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query);
        }
    }

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        delete query.alternate
        // delete query.renewalData.members
        // delete query.renewalData.Ibr
        query.enrolledPlans = [];
        query.enrolledDentalPlans = [];
        query.enrolledVisionPlans = [];
        query.enrolledLifePlans = [];
        query.enrolledDependentLifePlans = [];

        if (st.decide.medical && st.decide.medical.set && st.decide.medical.set.length > 0) {
            st.decide.medical.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledPlans.push(setPlan[1]);
            });
        }

        if (st.decide.dental && st.decide.dental.set && st.decide.dental.set.length > 0) {
            st.decide.dental.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledDentalPlans.push(setPlan[1]);
            });
        }

        if (st.decide.vision && st.decide.vision.set && st.decide.vision.set.length > 0) {
            st.decide.vision.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledVisionPlans.push(setPlan[1]);
            });
        }

        if (st.decide.life && st.decide.life.set && st.decide.life.set.length > 0) {
            st.decide.life.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledLifePlans.push(setPlan[1]);
            });
        }

        if (st.decide.dependentLife && st.decide.dependentLife.set && st.decide.dependentLife.set.length > 0) {
            st.decide.dependentLife.set.map((setPlan) => {
                if (setPlan[1]) query.enrolledDependentLifePlans.push(setPlan[1]);
            });
        }
    }
    if (st.caseTracking.currentQuoteId != undefined)
        query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const saveShoppingHelperDetailQueryRenewAsIs = (st) => {
    let shoppingPlans = [];
    const enrolledDentalPlans = [];
    const enrolledVisionPlans = [];
    const enrolledLifePlans = [];
    const
        enrolledDependentLifePlans = [];

    if (st.renewalShopping.selectedPlans && st.renewalShopping.selectedPlans.length > 0) { shoppingPlans = st.renewalShopping.selectedPlans; }

    if (st.renewalShoppingHelper?.dental?.length > 0 && st.renewalShoppingHelper.dental.set && st.renewalShoppingHelper.dental.set.length > 0) {
        st.renewalShoppingHelper.dental.set.map((setPlan) => {
            if (setPlan[1]) enrolledDentalPlans.push(setPlan[1]);
        });
    }

    if (st.renewalShoppingHelper?.vision?.length > 0 && st.renewalShoppingHelper.vision.set && st.renewalShoppingHelper.vision.set.length > 0) {
        st.renewalShoppingHelper.vision.set.map((setPlan) => {
            if (setPlan[1]) enrolledVisionPlans.push(setPlan[1]);
        });
    }

    if (st.renewalShoppingHelper?.life?.length > 0 && st.renewalShoppingHelper.life.set && st.renewalShoppingHelper.life.set.length > 0) {
        st.renewalShoppingHelper.life.set.map((setPlan) => {
            if (setPlan[1]) enrolledLifePlans.push(setPlan[1]);
        });
    }

    if (st.renewalShoppingHelper?.dependentLife?.length > 0 && st.renewalShoppingHelper.dependentLife.set && st.renewalShoppingHelper.dependentLife.set.length > 0) {
        st.renewalShoppingHelper.dependentLife.set.map((setPlan) => {
            if (setPlan[1]) enrolledDependentLifePlans.push(setPlan[1]);
        });
    }
    const renewalShoppingHelperDataCopy = {
        ...st.renewalShoppingHelper.renewalData,
        planRates: st.renewalShoppingHelper.renewalData.planRates,
        beenToRenewalShopping: true,
        callingPage: 'ren-shopping',
        renewAsIs: true,
    };
    const query = {
        mdvContribution: st.renewalShoppingHelper.mdvContribution,
        salesRepData: st.renewalShoppingHelper.salesRepData,
        agentData: st.renewalShoppingHelper.agentData,
        memberGroupId: st.renewalShoppingHelper && st.renewalShoppingHelper.renewalCaseData && st.renewalShoppingHelper.renewalCaseData.memberGroupId ? st.renewalShoppingHelper.renewalCaseData.memberGroupId : null,
        primeCustomerNumber: st.review.val && st.review.val.primeCustomerNumber ? st.review.val.primeCustomerNumber : null,
        renewalCaseData: st.renewalShoppingHelper.renewalCaseData,
        renewalData: renewalShoppingHelperDataCopy,
        dental: st.renewalShoppingHelper.dental ? deepCopy(st.renewalShoppingHelper.dental) : [],
        vision: st.renewalShoppingHelper.vision ? deepCopy(st.renewalShoppingHelper.vision) : [],
        life: st.renewalShoppingHelper.life ? deepCopy(st.renewalShoppingHelper.life) : [],
        dependentLife: st.renewalShoppingHelper.dependentLife ? deepCopy(st.renewalShoppingHelper.dependentLife) : [],
        alternate: st.renewalShoppingHelper.alternate ? st.renewalShoppingHelper.alternate : [],
        caseSubmitted: (st && st.renewalShoppingHelper && st.renewalShoppingHelper.caseSubmitted) ? st.renewalShoppingHelper.caseSubmitted : false,
        shoppingPlans,
        enrolledDentalPlans,
        enrolledLifePlans,
        enrolledVisionPlans,
        enrolledDependentLifePlans,
        stepperAccess: {
            ...st.renewals.stepperAccess,
            review: true,
        },
        customPlanNames: st.renewalShoppingHelper.customPlanNames ? st.renewalShoppingHelper.customPlanNames : emptyCustomPlanState,
        enrolledPlansMed: st.renewalShoppingHelper.enrolledPlansMed ? st.renewalShoppingHelper.enrolledPlansMed : {},
        employeesEnrollStatus: st.renewalShoppingHelper.employeesEnrollStatus ? st.renewalShoppingHelper.employeesEnrollStatus : 'In Progress',
    };
    if (isUhcRenewals()) {
        query.isMMRCase = isMmr()
        if (!query.isMMRCase) {
            deleteExtrasFromQuery(query);
            deletePlanSetForProducts(query, true);
        }
    }
    query.quoteTrackingNumber = st.caseTracking.currentQuoteId;

    return query;
};

export const formatIBRMembers = (renewalData) => {

    const Ibr = renewalData.Ibr ? renewalData.Ibr : renewalData.Ibr ? renewalData.Ibr : null
    const dentalPlans = []
    const visionPlans = []
    const lifePlans = []
    const dependentLifePlans = []

    const planOfferingData = Ibr.quote.planOfferingData;
    for (const planOfferings of planOfferingData) {
        for (const item in planOfferings) {
            if (item === 'existingPlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_SPOUSE') dependentLifePlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_CHILD') dependentLifePlans.push(plan.planID)
                })
            }
            if (item === 'alternatePlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                })
            }
        }
    }

    const covTypes = {
        'EMP': 'Employee Only',
        'ESP': 'Employee With Spouse',
        'ECH': 'Employee With Child',
        'FAM': 'Employee With Family',
        'WAV': 'Waived',
        '': ''
    }

    const covTypesUHC = {
        'EMP': 'EE',
        'ESP': 'EE/SP',
        'ECH': 'EE/CH',
        'FAM': 'EE/FAM',
        'WAV': 'WAV',
        '': '',
    }
    // }

    const depTypeMapping = {
        'C': 'Child',
        'S': 'Spouse'
    }

    const effectiveDate = isoStringToMMDDYYYY(renewalData.memberGroup.renewalEffectiveDate);
    let members = [];

    if (Ibr && Ibr.quote && Ibr.quote.census && Ibr.quote.census.employee && Ibr.quote.census.employee.length > 0) {
        Ibr.quote.census.employee.forEach((member) => {
            let isChildExist = false
            let isSpouceExist = false
            let memberDOB = member.dateOfBirth.month + '/' + member.dateOfBirth.day + '/' + member.dateOfBirth.year
            let empData = {
                employeeId: member.xref,
                age: getAgeFromDOB(memberDOB, effectiveDate),
                dependents: [],
                dob: memberDOB,
                firstName: member.first ? member.first : '',
                middleName: member.middle ? member.middle : '',
                middleInitial: member.middle ? member.middle : '',
                lastName: member.last ? member.last : '',
                gender: member.genderType ? member.genderType === 'M' ? 'Male' : 'Female' : '',
                zipCode: member.homeZip ? member.homeZip : '',
                contactInfo: {
                    streetAddress: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: member.clientDefinedData.data.find(({ key }) => key === 'State') ? member.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: member.clientDefinedData.data.find(({ key }) => key === 'Zip') ? member.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    zipCode4: '',
                },
                status: member.status ? member.status : 'Active',
                employeeStatus: member.status ? member.status : 'Active',
                salary: member.salary ? member.salary : '',
                smoker: member.smoker ? member.smoker : '', //
                classId: member.classId ? member.classId : '', //
                ssn: member.ssn ? member.ssn : '',
                socialSecurityNumber: member.ssn ? member.ssn : '',
            };

            let dependents = [];
            let memberDependents = member.dependents && member.dependents.dependent && member.dependents.dependent.length > 0 ? member.dependents.dependent : null

            if (memberDependents) {
                memberDependents.forEach((dep) => {
                    const depDOB = dep.dateOfBirth.month + '/' + dep.dateOfBirth.day + '/' + dep.dateOfBirth.year
                    let depDetails = {
                        relationship: dep.depType ? depTypeMapping[dep.depType] : '',
                        age: getAgeFromDOB(depDOB, effectiveDate),
                        firstName: dep.first ? dep.first : '',
                        middleName: dep.middle ? dep.middle : '',
                        middleInitial: dep.middle ? dep.middle : '',
                        lastName: dep.last ? dep.last : '',
                        suffix: '',
                        dob: depDOB,
                        gender: dep.genderType ? dep.genderType === 'M' ? 'Male' : 'Female' : '',
                        streetAddress: empData.contactInfo.streetAddress ? empData.contactInfo.streetAddress : '',
                        streetAddressln2: empData.contactInfo.streetAddressln2 ? empData.contactInfo.streetAddressln2 : '',
                        city: empData.contactInfo.city ? empData.contactInfo.city : '',
                        state: empData.contactInfo.state ? empData.contactInfo.state : '',
                        zipCode: empData.contactInfo.zipCode ? empData.contactInfo.zipCode : '',
                        zipCode4: '',
                        smoker: dep.smoker ? dep.smoker : '',
                        employeeId: empData.xref,
                        ssn: dep.ssn ? dep.ssn : '',
                        socialSecurityNumber: dep.ssn ? dep.ssn : '',
                    };
                    let depLifeElectedPlan = dep.coverageElections.plan.find(plan => dependentLifePlans.includes(plan.planID))
                    if (dep.depType === 'C' && depLifeElectedPlan?.coverageType !== 'WAV') {
                        isChildExist = true
                    } else if (dep.depType === 'S' && depLifeElectedPlan?.coverageType !== 'WAV') {
                        isSpouceExist = true
                    }
                    dependents.push(depDetails);
                });
            }

            let dentalElectedPlan, visionElectedPlan, dentalAlternatePlan, visionAlternatePlan, lifeElectedPlan, lifeAlternatePlan, dependentLifeElectedPlan
            if (member.electedPlans && member.electedPlans.plan && member.electedPlans.plan.length > 0) {
                member.electedPlans.plan.map(plan => {
                    if (dentalPlans.includes(plan.planID)) dentalElectedPlan = plan
                    if (visionPlans.includes(plan.planID)) visionElectedPlan = plan
                    if (lifePlans.includes(plan.planID)) lifeElectedPlan = plan
                    if (dependentLifePlans.includes(plan.planID)) dependentLifeElectedPlan = plan
                })
            }
            if (member.alternatePlans && member.alternatePlans.plans && member.alternatePlans.plans.length > 0) {
                dentalAlternatePlan = member.alternatePlans.plan.find(plan => dentalPlans.includes(plan.planID))
                visionAlternatePlan = member.alternatePlans.plan.find(plan => visionPlans.includes(plan.planID))
                lifeAlternatePlan = member.alternatePlans.plans.find(plan => (lifePlans.map((x) => x.code)).includes(plan.planID))
            }

            let dentalCov = dentalElectedPlan && dentalElectedPlan.coverageType ? dentalElectedPlan.coverageType :
                dentalAlternatePlan && dentalAlternatePlan.coverageType ? dentalAlternatePlan.coverageType : ''
            let visionCov = visionElectedPlan && visionElectedPlan.coverageType ? visionElectedPlan.coverageType :
                visionAlternatePlan && visionAlternatePlan.coverageType ? visionAlternatePlan.coverageType : ''
            let lifeCov = lifeElectedPlan && lifeElectedPlan.coverageType ? lifeElectedPlan.coverageType :
                lifeAlternatePlan && lifeAlternatePlan.coverageType ? lifeAlternatePlan.coverageType : ''
            let depLifeCov = findDepLifeCov(isChildExist, isSpouceExist)

            if (isMmr()) {
                empData.coverages = {
                    medical: '',
                    dental: dentalCov,
                    vision: visionCov,
                    life: lifeCov,
                    basicLife: depLifeCov
                }
            } else {
                empData.coverages = {
                    medical: '',
                    dental: covTypes[dentalCov],
                    vision: covTypes[visionCov],
                    life: covTypes[lifeCov],
                    basicLife: covTypesUHC[depLifeCov]
                }
            }

            empData.dependents = dependents;
            members.push(empData);

        })
    }

    return members;
};
export const displayTieredCoverageTypeViaProducts = (member, productType = '', rates = '') => {
    if (member.products && member.products.length > 0) {
        const covLevelArray = member.products.map(prod => prod.coverageLevel);
        const prodCov = member.products.find(prod => prod.type === productType && prod.coverageLevel !== "")
        if (prodCov) {
            return prodCov.coverageLevel
        }
        const highestCov = findHighestCoverage(covLevelArray)
        if (highestCov !== "")
            return highestCov
    }
    else {
        const highestCov = member.coverageLevel
        if (highestCov && highestCov !== "")
            return highestCov
        else {
            if (rates !== '' && productType === 'Medical') {
                let medicalHighestCoverage = member.medicalHighestCoverage
                return medicalHighestCoverage || '';
            }
        }
    }
    return ""
}
export const formatRenewalDataMembers = (renewalData) => {
    if (!renewalData) {
        return []
    }  
    const effectiveDate = isoStringToMMDDYYYY((renewalData.memberGroup && renewalData.memberGroup.renewalEffectiveDate) ? renewalData.memberGroup.renewalEffectiveDate : '');
    const members = [];

    if (renewalData.members) {
        renewalData.members.forEach((member) => {
            if (member.relationship === 'Self') {
                const dob = isoStringToMMDDYYYY(member.birthDate);

                const empData = {
                    employeeId: member.employeeId,
                    memberId:member.memberId,
                    age: member.age ? parseInt(member.age) : getAgeFromDOB(dob, effectiveDate),
                    coverages: {
                        medical: member.coverageLevel ? member.coverageLevel : displayTieredCoverageTypeViaProducts(member, 'Medical', 'medical'),
                        dental: member.coverageLevel ? member.coverageLevel : displayTieredCoverageTypeViaProducts(member, 'Dental'),
                        vision: member.coverageLevel ? member.coverageLevel : displayTieredCoverageTypeViaProducts(member, 'Vision'),
                        life: member.coverageLevel ? member.coverageLevel : displayTieredCoverageTypeViaProducts(member, 'Life'),
                    },
                    dependents: [],
                    dob,
                    employeeStatus: member.status ? member.status : 'Active',
                    firstName: member.firstName ? member.firstName : '',
                    middleName: member.middleName ? member.middleName : '',
                    middleInitial: member.middleInitial ? member.middleInitial : '',
                    lastName: member.lastName ? member.lastName : '',
                    gender: member.gender ? member.gender === 'M' ? 'Male' : 'Female' : '',
                    zipCode: member.zipCode ? member.zipCode : '',
                    contactInfo: {
                        streetAddress: member.address1 ? member.address1 : '',
                        streetAddressln2: member.address2 ? member.address2 : '',
                        city: member.city ? member.city : '',
                        state: member.state ? member.state : '',
                        zipCode: member.zipCode ? member.zipCode : '',
                        zipCode4: '',
                    },
                    status: member.status ? member.status : 'Active',
                    employeeStatus: member.status ? member.status : 'Active',
                    salary: member.salary ? member.salary : '',
                    smoker: member.smoker ? member.smoker : '',
                    classId: member.classId ? member.classId : '',
                };

                const dependents = [];

                renewalData.members.forEach((dep) => {
                    if ((dep.relationship == 'Spouse' || dep.relationship == 'Life Partner' || dep.relationship == 'Child' || dep.relationship == 'Domestic Partner' || dep.relationship == 'Civil Union Partner' || dep.relationship == 'Dependent Child') && member.employeeId === dep.employeeId) {
                        const depDOB = isoStringToMMDDYYYY(dep.birthDate);

                        const depDetails = {
                            relationship: dep.relationship ? dep.relationship : '',
                            age: dep.age ? parseInt(dep.age) : getAgeFromDOB(depDOB, effectiveDate),
                            firstName: dep.firstName ? dep.firstName : '',
                            middleName: dep.middleName ? dep.middleName : '',
                            middleInitial: dep.middleInitial ? dep.middleInitial : '',
                            lastName: dep.lastName ? dep.lastName : '',
                            suffix: '',
                            dob: depDOB,
                            gender: dep.gender ? dep.gender === 'M' ? 'Male' : 'Female' : '',
                            streetAddress: dep.address1 ? dep.address1 : '',
                            streetAddressln2: dep.address2 ? dep.address2 : '',
                            city: dep.city ? dep.city : '',
                            state: dep.state ? dep.state : '',
                            zipCode: dep.zipCode ? dep.zipCode : '',
                            zipCode4: '',
                            smoker: dep.smoker ? dep.smoker : '',
                            employeeId: member.employeeId,
                            memberId:member.memberId
                        };

                        dependents.push(depDetails);
                    }
                });

                empData.dependents = dependents;
                members.push(empData);
            }
        });
    }

    return members;
};

export const getMedicalPlansRenewalMembers = (state) => {
    const sets = FilterRenewalsMedicalData(state.renewalData, state.customPlanNames);
    const members = formatRenewalDataMembers(state.renewalData);
    if (sets && sets.length !== 0) {
        sets.map((set) => {
            let currentEmployees = [];
            let renewingEmployees = [];
            let allEnrolledEmployees = []

            set.current.employeeRates && set.current.employeeRates.map(employeeCurrRate => {
                if (members.find((member) => parseInt(member.employeeId) === parseInt(employeeCurrRate.employeeId))) {
                    const findCurMember = deepCopy(members.find((member) => parseInt(member.employeeId) === parseInt(employeeCurrRate.employeeId)));
                    if (findCurMember) {
                        findCurMember['total'] = employeeCurrRate.total
                        currentEmployees.push(findCurMember);
                    }
                }
            })

            set.renewing.employeeRates && set.renewing.employeeRates.map(employeeRenRate => {
                if (members.find((member) => parseInt(member.employeeId) === parseInt(employeeRenRate.employeeId))) {
                    const findRenMember = deepCopy(members.find((member) => parseInt(member.employeeId) === parseInt(employeeRenRate.employeeId)));
                    if (findRenMember) {
                        allEnrolledEmployees.push(findRenMember)
                        findRenMember['total'] = employeeRenRate.total
                        renewingEmployees.push(findRenMember);
                    }
                }
            })

            set.current.employees = currentEmployees;
            set.renewing.employees = renewingEmployees;
            set.employees = allEnrolledEmployees
        });
    }

    return sets;
};

export const formatSalesRepRenewals = (salesRep, platform) => {
    let finalSalesRepData = [];
    salesRep && salesRep.map(data => {
        const salesRepData = {
            assignedAccountManagerFN: data.salesRepNameFN || data.salesRepName,
            assignedAccountManagerLN: data.salesRepNameLN,
            assignedAccountManagerProducerId: data.PCIS_ID,
            // Need to change later
            platform: platform ? platform.toUpperCase() : "CIRRUS"
        }
        finalSalesRepData.push(salesRepData)
    })
    return finalSalesRepData
}

export const saveSalesRepInDB = (st, platform) => {
    let finalSalesRepData = formatSalesRepRenewals(st.salesRep, platform)
    return finalSalesRepData;
}
export const formatGaSalesRepRenewals = (gaSalesRep, platform) => {
    let finalSalesRepData = [];
    gaSalesRep && gaSalesRep.map(data => {
        const gaSalesRepData = {
            salesRepFirstName: data.salesRepNameFN || data.salesRepName,
            salesRepLastName: data.salesRepNameLN,
            platform: platform ? platform.toUpperCase() : "CIRRUS"
        }
        finalSalesRepData.push(gaSalesRepData)
    })
    return finalSalesRepData
}

export const saveGaSalesRepInDB = (st, platform) => {

    let finalGaSalesRepData = formatGaSalesRepRenewals(st.gaSalesRep, platform)

    return finalGaSalesRepData;
}


export const updateCaseSubmitOnReneAsIs = (res, email, customerNumber, quoteTrackingNum, store = {}) => {
    const { _id, ...restQuery } = res
    const query = {
        ...restQuery,
        caseSubmitted: true,
        notificationEmail: email,
        memberGroupId: customerNumber,
        quoteTrackingNumber: quoteTrackingNum
    }

    if (isUhcRenewals()) {
        query.isMMRCase = isMmr();
        if (!query.isMMRCase) {
            delete query.renewalData.Ibr
            delete query.renewalData.members
        }
        query.employeesEnrollStatus = 'Completed';
        let medicalPlans = [];
        if (store && store.rules) {
            const { enrollment: { enrolledPlans } } = store.rules;
            medicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
            query.enrolledPlans = medicalPlans;
            query.enrolledDentalPlans = store.rules.enrollment.enrolledDentalPlans || [];
            query.enrolledVisionPlans = store.rules.enrollment.enrolledVisionPlans || [];
            query.enrolledLifePlans = store.rules.enrollment.enrolledLifePlans || [];
            query.enrolledDependentLifePlans = store.rules.enrollment.enrolledDependentLifePlans || [];
        }
    }
    return query;
};

export const findDepLifeCov = (isChildExist, isSpouceExist) => {
    if (isChildExist && isSpouceExist) {
        return 'FAM'
    }
    if (isChildExist) {
        return 'ECH'
    }
    if (isSpouceExist) {
        return 'ESP'
    }
    return 'EMP'
}

export const formatIBRMembersReconciled = (renewalData) => {

    const Ibr = renewalData.Ibr ? renewalData.Ibr : renewalData.ibr ? renewalData.ibr : null
    const dentalPlans = []
    const visionPlans = []
    const lifePlans = []
    const depLifePlans = []

    const planOfferingData = Ibr.quote.planOfferingData;
    for (const planOfferings of planOfferingData) {
        for (const item in planOfferings) {
            if (item === 'existingPlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_SPOUSE' || plan.productLineType === 'BASIC_LIFE_CHILD') depLifePlans.push(plan.planID)
                })
            }
            if (item === 'alternatePlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                })
            }
        }
    }

    const covTypes = {
        'EMP': 'Employee Only',
        'ESP': 'Employee With Spouse',
        'ECH': 'Employee With Child',
        'FAM': 'Employee With Family',
        'EO': 'Employee Only',
        'ES': 'Employee With Spouse',
        'EC': 'Employee With Child',
        'EF': 'Employee With Family',
        '': '',
        'WAV': 'Waived'
    }

    const covTypesUHC = {
        'EMP': 'EE',
        'ESP': 'EE/SP',
        'ECH': 'EE/CH',
        'FAM': 'EE/FAM',
        'EO': 'EE',
        'ES': 'EE/SP',
        'EC': 'EE/CH',
        'EF': 'EE/FAM',
        '': '',
        'WAV': 'WAV'
    }

    const depTypeMapping = {
        'C': 'Child',
        'S': 'Spouse'
    }

    const effectiveDate = isoStringToMMDDYYYY(renewalData.memberGroup.renewalEffectiveDate);
    let members = [];

    if (Ibr && Ibr.quote && Ibr.quote.census && Ibr.quote.census.employee && Ibr.quote.census.employee.length > 0) {
        Ibr.quote.census.employee.forEach((member) => {
            let isChildExist = false
            let isSpouceExist = false
            let memberDOB = member.dateOfBirth.month + '/' + member.dateOfBirth.day + '/' + member.dateOfBirth.year
            let empData = {
                employeeId: member.xref,
                age: getAgeFromDOB(memberDOB, effectiveDate),
                dependents: [],
                dob: memberDOB,
                firstName: member.first ? member.first : '',
                middleName: member.middle ? member.middle : '',
                middleInitial: member.middle ? member.middle : '',
                lastName: member.last ? member.last : '',
                gender: member.genderType ? member.genderType === 'M' ? 'M' : 'F' : '',
                zipCode: member.homeZip ? member.homeZip : '',
                contactInfo: {
                    streetAddress: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: member.clientDefinedData.data.find(({ key }) => key === 'State') ? member.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: member.clientDefinedData.data.find(({ key }) => key === 'Zip') ? member.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    zipCode4: '',
                },
                status: member.status ? member.status : 'Active',
                employeeStatus: member.status ? member.status : 'Active',
                salary: member.salary ? member.salary : '',
                smoker: member.smoker ? member.smoker : '', //
                classId: member.classId ? member.classId : '', //
                ssn: member.ssn ? member.ssn : '',
                socialSecurityNumber: member.ssn ? member.ssn : '',
            };

            let dependents = [];
            let memberDependents = member.dependents && member.dependents.dependent && member.dependents.dependent.length > 0 ? member.dependents.dependent : null

            if (memberDependents) {
                memberDependents.forEach((dep) => {
                    const depDOB = dep.dateOfBirth.month + '/' + dep.dateOfBirth.day + '/' + dep.dateOfBirth.year
                    let depDetails = {
                        relationship: dep.depType ? depTypeMapping[dep.depType] : '',
                        age: getAgeFromDOB(depDOB, effectiveDate),
                        firstName: dep.first ? dep.first : '',
                        middleName: dep.middle ? dep.middle : '',
                        middleInitial: dep.middle ? dep.middle : '',
                        lastName: dep.last ? dep.last : '',
                        suffix: '',
                        dob: depDOB,
                        gender: dep.genderType ? dep.genderType === 'M' ? 'Male' : 'Female' : '',
                        streetAddress: empData.contactInfo.streetAddress ? empData.contactInfo.streetAddress : '',
                        streetAddressln2: empData.contactInfo.streetAddressln2 ? empData.contactInfo.streetAddressln2 : '',
                        city: empData.contactInfo.city ? empData.contactInfo.city : '',
                        state: empData.contactInfo.state ? empData.contactInfo.state : '',
                        zipCode: empData.contactInfo.zipCode ? empData.contactInfo.zipCode : '',
                        zipCode4: '',
                        smoker: dep.smoker ? dep.smoker : '',
                        employeeId: empData.xref,
                        ssn: dep.ssn ? dep.ssn : '',
                        socialSecurityNumber: dep.ssn ? dep.ssn : '',
                    };
                    let depLifeElectedPlan = dep.coverageElections.plan.find(plan => depLifePlans.includes(plan.planID))
                    if (dep.depType === 'C' && depLifeElectedPlan?.coverageType !== 'WAV') {
                        isChildExist = true
                    } else if (dep.depType === 'S' && depLifeElectedPlan?.coverageType !== 'WAV') {
                        isSpouceExist = true
                    }
                    dependents.push(depDetails);
                });
            }

            let dentalElectedPlan, visionElectedPlan, dentalAlternatePlan, visionAlternatePlan, lifeElectedPlan, lifeAlternatePlan
            if (member.electedPlans && member.electedPlans.plan && member.electedPlans.plan.length > 0) {
                member.electedPlans.plan.map(plan => {
                    if (dentalPlans.includes(plan.planID)) dentalElectedPlan = plan
                    if (visionPlans.includes(plan.planID)) visionElectedPlan = plan
                    if (lifePlans.includes(plan.planID)) lifeElectedPlan = plan
                })
            }
            if (member.alternatePlans && member.alternatePlans.plans && member.alternatePlans.plans.length > 0) {
                dentalAlternatePlan = member.alternatePlans.plan.find(plan => dentalPlans.includes(plan.planID))
                visionAlternatePlan = member.alternatePlans.plan.find(plan => visionPlans.includes(plan.planID))
                lifeAlternatePlan = member.alternatePlans.plans.find(plan => (lifePlans.map((x) => x.code)).includes(plan.planID))
            }

            let dentalCov = dentalElectedPlan && dentalElectedPlan.coverageType ? dentalElectedPlan.coverageType :
                dentalAlternatePlan && dentalAlternatePlan.coverageType ? dentalAlternatePlan.coverageType : ''
            let visionCov = visionElectedPlan && visionElectedPlan.coverageType ? visionElectedPlan.coverageType :
                visionAlternatePlan && visionAlternatePlan.coverageType ? visionAlternatePlan.coverageType : ''
            let lifeCov = lifeElectedPlan && lifeElectedPlan.coverageType ? lifeElectedPlan.coverageType :
                lifeAlternatePlan && lifeAlternatePlan.coverageType ? lifeAlternatePlan.coverageType : ''
            let depLifeCov = findDepLifeCov(isChildExist, isSpouceExist)

            let coverageLevelArray = [dentalCov, visionCov, lifeCov]
            let highestCov = findHighestCoverage(coverageLevelArray)
            if (isUhcRenewals() && dentalCov == "" && visionCov == "" && lifeCov == "") {
                highestCov = findDepLifeCov(isChildExist, isSpouceExist)
            }
            empData.coverages = {
                medical: 'EE',
                dental: covTypes[highestCov],
                vision: covTypes[highestCov],
                life: covTypes[highestCov],
                basicLife: covTypesUHC[depLifeCov],
            }
            empData.dependents = dependents;
            members.push(empData);

        })
    }
    let i = 0;
    if (renewalData.members) {
        renewalData.members.forEach((member) => {
            if (member.relationship === 'Self' && !members.find(emp => (emp.ssn && member.ssn && emp.ssn === member.ssn) || (emp.firstName.toLowerCase() === member.firstName.toLowerCase() && emp.lastName.toLowerCase() === member.lastName.toLowerCase()))) {
                if (member.relationship === 'Self') {
                    const dob = isoStringToMMDDYYYY(member.birthDate);

                    const empData = {
                        employeeId: member.employeeId,
                        age: member.age ? parseInt(member.age) : getAgeFromDOB(dob, effectiveDate),
                        coverages: {
                            medical: covTypes[member.coverageLevel] ? covTypes[member.coverageLevel] : '',
                            dental: covTypes[member.coverageLevel] ? covTypes[member.coverageLevel] : '',
                            vision: covTypes[member.coverageLevel] ? covTypes[member.coverageLevel] : '',
                            life: covTypes[member.coverageLevel] ? covTypes[member.coverageLevel] : '',
                        },
                        dependents: [],
                        dob,
                        employeeStatus: member.status ? member.status : 'Active',
                        firstName: member.firstName ? member.firstName : '',
                        middleName: member.middleName ? member.middleName : '',
                        middleInitial: member.middleInitial ? member.middleInitial : '',
                        lastName: member.lastName ? member.lastName : '',
                        gender: member.gender ? member.gender === 'M' ? 'Male' : 'Female' : '',
                        zipCode: member.zipCode ? member.zipCode : '',
                        contactInfo: {
                            streetAddress: member.address1 ? member.address1 : '',
                            streetAddressln2: member.address2 ? member.address2 : '',
                            city: member.city ? member.city : '',
                            state: member.state ? member.state : '',
                            zipCode: member.zipCode ? member.zipCode : '',
                            zipCode4: '',
                        },
                        status: member.status ? member.status : 'Active',
                        employeeStatus: member.status ? member.status : 'Active',
                        salary: member.salary ? member.salary : '',
                        smoker: member.smoker ? member.smoker : '',
                        classId: member.classId ? member.classId : '',
                    };

                    const dependents = [];

                    renewalData.members.forEach((dep) => {
                        if ((dep.relationship == 'Spouse' || dep.relationship == 'Life Partner' || dep.relationship == 'Child' || dep.relationship == 'Domestic Partner' || dep.relationship == 'Civil Union Partner' || dep.relationship == 'Dependent Child') && member.employeeId === dep.employeeId) {
                            const depDOB = isoStringToMMDDYYYY(dep.birthDate);

                            const depDetails = {
                                relationship: dep.relationship ? dep.relationship : '',
                                age: dep.age ? parseInt(dep.age) : getAgeFromDOB(depDOB, effectiveDate),
                                firstName: dep.firstName ? dep.firstName : '',
                                middleName: dep.middleName ? dep.middleName : '',
                                middleInitial: dep.middleInitial ? dep.middleInitial : '',
                                lastName: dep.lastName ? dep.lastName : '',
                                suffix: '',
                                dob: depDOB,
                                gender: dep.gender ? dep.gender === 'M' ? 'Male' : 'Female' : '',
                                streetAddress: dep.address1 ? dep.address1 : '',
                                streetAddressln2: dep.address2 ? dep.address2 : '',
                                city: dep.city ? dep.city : '',
                                state: dep.state ? dep.state : '',
                                zipCode: dep.zipCode ? dep.zipCode : '',
                                zipCode4: '',
                                smoker: dep.smoker ? dep.smoker : '',
                                employeeId: member.employeeId,
                            };

                            dependents.push(depDetails);
                        }
                    });

                    empData.dependents = dependents;
                    members.push(empData);
                }
            } else if (member.relationship === 'Self') {
                let memberIndex = members.findIndex(emp => emp.ssn === member.ssn || !(emp.firstName === member.firstName && emp.lastName === member.lastName && emp.dob === member.dob))
                if (memberIndex) {
                    members[memberIndex].coverages = {
                        medical: member.coverages.medical,
                        dental: findMaxCoverage(member.coverages.dental, members[memberIndex].coverages.dental),
                        vision: findMaxCoverage(member.coverages.vision, members[memberIndex].coverages.vision),
                        life: findMaxCoverage(member.coverages.life, members[memberIndex].coverages.life),
                    }
                }
            }
        });
    }
    return members;
};

const findCommonMaxCoverage = (medCov, ibrCov) => {
    if (!medCov)
        return ibrCov
    else {
        switch (medCov) {
            case 'EE':
                return ibrCov
            case 'EE/SP':
                if (ibrCov === 'EE' || ibrCov === 'EE/CH')
                    return 'EE/SP'
                else
                    return ibrCov
            case 'EE/CH':
                if (ibrCov === 'EE')
                    return 'EE/CH'
                else
                    return ibrCov
            case 'EE/FAM':
                return 'EE/FAM'
        }
    }

}

export const formatIBRMembersMapping = (renewalData) => {

    const Ibr = renewalData.Ibr ? renewalData.Ibr : renewalData.ibr ? renewalData.ibr : null
    const dentalPlans = []
    const visionPlans = []
    const lifePlans = []

    const planOfferingData = Ibr.quote.planOfferingData;
    for (const planOfferings of planOfferingData) {
        for (const item in planOfferings) {
            if (item === 'existingPlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                })
            }
            if (item === 'alternatePlanPkg') {
                planOfferings[item].plans.plan.map(plan => {
                    if (plan.productLineType === 'DENTAL') dentalPlans.push(plan.planID)
                    if (plan.productLineType === 'VISION') visionPlans.push(plan.planID)
                    if (plan.productLineType === 'BASIC_LIFE_EE') lifePlans.push(plan.planID)
                })
            }
        }
    }

    const coverageMapping = {
        "EO": "EE",
        "EC": "EE/CH",
        "ES": "EE/SP",
        "EF": "EE/FAM",
        "W": "W",
        '': ''
    }
    const covTypes = {
        'EMP': 'EE',
        'ESP': 'EE/SP',
        'ECH': 'EE/CH',
        'FAM': 'EE/FAM',
        'WAV': 'W',
        '': ''
    }

    const depTypeMapping = {
        'C': 'Child',
        'S': 'Spouse'
    }

    const effectiveDate = isoStringToMMDDYYYY(renewalData.memberGroup.renewalEffectiveDate);
    let members = [];

    if (Ibr && Ibr.quote && Ibr.quote.census && Ibr.quote.census.employee && Ibr.quote.census.employee.length > 0) {
        Ibr.quote.census.employee.forEach((member) => {
            let isChildExist = false
            let isSpouceExist = false
            let memberDOB = member.dateOfBirth.month + '/' + member.dateOfBirth.day + '/' + member.dateOfBirth.year
            let empData = {
                employeeId: member.xref,
                age: getAgeFromDOB(memberDOB, effectiveDate),
                dependents: [],
                dob: memberDOB,
                firstName: member.first ? member.first : '',
                middleName: member.middle ? member.middle : '',
                middleInitial: member.middle ? member.middle : '',
                lastName: member.last ? member.last : '',
                gender: member.genderType ? member.genderType === 'M' ? 'M' : 'F' : '',
                zipCode: member.homeZip ? member.homeZip : '',
                contactInfo: {
                    streetAddress: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    streetAddressln2: '',
                    city: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: member.clientDefinedData.data.find(({ key }) => key === 'State') ? member.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: member.clientDefinedData.data.find(({ key }) => key === 'Zip') ? member.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                    zipCode4: '',
                },
                status: member.status ? member.status : 'Active',
                employeeStatus: member.status ? member.status : 'Active',
                salary: member.salary ? member.salary : '',
                smoker: member.smoker ? member.smoker : '', //
                classId: member.classId ? member.classId : '', //
                ssn: member.ssn ? member.ssn : '',
                socialSecurityNumber: member.ssn ? member.ssn : '',
            };

            let dependents = [];
            let memberDependents = member.dependents && member.dependents.dependent && member.dependents.dependent.length > 0 ? member.dependents.dependent : null

            if (memberDependents) {
                memberDependents.forEach((dep) => {
                    const depDOB = dep.dateOfBirth.month + '/' + dep.dateOfBirth.day + '/' + dep.dateOfBirth.year
                    let depDetails = {
                        relationship: dep.depType ? depTypeMapping[dep.depType] : '',
                        age: getAgeFromDOB(depDOB, effectiveDate),
                        firstName: dep.first ? dep.first : '',
                        middleName: dep.middle ? dep.middle : '',
                        middleInitial: dep.middle ? dep.middle : '',
                        lastName: dep.last ? dep.last : '',
                        suffix: '',
                        dob: depDOB,
                        gender: dep.genderType ? dep.genderType === 'M' ? 'M' : 'F' : '',
                        streetAddress: empData.contactInfo.streetAddress ? empData.contactInfo.streetAddress : '',
                        streetAddressln2: empData.contactInfo.streetAddressln2 ? empData.contactInfo.streetAddressln2 : '',
                        city: empData.contactInfo.city ? empData.contactInfo.city : '',
                        state: empData.contactInfo.state ? empData.contactInfo.state : '',
                        zipCode: empData.contactInfo.zipCode ? empData.contactInfo.zipCode : '',
                        zipCode4: '',
                        smoker: dep.smoker ? dep.smoker : '',
                        employeeId: empData.xref,
                        ssn: dep.ssn ? dep.ssn : '',
                        socialSecurityNumber: dep.ssn ? dep.ssn : '',
                    };
                    if (dep.depType === 'C') {
                        isChildExist = true
                    } else if (dep.depType === 'S') {
                        isSpouceExist = true
                    }
                    dependents.push(depDetails);
                });
            }

            let dentalElectedPlan, visionElectedPlan, dentalAlternatePlan, visionAlternatePlan, lifeElectedPlan, lifeAlternatePlan
            if (member.electedPlans && member.electedPlans.plan && member.electedPlans.plan.length > 0) {
                member.electedPlans.plan.map(plan => {
                    if (dentalPlans.includes(plan.planID)) dentalElectedPlan = plan
                    if (visionPlans.includes(plan.planID)) visionElectedPlan = plan
                    if (lifePlans.includes(plan.planID)) lifeElectedPlan = plan
                })
            }
            if (member.alternatePlans && member.alternatePlans.plans && member.alternatePlans.plans.length > 0) {
                dentalAlternatePlan = member.alternatePlans.plan.find(plan => dentalPlans.includes(plan.planID))
                visionAlternatePlan = member.alternatePlans.plan.find(plan => visionPlans.includes(plan.planID))
                lifeAlternatePlan = member.alternatePlans.plans.find(plan => (lifePlans.map((x) => x.code)).includes(plan.planID))
            }

            let dentalCov = dentalElectedPlan && dentalElectedPlan.coverageType ? dentalElectedPlan.coverageType :
                dentalAlternatePlan && dentalAlternatePlan.coverageType ? dentalAlternatePlan.coverageType : ''
            let visionCov = visionElectedPlan && visionElectedPlan.coverageType ? visionElectedPlan.coverageType :
                visionAlternatePlan && visionAlternatePlan.coverageType ? visionAlternatePlan.coverageType : ''
            let lifeCov = lifeElectedPlan && lifeElectedPlan.coverageType ? lifeElectedPlan.coverageType :
                lifeAlternatePlan && lifeAlternatePlan.coverageType ? lifeAlternatePlan.coverageType : ''

            let coverageLevelArray = [dentalCov, visionCov, lifeCov]
            let highestCov = findHighestCoverage(coverageLevelArray)
            if (isUhcRenewals() && dentalCov == "" && visionCov == "" && lifeCov == "") {
                highestCov = findDepLifeCov(isChildExist, isSpouceExist)
            }

            empData.coverages = {
                medical: 'EE',
                dental: covTypes[highestCov],
                vision: covTypes[highestCov],
                life: covTypes[highestCov],
            }

            empData.dependents = dependents;
            members.push(empData);

        })
    }
    if (renewalData.members) {
        const isUhcRenewal = isUhcRenewals();
        renewalData.members.forEach((member) => {
            if (member.relationship === 'Self' && !members.find(emp => (emp.ssn && member.ssn && emp.ssn === member.ssn) || (emp.firstName.toLowerCase() === member.firstName.toLowerCase() && emp.lastName.toLowerCase() === member.lastName.toLowerCase()))) {
                if (member.relationship === 'Self') {
                    const dob = isoStringToMMDDYYYY(member.birthDate);

                    const empData = {
                        employeeId: member.employeeId,
                        age: member.age ? parseInt(member.age) : getAgeFromDOB(dob, effectiveDate),
                        coverages: {
                            medical: coverageMapping[member.coverageLevel] ? coverageMapping[member.coverageLevel] : isUhcRenewal ? '' : 'W',
                            dental: coverageMapping[member.coverageLevel] ? coverageMapping[member.coverageLevel] : isUhcRenewal ? '' : 'W',
                            vision: coverageMapping[member.coverageLevel] ? coverageMapping[member.coverageLevel] : isUhcRenewal ? '' : 'W',
                            life: coverageMapping[member.coverageLevel] ? coverageMapping[member.coverageLevel] : isUhcRenewal ? '' : 'W',
                        },
                        dependents: [],
                        dob,
                        employeeStatus: member.status ? member.status : 'Active',
                        firstName: member.firstName ? member.firstName : '',
                        middleName: member.middleName ? member.middleName : '',
                        middleInitial: member.middleInitial ? member.middleInitial : '',
                        lastName: member.lastName ? member.lastName : '',
                        gender: member.gender ? member.gender === 'M' ? 'M' : 'F' : '',
                        zipCode: member.zipCode ? member.zipCode : '',
                        contactInfo: {
                            streetAddress: member.address1 ? member.address1 : '',
                            streetAddressln2: member.address2 ? member.address2 : '',
                            city: member.city ? member.city : '',
                            state: member.state ? member.state : '',
                            zipCode: member.zipCode ? member.zipCode : '',
                            zipCode4: '',
                        },
                        status: member.status ? member.status : 'Active',
                        employeeStatus: member.status ? member.status : 'Active',
                        salary: member.salary ? member.salary : '',
                        smoker: member.smoker ? member.smoker : '',
                        classId: member.classId ? member.classId : '',
                    };

                    const dependents = [];

                    renewalData.members.forEach((dep) => {
                        if ((dep.relationship == 'Spouse' || dep.relationship == 'Life Partner' || dep.relationship == 'Child' || dep.relationship == 'Domestic Partner' || dep.relationship == 'Civil Union Partner' || dep.relationship == 'Dependent Child') && member.employeeId === dep.employeeId) {
                            const depDOB = isoStringToMMDDYYYY(dep.birthDate);

                            const depDetails = {
                                relationship: dep.relationship ? dep.relationship : '',
                                age: dep.age ? parseInt(dep.age) : getAgeFromDOB(depDOB, effectiveDate),
                                firstName: dep.firstName ? dep.firstName : '',
                                middleName: dep.middleName ? dep.middleName : '',
                                middleInitial: dep.middleInitial ? dep.middleInitial : '',
                                lastName: dep.lastName ? dep.lastName : '',
                                suffix: '',
                                dob: depDOB,
                                gender: dep.gender ? dep.gender === 'M' ? 'M' : 'F' : '',
                                streetAddress: dep.address1 ? dep.address1 : '',
                                streetAddressln2: dep.address2 ? dep.address2 : '',
                                city: dep.city ? dep.city : '',
                                state: dep.state ? dep.state : '',
                                zipCode: dep.zipCode ? dep.zipCode : '',
                                zipCode4: '',
                                smoker: dep.smoker ? dep.smoker : '',
                                employeeId: member.employeeId,
                            };

                            dependents.push(depDetails);
                        }
                    });

                    empData.dependents = dependents;
                    members.push(empData);
                }
            } else if (member.relationship === 'Self') {
                let memberIndex = members.findIndex(emp => emp.ssn === member.ssn || !(emp.firstName === member.firstName && emp.lastName === member.lastName && emp.dob === member.dob))
                if (memberIndex) {
                    members[memberIndex].coverages = {
                        medical: member.coverages.medical,
                        dental: findCommonMaxCoverage(member.coverages.dental, members[memberIndex].coverages.dental),
                        vision: findCommonMaxCoverage(member.coverages.vision, members[memberIndex].coverages.vision),
                        life: findCommonMaxCoverage(member.coverages.life, members[memberIndex].coverages.life),
                    }
                }
            }
        });
    }
    return members;
};


export const getAllSelectedPlans = (page, collection = {}, store = {}) => {

    let selectedMedicalPlans = []
    let selectedDentalPlans = []
    let selectedVisionPlans = []
    let selectedLifePlans = []
    let selectedStdPlans = [];
    let selectedLtdPlans = [];
    let selectedSuppLifePlans = [];

    const marketType = getMarketType();
    // Medical
    let medicalPlans = []
    if (marketType === MARKET_TYPES.UHC) {
        medicalPlans = collection && collection.medical && collection.medical.selectedPlans && collection.medical.selectedPlans.length > 0 ? collection.medical.selectedPlans : []
    } else {
        let renewalPlans = collection && collection.renewalData && collection.renewalData.renewalPlanMaps && collection.renewalData.renewalPlanMaps.length > 0 ? FilterRenewalsMedicalData(collection.renewalData, collection.customPlanNames) : []

        if (renewalPlans.length > 0) {
            renewalPlans.map(set => {
                if (set.renewing && set.renewing.selected === true)
                    medicalPlans.push(set.renewing)
            })
        }
    }
    let medicalShopPlans = collection && collection.shoppingPlans && collection.shoppingPlans.length > 0 ? collection.shoppingPlans : []

    // Dental
    let dentalPlans = collection && collection.dental && collection.dental.selectedPlans && collection.dental.selectedPlans.length > 0 ? collection.dental.selectedPlans : []
    let dentalShopPlans = collection && collection.shoppingDentalPlans && collection.shoppingDentalPlans.length > 0 ? collection.shoppingDentalPlans : []

    // Vision
    let visionPlans = collection && collection.vision && collection.vision.selectedPlans && collection.vision.selectedPlans.length > 0 ? collection.vision.selectedPlans : []
    let visionShopPlans = collection && collection.shoppingVisionPlans && collection.shoppingVisionPlans.length > 0 ? collection.shoppingVisionPlans : []

    // Life
    let lifePlans = collection && collection.life && collection.life.selectedPlans && collection.life.selectedPlans.length > 0 ? collection.life.selectedPlans : []
    let lifeShopPlans = collection && collection.shoppingLifePlans && collection.shoppingLifePlans.length > 0 ? collection.shoppingLifePlans : []

    if(isUhcRenewals() || isOxfordRenewals()) {
        if (collection.std && collection.std.selectedPlans && collection.std.selectedPlans.length > 0) {
            selectedStdPlans = collection.std.selectedPlans;
        }
        if (collection.ltd && collection.ltd.selectedPlans && collection.ltd.selectedPlans.length > 0) {
            selectedLtdPlans = collection.ltd.selectedPlans;
        }
         if (collection.suppLife && collection.suppLife.selectedPlans && collection.suppLife.selectedPlans.length > 0) {
            selectedSuppLifePlans = collection.suppLife.selectedPlans;
        }

    }
    if (page === 'review') {

        let additionalBool = false;
        medicalShopPlans.length > 0 && medicalShopPlans.map((shopPlan) => {
            if (!shopPlan.hasOwnProperty('renewalPlan') && !shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
        });
        if (!additionalBool) {
            selectedMedicalPlans = medicalPlans;
        } else {
            medicalShopPlans.length > 0 && medicalShopPlans.map((shopPlan, index) => {
                if (!shopPlan.hasOwnProperty('renewalPlan') && !shopPlan.hasOwnProperty('renewalPlanType')) {
                    selectedMedicalPlans.push(shopPlan);
                } else {
                    let checkRenewalPlan = medicalPlans.find(plan => plan.code === shopPlan.code)
                    if (checkRenewalPlan && checkRenewalPlan.selected === true) {
                        selectedMedicalPlans.push(shopPlan)
                    }
                }
            });
        }

        if (collection.dental && collection.dental.selectedPlans && collection.dental.selectedPlans.length > 0) {
            selectedDentalPlans = collection.dental.selectedPlans;
        }
        if (collection.vision && collection.vision.selectedPlans && collection.vision.selectedPlans.length > 0) {
            selectedVisionPlans = collection.vision.selectedPlans;
        }
        if (collection.life && collection.life.selectedPlans && collection.life.selectedPlans.length > 0) {
            selectedLifePlans = collection.life.selectedPlans;
        }

    } else if (page === 'decide') {

        let count = 0
        if (collection && collection.shoppingPlans) {
            collection.shoppingPlans.map((plan) => {
                if (plan && !plan.renewalPlan) {
                    count += 1;
                }
            });
        }
        if (count > 0) {
            selectedMedicalPlans = medicalShopPlans
        } else {
            selectedMedicalPlans = medicalPlans
        }

        let shopPlanFlag = false;
        let finalDentalPlans = []
        let finalVisionPlans = []
        let finalLifePlans = []

        if (dentalShopPlans && dentalShopPlans.length > 0 || visionShopPlans && visionShopPlans.length > 0 || collection.shoppingLifePlans.length > 0) {
            finalDentalPlans = dentalShopPlans || []
            finalVisionPlans = visionShopPlans || []
            finalLifePlans = lifeShopPlans || []

            if (collection.dental) {
                if (collection.dental.selectedPlans) {
                    collection.dental.selectedPlans.map(dentalAlternate => {
                        let planExist = false
                        finalDentalPlans.map(dentalPlans => {
                            if (dentalAlternate.code === dentalPlans.code)
                                planExist = true
                        })
                        if (!planExist)
                            finalDentalPlans.push(dentalAlternate)
                    })
                }
            }
            if (collection.vision) {
                if (collection.vision.selectedPlans) {
                    collection.vision.selectedPlans.map(visionAlternate => {
                        let planExist = false
                        finalVisionPlans.map(visionPlans => {
                            if (visionAlternate.code === visionPlans.code)
                                planExist = true
                        })
                        if (!planExist)
                            finalVisionPlans.push(visionAlternate)
                    })
                }
            }
            if (collection.life) {
                if (collection.life.selectedPlans) {
                    collection.life.selectedPlans.map(lifeAlternate => {
                        let planExist = false
                        finalLifePlans.map(lifePlans => {
                            if (lifeAlternate.code === lifePlans.code)
                                planExist = true
                        })
                        if (!planExist)
                            finalLifePlans.push(lifeAlternate)
                    })
                }
            }

            shopPlanFlag = true;
        }

        selectedDentalPlans = shopPlanFlag ? finalDentalPlans : dentalPlans
        selectedVisionPlans = shopPlanFlag ? finalVisionPlans : visionPlans
        selectedLifePlans = shopPlanFlag ? finalLifePlans : lifePlans

    } else if (page === 'ren-shopping') {

        if (store.renewalShopping.selectedPlans && store.renewalShopping.selectedPlans.length > 0) {

            let count = 0

            store.renewalShopping.selectedPlans.map((plan) => {
                if (plan && !plan.renewalPlan) {
                    count += 1;
                }
            });

            if (count > 0) {
                selectedMedicalPlans = store.renewalShopping.selectedPlans
            } else {
                selectedMedicalPlans = medicalPlans
            }

            //selectedMedicalPlans = store.renewalShopping.selectedPlans;
        }
        if (store.renewalShoppingHelper.dental && store.renewalShoppingHelper.dental.selectedPlans && store.renewalShoppingHelper.dental.selectedPlans.length > 0) {
            selectedDentalPlans = store.renewalShoppingHelper.dental.selectedPlans;
        }
        if (store.renewalShoppingHelper.vision.selectedPlans && store.renewalShoppingHelper.vision.selectedPlans.length > 0) {
            selectedVisionPlans = store.renewalShoppingHelper.vision.selectedPlans;
        }
        if (store.renewalShoppingHelper.life.selectedPlans && store.renewalShoppingHelper.life.selectedPlans.length > 0) {
            selectedLifePlans = store.renewalShoppingHelper.life.selectedPlans;
        }

    }

    return [selectedMedicalPlans, selectedDentalPlans, selectedVisionPlans, selectedLifePlans,selectedStdPlans,selectedLtdPlans,selectedSuppLifePlans]

}

export const getSelectedPlansFromIbrSets = sets => {
    let selectedPlans = []
    for (let set of sets) {
        for (let plan of set) {
            if (plan.renewalPlanType === 'renewing') {
                plan['selected'] = true;
                plan.selectedPackages = [];
                if (isUhcRenewals()) {
                    if (plan.packages && plan.packages.length > 0) {
                        plan.selectedPackages.push(plan.packages[0])
                    }
                } else {
                    plan.selectedPackages.push('Single');
                }
                selectedPlans.push(plan)
            }
        }
    }
    return selectedPlans
}


export const updateShoppingPlansAccToEnrolledPlans = (shoppingPlans, enrolledPlans) => {
    const updatedPlans = (shoppingPlans || []).map(shopPlan => {
        const matchingEnrollPlan = (enrolledPlans || []).find(plan => plan.code === shopPlan.code)
        if (matchingEnrollPlan) {
            let { selected, ...restOfMatchingPlan } = matchingEnrollPlan;
            return {
                ...shopPlan,
                ...restOfMatchingPlan
            }
        } else return shopPlan
    })
    return updatedPlans
}

export const updateShoppingPlansAccToEnrolledPlansUhc = (shoppingPlans, enrolledPlans) => {
    const updatedPlans = (shoppingPlans || []).map(shopPlan => {
        const matchingEnrollPlan = (enrolledPlans || []).find(plan => {
            let shopPlanPackages = shopPlan.selectedPackages, enrolledPlanPackages = plan.selectedPackages;
            let unMatchedPackage = shopPlanPackages.filter(item => !enrolledPlanPackages.includes(item));
            if (plan.code !== shopPlan.code || unMatchedPackage.length !== 0) return false;
            else return true;
        })
        if (matchingEnrollPlan) {
            let { selected, ...restOfMatchingPlan } = matchingEnrollPlan;
            return {
                ...shopPlan,
                ...restOfMatchingPlan
            }
        } else return shopPlan
    })
    return updatedPlans
}
