import { MMDDYYYYtoIsoString, isoStringToMMDDYYYY, convertDateString } from '../formatters/dates';
import { formComparePlanObject } from '../../components/activities/Renewal/RenewalsCommonComponents/ComparePlans/RenewalComparePlans';
import { identifyPlatform } from '../../components/activities/Renewal/Review/reviewUtils';
import setCoverages from './proposalUtils/setCoverages';
import { MARKET_TYPES, getMarketType, isOxfordRenewals, isUhcRenewals } from '../businessLogic/platformUtils'

const deepCopy = require('../deepCopy');

export const extractSalesRep = (state) => {
    const salesRep = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment
        && state.reviewCards.ownerAssignment.salesRep) {
        state.reviewCards.ownerAssignment.salesRep.forEach((sales) => {
            const data = {
                name: sales ? sales.salesRepName ? sales.salesRepName : 'N/A' : 'N/A',
                phone: 'N/A',
            };
            salesRep.push(data);
        });
    }
    return salesRep;
};
export const extractBrokers = (state) => {
    const producer = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.producer) {
        state.reviewCards.ownerAssignment.producer.map((prod, i) => {
            const data = {
                name: prod ? prod.producerName ? prod.producerName : 'N/A' : 'N/A',
                phone: prod ? prod.producerPhone ? prod.producerPhone : 'N/A' : 'N/A',
            };
            producer.push(data);
        });
    }
    return producer;
};
export const extractGeneralAgent = (state) => {
    const generalAgent = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.generalAgent) {
        state.reviewCards.ownerAssignment.generalAgent.map((agent, i) => {
            const data = {
                name: agent ? agent.generalAgentName ? agent.generalAgentName : 'N/A' : 'N/A',
                phone: 'N/A',
            };
            generalAgent.push(data);
        });
    }
    return generalAgent;
};
export const extractAgency = (state) => {
    const agencies = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.agency) {
        state.reviewCards.ownerAssignment.agency.map((agency, i) => {
            const data = {
                name: agency ? agency.agencyName ? agency.agencyName : 'N/A' : 'N/A',
                phone: agency ? agency.agencyPhone ? agency.agencyPhone : 'N/A' : 'N/A',
            };
            agencies.push(data);
        });
    }
    return agencies;
};


export const getCovergaes = (coverage) => {
    switch(coverage){
        case 'EE':
            return 'EO';
        case 'EE/CH':
            return 'EC';
        case 'EE/SP':
            return 'ES';           
        case 'EE/FAM':
            return 'EF';
        default:
            return coverage;

    }
};

export const findCommonPlans = (state) => {
    let finalDentalPlans; let finalVisionPlans; let
        finalLifePlans;
    const commonPlansArray = {
        dentalPlans: [],
        visionPlans: [],
        lifePlans: [],
    };
    if (state.decide.shoppingDentalPlans || state.decide.shoppingVisionPlans || state.decide.shoppingLifePlans) {
        finalDentalPlans = state.decide.shoppingDentalPlans ? [...state.decide.shoppingDentalPlans] : [];
        finalVisionPlans = state.decide.shoppingVisionPlans ? [...state.decide.shoppingVisionPlans] : [];
        finalLifePlans = state.decide.shoppingLifePlans ? [...state.decide.shoppingLifePlans] : [];
        if (state.decide.dental) {
            if (state.decide.dental.selectedPlans) {
                state.decide.dental.selectedPlans.map((dentalAlternate) => {
                    let planExist = false;
                    finalDentalPlans.map((dentalPlans, index)  => {
                        if (dentalAlternate.code === dentalPlans.code) {
                            planExist = true;
                            finalDentalPlans[index] = { //// added for Rate Override, as state.dental contains overriden rates
                                ...dentalAlternate,
                                ...dentalPlans
                            }
                        }
                    });
                    if (!planExist) { finalDentalPlans.push(dentalAlternate); }
                });
            }
        }
        if (state.decide.vision) {
            if (state.decide.vision.selectedPlans) {
                state.decide.vision.selectedPlans.map((visionAlternate) => {
                    let planExist = false;
                    finalVisionPlans.map((visionPlans, index) => {
                        if (visionAlternate.code === visionPlans.code) { 
                            planExist = true; 
                            finalVisionPlans[index] = { //// added for Rate Override, as state.vision contains overriden rates
                                ...visionAlternate,
                                ...visionPlans
                            }
                        }
                    });
                    if (!planExist) { finalVisionPlans.push(visionAlternate); }
                });
            }
        }

        if (state.decide.life) {
            if (state.decide.life.selectedPlans) {
                state.decide.life.selectedPlans.map((lifeAlternate, index) => {
                    let planExist = false;
                    finalLifePlans.map((lifePlans) => {
                        if (lifeAlternate.code === lifePlans.code) { 
                            planExist = true;
                            finalLifePlans[index] = { //// added for Rate Override, as state.life contains overriden rates
                                ...lifeAlternate,
                                ...lifePlans
                            } }
                    });
                    if (!planExist) { finalLifePlans.push(lifeAlternate); }
                });
            }
        }
        commonPlansArray.dentalPlans = finalDentalPlans;
        commonPlansArray.visionPlans = finalVisionPlans;
        commonPlansArray.lifePlans = finalLifePlans;
    }

    return commonPlansArray;
};
export const decideHelper = (state, useEnrolledPlans) => {
    let pdfData;
    const isUhcRenewal = isUhcRenewals();
    const effectiveDateRenewal = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(state.decide.renewalCaseData.effectiveDate));
    const groupSize = isUhcRenewal ? deepCopy(state.decide.renewalData.Ibr.quote?.census?.employee ? state.decide.renewalData.Ibr.quote.census.employee.length : '3')  : deepCopy(state.decide.renewalData.memberGroup.locations.employeeCounts[0] ? state.decide.renewalData.memberGroup.locations.employeeCounts[0].type === 'FTE' ? state.decide.renewalData.memberGroup.locations.employeeCounts[0].count : 0 : 0);
    const eligibleEmployees = isUhcRenewal ? state.review?.renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '' : deepCopy(state.decide.renewalData.memberGroup.locations.employeeCounts[1] ? state.decide.renewalData.memberGroup.locations.employeeCounts[1].type === 'Eligibles' ? state.decide.renewalData.memberGroup.locations.employeeCounts[1].count : 0 : 0);
    let employeesRenArray = deepCopy(state.decide.renewalData.members);
    const renLifePlans = state.decide.life.selectedPlans || [];
    if(isUhcRenewals() && renLifePlans.length > 0){
        const renLifeEmployees = extractLifeEmployees(renLifePlans);
        if(renLifeEmployees.length > 0 && renLifeEmployees[0].optedLifeClass !== undefined && renLifeEmployees[0].optedLifeClass !== ""){
            employeesRenArray = mapClassName(employeesRenArray, renLifeEmployees);
        }
    }
    if (isUhcRenewals()) employeesRenArray.sort((a, b) => a.lastName.localeCompare(b.lastName)); // UHC RN displays employees alphabetically
    const ibrEmployees = isUhcRenewals() ? deepCopy(state.decide.members) : null;
    let employeesRenewal = [];
    let groupCount = 0;
    const commonPlans = findCommonPlans(state);
    const customerType = deepCopy(state.renewals?.rlCaseData?.custType);

    let medicalPlans = !useEnrolledPlans ? state.decide.shoppingPlans : (isUhcRenewal ? [].concat(...Object.values(state.rules.enrollment.enrolledPlans)) || [] : state.rules.enrollment.enrolledPlans.standalone || []);
    let dentalPlans = !useEnrolledPlans ? commonPlans.dentalPlans : state.rules.enrollment.enrolledDentalPlans || [];
    let visionPlans = !useEnrolledPlans ? commonPlans.visionPlans : state.rules.enrollment.enrolledVisionPlans || [];
    let lifePlans = !useEnrolledPlans ? commonPlans.lifePlans : state.rules.enrollment.enrolledLifePlans || [];
    let depLifePlans = !useEnrolledPlans ? state.decide.dependentLife.selectedPlans : state.rules.enrollment.enrolledDependentLifePlans || [];
    let ltdPlans = state.rules.enrollment?.enrolledLTDPlans?.length > 0 ? state.rules.enrollment.enrolledLTDPlans : state.decide.ltd.selectedPlans || [];
    let stdPlans = state.rules.enrollment?.enrolledSTDPlans?.length > 0 ? state.rules.enrollment.enrolledSTDPlans : state.decide.std.selectedPlans || [];
    let suppLifePlans = state.rules.enrollment?.enrolledSuppLifePlans?.length > 0 ? state.rules.enrollment.enrolledSuppLifePlans : state.decide.suppLife?.selectedPlans || [];



    const planRates = state.decide.renewalData?state.decide.renewalData.planRates?state.decide.renewalData.planRates:'':'';
    const prods = [];
    let renewingPlans = [];
    state.decide.medical && state.decide.medical.set && state.decide.medical.set.length > 0 && state.decide.medical.set.forEach(plan => {
        renewingPlans.push(plan[1]);
    }) 

    if (!medicalPlans){
        medicalPlans=[]
    }
    if (!dentalPlans){
        dentalPlans=[]
    }
    if (!visionPlans){
        visionPlans=[]
    }
    if (!lifePlans){
        lifePlans=[]
    }
    if (!depLifePlans){
        depLifePlans=[]
    }

    if (!ltdPlans){
        ltdPlans=[]
    }
    if (!stdPlans){
        stdPlans=[]
    }
    if(!suppLifePlans){
        suppLifePlans = []
    }
    
    if (medicalPlans && medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans && dentalPlans.length > 0) prods.push('dental');
    if (visionPlans && visionPlans.length > 0) prods.push('vision');
    if (lifePlans && lifePlans.length > 0) prods.push('life');
    if (depLifePlans && depLifePlans.length > 0) prods.push('lifeDep');
    if (ltdPlans && ltdPlans.length > 0) prods.push('ltd');
    if (stdPlans && stdPlans.length > 0) prods.push('std');
    if (suppLifePlans && suppLifePlans.length > 0) prods.push('suppLife');


    let finalVisionPlan = [];
    let isPushed=false;
    let isRateGuaranteePlan;
    if(identifyPlatform(state.decide.renewalData) === 'CIRRUS' ){
     visionPlans.map(plan=>{
        isPushed=false;
        planRates &&  planRates.map(x=>{
             if(x.planCode==plan.planCode && x.renewalPlanType && x.renewalPlanType=="Renewal"){
                isPushed=true;
                 isRateGuaranteePlan={
                     ...plan,
                     rateGuarBegin:x.rateGuarBegin,
                     rateGuarEnd:x.rateGuarEnd
                 }
                 finalVisionPlan.push(isRateGuaranteePlan);
             }
         })
         if(!isPushed){
            finalVisionPlan.push(plan);
         }
     })
    }else{
        finalVisionPlan=visionPlans
    }
 
    if(isUhcRenewal){
        employeesRenArray.map((emp) => {
            const matchedEmp =ibrEmployees.find((emp1)=>emp1.employeeId==emp.employeeId);
            let products = ['medical','dental','vision','basicLife','dependentLife', 'ltd', 'std','suppLife']
            if(matchedEmp){
                let coverages = matchedEmp.coverages;
                products.map((product)=>{
                    if(!coverages.hasOwnProperty(product)){
                        coverages[product] = emp.coverageLevel;
                    }
                    else{
                        coverages[product] = getCovergaes(coverages[product])
                    }
                })
                emp.coverages = coverages;
            }
        });
    }
    
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Self') {
            if(!isUhcRenewal){
                if(customerType === 'PRIME'){
                    temp.coverages = {
                        medical: emp.coverageLevel,
                        dental: emp.coverageLevel,
                        vision: emp.coverageLevel,
                        basicLife: emp.coverageLevel,
                        dependentLife: emp.coverageLevel,
                        std: emp.coverageLevel,
                        ltd: emp.coverageLevel,
                        suppLife : emp.coverageLevel,
                    };
                }
                else{
                    const empCoverages = {};
                    emp.products.forEach((coverage, index) => {
                        let product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                        let coverageLevel =  coverage.coverageLevel;
                        if (product.toLowerCase().includes("basiclife")) {
                          product = "basicLife";
                          coverageLevel =
                            coverageLevel == "EO" ? "EL" : coverageLevel;
                        }
                        empCoverages[product] = coverageLevel;
                    })
                    temp.coverages = empCoverages;
                }  
            }
            else{
                temp.coverages = emp.coverages;
            }
            temp.dependents = [];
            temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
            temp.employeeStatus = emp.status ? emp.status : '';
            temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
            employeesRenewal.push(temp);
            groupCount += 1;
        }
    });
    const groupSizeRenewal = groupSize === 0 ? groupCount : groupSize;
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Spouse' || temp.relationship === 'Civil Union Partner' || temp.relationship === 'Domestic Partner' || temp.relationship === 'Life Partner' || temp.relationship === 'Child' || temp.relationship === 'Dependent Child') {
            if (employeesRenewal && employeesRenewal.length > 0) {
                employeesRenewal.forEach((y, i) => {
                    if (temp.employeeId === y.employeeId) {
                        if(!isUhcRenewal){
                            if(customerType === 'PRIME'){
                                temp.coverages = {
                                    medical: emp.coverageLevel,
                                    dental: emp.coverageLevel,
                                    vision: emp.coverageLevel,
                                    basicLife: emp.coverageLevel,
                                    dependentLife: emp.coverageLevel,
                                    std: emp.coverageLevel,
                                    ltd: emp.coverageLevel,
                                    suppLife : emp.coverageLevel,
                                };
                            }
                            else{
                                const empCoverages = {};
                                emp.products.forEach((coverage, index) => {
                                    const product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                                    empCoverages[product] = coverage.coverageLevel;
                                })
                                temp.coverages = empCoverages;
                            } 
                        }
                        else{
                            temp.coverages = {
                                medical: emp.coverages.medical,
                                dental: emp.coverages.dental,
                                vision: emp.coverages.vision,
                                basicLife: emp.coverages.life,
                                dependentLife: emp.coverages.dependentLife,
                                std: emp.coverageLevel,
                                ltd: emp.coverageLevel,
                                suppLife : emp.coverageLevel,
                            };
                        }
                        temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
                        temp.employeeStatus = emp.status ? emp.status : '';
                        temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
                        temp.relationship = temp.relationship ? temp.relationship : '';
                        employeesRenewal[i].dependents.push(temp);
                    }
                });
            }
        }
    });
    const covLevelEmp = [];
    covLevelEmp.push(employeesRenewal);
    const tempSelectedProducts = {
        medical: medicalPlans && medicalPlans.length > 0 ? true : false,
        dental: dentalPlans && dentalPlans.length > 0 ? true : false,
        vision: visionPlans && visionPlans.length > 0 ? true : false,
        basicLife: lifePlans && lifePlans.length > 0 ? true : false,
        dependentLife: depLifePlans && depLifePlans.length > 0 ? true : false,
        ltd:ltdPlans && ltdPlans.length >0 ?true:false,
        std:stdPlans && stdPlans.length >0 ?true:false,
        suppLife : suppLifePlans && suppLifePlans.length>0 ?true : false,

    };

    employeesRenewal = setCoverages(tempSelectedProducts, covLevelEmp, medicalPlans, dentalPlans, visionPlans, lifePlans, depLifePlans,stdPlans, ltdPlans ,suppLifePlans);
    const companyProfileRenewal = deepCopy(state.companyProfile);
    const overallRenewal = deepCopy(state.overall);
    // companyProfileRenewal.sicCode = deepCopy(state.decide.renewalData.memberGroup.industryCode)
    if (companyProfileRenewal.sicCode === '' || companyProfileRenewal.sicCode == null) {
        companyProfileRenewal.sicCode = '-';
    }
    companyProfileRenewal.sicData = {
        // division: "",
        // majorGroup: 99,
        // industryGroup: 999,
        sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
        description: '-',
        // divisionDescription: "Public Administration",
        // majorGroupDescription: "Nonclassifiable Establishments",
        // industryGroupDescription: "Nonclassifiable Establishments",
        productAvailability: {
            dental: false,
            vision: false,
            life: false,
            dependentLife: false,
            ltd: false,
            std: false,
            suppLife : false,
        },
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    let zipObj = {
        zipCode: state.decide.renewalCaseData.zipCode,
        stateCode: state.decide.renewalCaseData.situsState,
        state: state.decide.renewalCaseData.situsState
    }
    companyProfileRenewal.employees = employeesRenewal;
    companyProfileRenewal.effectiveDate = effectiveDateRenewal;
    companyProfileRenewal.selectedProducts = state.companyProfile.userCardsSelectedProducts;
    const zipObjRenewal = isUhcRenewal ? zipObj : Object.keys(companyProfileRenewal.locations[0].zipData).length != 0 ? companyProfileRenewal.locations[0].zipData : zipObj;
    const specialtyCriteriaRenewal = state.specialtyCriteria;

    const { customPlanNames } = state.decide;

    let isComposite = false;
    if (medicalPlans && medicalPlans.length > 0) {
        medicalPlans.forEach((plan) => {
            if(isUhcRenewal) {
                if(plan.defaultRatingType === 'composite') isComposite = true;
            } else {
                if (plan.isComposite) { isComposite = true; }
            }
        });
    }

    if (medicalPlans !== []) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
                if (isUhcRenewals()) {
                    const uhcPlatformCode = state.renewals?.renewalCaseData?.uhcPlatformCode || '';
                    const platformBeforeIpps = uhcPlatformCode !== '' ? ((uhcPlatformCode === 'M' || uhcPlatformCode === 'U') ? 'CIRRUS' : 'PRIME') : plan.platform;
                    plan.installPlatform = plan.installPlatform ? plan.installPlatform : platformBeforeIpps
                }
            }
        });
    }

    if(!useEnrolledPlans && isUhcRenewal && renewingPlans && renewingPlans.length > 0 && medicalPlans && medicalPlans.length > 0){
        let renewingPlanCode = renewingPlans.map((renewingPlan) => renewingPlan.code);
        medicalPlans = medicalPlans.filter((plan) => !renewingPlanCode.includes(plan.code) || plan.renewalPlanType === "renewing")
    }

    pdfData = {
        effectiveDate: effectiveDateRenewal,
        groupSize: groupSizeRenewal,
        eligibleEmployees,
        zipObj: zipObjRenewal,
        quickQuoteRequest: {
            zipObj: zipObjRenewal,
            effectiveDate: effectiveDateRenewal,
            otherDetails: {
                includeSpecialty: dentalPlans.length > 0 || visionPlans.length > 0 || lifePlans.length > 0 || ltdPlans.length>0 || stdPlans.length>0 || suppLifePlans.length > 0,
                sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
                selectedProducts: prods,
                isPreferredPlans: true,
            },
        },
        isCirrus: !!((identifyPlatform(state.decide.renewalData) === 'CIRRUS' || identifyPlatform(state.decide.renewalData) === 'DUAL')),
        companyProfile: companyProfileRenewal,
        overall: overallRenewal,

        brokers: extractBrokers(state),
        generalAgent: extractGeneralAgent(state),
        salesRep: extractSalesRep(state),
        agencies: extractAgency(state),
        // salesRep: [
        //     {
        //         name: state.reviewCards.ownerAssignment.salesRep[0] ? state.reviewCards.ownerAssignment.salesRep[0].salesRepName : "N/A",
        //         phone: "N/A"
        //     }
        // ],
        employees: employeesRenewal,
        medicalPlans,
        dental: { selectedPlans: dentalPlans },
        vision: { selectedPlans: finalVisionPlan },
        life: { selectedPlans: lifePlans },
        lifeDep: { selectedPlans: depLifePlans },
        ltd:{selectedPlans:ltdPlans},
        std:{selectedPlans:stdPlans},
        suppLife : {selectedPlans : suppLifePlans},
        sendMail: false,
        ratingMethod: {
            age: !isComposite,
            tier: isComposite,
        },
        specialtyCriteria: specialtyCriteriaRenewal,
        creationDate: new Date().toLocaleString('en-us', { timeZoneName: 'short' }),
    };
    return pdfData;
};

export const mapClassName = (employees , lifeEmployees) =>{
    employees.map((employee)=> {
        employee.optedLifeClass = lifeEmployees.find((emp)=> emp.employeeId === employee.employeeId)?.optedLifeClass ;
    })
    return employees;
}

export const extractLifeEmployees = (lifePlans) => {
    let employees = [];
    lifePlans.forEach((lifePlan) => {
        if(lifePlan.member && lifePlan.member.length > 0)
            employees.push(...lifePlan.member);
    })
    return employees;
}

export const reviewHelper = (state) => {
    let pdfData;
    const isUhcRenewal = isUhcRenewals();
    const isOxfordRenewal = isOxfordRenewals()
    const effectiveDateRenewal = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(state.review.renewalCaseData.effectiveDate));
    const groupSize = isUhcRenewal ? state.review.renewalData?.Ibr?.quote?.census?.employee ? deepCopy(state.review.renewalData.Ibr.quote.census.employee.length) : '3'  : state.review.renewalData.memberGroup.locations.employeeCounts[0] ? deepCopy(state.review.renewalData.memberGroup.locations.employeeCounts[0].count) : '3';
    const eligibleEmployees = isUhcRenewal ? state.review?.renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '' : deepCopy(state.review.renewalData.memberGroup.locations.employeeCounts[1].count);
    let employeesRenArray = deepCopy(state.review.renewalData.members);
    const renLifePlans = state.review.life.selectedPlans || [];
    if(isUhcRenewals() && renLifePlans.length > 0){
        const renLifeEmployees = extractLifeEmployees(renLifePlans);
        if(renLifeEmployees.length > 0 && renLifeEmployees[0].optedLifeClass !== undefined && renLifeEmployees[0].optedLifeClass !== ""){
            employeesRenArray = mapClassName(employeesRenArray, renLifeEmployees);
        }
    }
    if (isUhcRenewals()) employeesRenArray.sort((a, b) => a.lastName.localeCompare(b.lastName)); // UHC RN displays employees alphabetically
    const ibrEmployees = isUhcRenewals() ? deepCopy(state.review.members) : null;
    const customerType = deepCopy(state.renewals?.rlCaseData?.custType);
    let employeesRenewal = [];
    let groupCount = 0;
    const formattedPlan = [];
    let isComposite = false;
    if (state.review.renewalData && state.review.renewalData.planRates && state.review.renewalData.planRates.length > 0) {
        const renewalSelected = state.review.renewalData.planRates.filter((plan) => ((plan.renewalPlanType === 'Renewal' && plan.productType === "Medical") && (plan.isChecked === true || !plan.hasOwnProperty('isChecked') )));
        renewalSelected.forEach((renewalPlan) => {
            if (renewalPlan && renewalPlan.rateTable && renewalPlan.rateTable.ratingMethod === 'Composite4Tier') { isComposite = true; }
            formattedPlan.push(formComparePlanObject(renewalPlan.planCode, state.review.renewalData));
        });
    } else if((getMarketType() === MARKET_TYPES.UHC) && state.review && state.review.medical.selectedPlans && state.review.medical.selectedPlans.length > 0){
        const renewalSelected = state.review.medical.selectedPlans.filter((plan) => ((plan.renewalPlanType === 'renewing') && plan.selected === true));
        renewalSelected.forEach((renewalPlan) => {
            if (
              renewalPlan &&
              (renewalPlan.ibrRatingType
                ? renewalPlan.ibrRatingType === "composite"
                : renewalPlan.defaultyRatingType === "composite")
            ) {
              isComposite = true;
            }
            formattedPlan.push(renewalPlan);
        });
    } 
    if ((getMarketType() === MARKET_TYPES.UHC) && state.review && state.review.medical.selectedPlans && state.review.medical.selectedPlans.length > 0) {
        const renewalSelected = state.review.medical.selectedPlans.filter((plan) => ((plan.renewalPlanType === 'alternate') && plan.selected === true));
        renewalSelected.forEach((renewalPlan) => {
            if (!state.review.shoppingPlans || state.review.shoppingPlans.length < 1) {
                if (
                    renewalPlan &&
                    (renewalPlan.ibrRatingType
                      ? renewalPlan.ibrRatingType === "composite"
                      : renewalPlan.defaultyRatingType === "composite")
                  ) { isComposite = true; }
                formattedPlan.push(renewalPlan);
            } else {
                let flag = 0;
                const renewalShopped = state.review.shoppingPlans.filter((plan) => ((plan.planType === "Medical") && (plan.renewalPlan !== true || !plan.hasOwnProperty('renewalPlan'))));
                renewalShopped.forEach((renewalShoppedPlan) => {
                    if (renewalPlan.code === renewalShoppedPlan.code) {
                        flag = 1;
                    }
                });
                if (flag === 0) {
                    if (
                        renewalPlan &&
                        (renewalPlan.ibrRatingType
                          ? renewalPlan.ibrRatingType === "composite"
                          : renewalPlan.defaultyRatingType === "composite")
                      ) { isComposite = true; }
                    formattedPlan.push(renewalPlan);
                }
            }
        });
    }
    
    if (state.review.shoppingPlans && state.review.shoppingPlans.length > 0) {
        const renewalSelected = state.review.shoppingPlans.filter((plan) => ((plan.planType === "Medical") && (plan.renewalPlan !== true || !plan.hasOwnProperty('renewalPlan'))));
        renewalSelected.forEach((renewalPlan) => {
            if (renewalPlan && renewalPlan.isComposite === 'Composite4Tier') { isComposite = true; }
            formattedPlan.push(renewalPlan);
        });
    }

    let medicalPlans = formattedPlan;
    let dentalPlans = state.review.dental.selectedPlans || [];
    let visionPlans = state.review.vision.selectedPlans || [];
    let lifePlans = state.review.life.selectedPlans || [];
    let depLifePlans = state.review.dependentLife.selectedPlans || [];
    let ltdPlans=state.review.ltd && state.review.ltd.selectedPlans ||[];
    let stdPlans=state.review.std &&state.review.std.selectedPlans ||[];
    let suppLifePlans=state.review.suppLife &&state.review.suppLife.selectedPlans ||[];



    const planRates = state.review.renewalData ? state.review.renewalData.planRates ? state.review.renewalData.planRates : '' : '';
    const prods = [];
    let renewingPlans = [];
    state.review.medical && state.review.medical.set && state.review.medical.set.length > 0 && state.review.medical.set.forEach(plan => {
        renewingPlans.push(plan[1]);
    }) 
    if (!medicalPlans){
        medicalPlans=[]
    }
    if (!dentalPlans){
        dentalPlans=[]
    }
    if (!visionPlans){
        visionPlans=[]
    }
    if (!lifePlans){
        lifePlans=[]
    }
    if (!depLifePlans){
        depLifePlans=[]
    }

    if (!ltdPlans){
        ltdPlans=[]
    }
    if (!stdPlans){
        stdPlans=[]
    }
    if(!suppLifePlans){
        suppLifePlans = []
    }


    if (medicalPlans && medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans && dentalPlans.length > 0) prods.push('dental');
    if (visionPlans && visionPlans.length > 0) prods.push('vision');
    if (lifePlans && lifePlans.length > 0) prods.push('life');
    if (depLifePlans && depLifePlans.length > 0) prods.push('lifeDep');
    if (ltdPlans && ltdPlans.length > 0) prods.push('ltd');
    if (stdPlans && stdPlans.length > 0) prods.push('std');
    if(suppLifePlans && suppLifePlans.length > 0) prods.push('suppLife');
    
    let  finalVisionPlan = [];
    let isRateGuaranteePlan;
    let isPushed=false;
    if(identifyPlatform(state.review.renewalData) === 'CIRRUS' ){
     visionPlans.map(plan=>{
        isPushed=false;
        planRates &&   planRates.map(x=>{
             if(x.planCode==plan.planCode && x.renewalPlanType && x.renewalPlanType=="Renewal"){
                isPushed=true;
                 isRateGuaranteePlan={
                     ...plan,
                     rateGuarBegin:x.rateGuarBegin,
                     rateGuarEnd:x.rateGuarEnd
                 }
                 finalVisionPlan.push(isRateGuaranteePlan);
             }
         })
         if(!isPushed){
            finalVisionPlan.push(plan);
         }
     })
    }else{
        finalVisionPlan=visionPlans
    }

    if(isUhcRenewal){
        employeesRenArray.map((emp) => {
            const matchedEmp =ibrEmployees.find((emp1)=>emp1.employeeId==emp.employeeId);
            let products = ['medical','dental','vision','basicLife','dependentLife','ltd','std', 'suppLife']
            if(matchedEmp){
                let coverages = matchedEmp.coverages;
                products.map((product)=>{
                    if(!coverages.hasOwnProperty(product)){
                        coverages[product] = emp.coverageLevel;
                    }
                    else{
                        coverages[product] = getCovergaes(coverages[product])
                    }
                })
                emp.coverages = coverages;
            }
        });
    }
    
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Self') {
            if(!isUhcRenewal){
                if(customerType === 'PRIME'){
                    temp.coverages = {
                        medical: emp.coverageLevel,
                        dental: emp.coverageLevel,
                        vision: emp.coverageLevel,
                        basicLife: emp.coverageLevel,
                        dependentLife: emp.coverageLevel,
                        std: emp.coverageLevel,
                        ltd: emp.coverageLevel,
                        suppLife : emp.coverageLevel,
                    };
                }
                else{
                    const empCoverages = {};
                    emp.products.forEach((coverage, index) => {
                        let product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                        let coverageLevel =  coverage.coverageLevel;
                        if(product.toLowerCase().includes("basiclife")){
                            product = "basicLife";
                            coverageLevel =
                              coverageLevel == "EO" ? "EL" : coverageLevel;
                        }
                        empCoverages[product] = coverageLevel;
                    })
                    temp.coverages = empCoverages;
                } 
            }
            else{
                temp.coverages = emp.coverages;
            }
            temp.dependents = [];
            temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
            temp.employeeStatus = emp.status ? emp.status : '';
            temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
            employeesRenewal.push(temp);
            groupCount += 1;
        }
    });
    const groupSizeRenewal = groupSize === 0 ? groupCount : groupSize;
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Spouse' || temp.relationship === 'Civil Union Partner' || temp.relationship === 'Domestic Partner' || temp.relationship === 'Life Partner' || temp.relationship === 'Child' || temp.relationship === 'Dependent Child') {
            if (employeesRenewal && employeesRenewal.length > 0) {
                employeesRenewal.forEach((y, i) => {
                    if (temp.employeeId === y.employeeId) {
                        if(!isUhcRenewal){
                            if(customerType === 'PRIME'){
                                temp.coverages = {
                                    medical: emp.coverageLevel,
                                    dental: emp.coverageLevel,
                                    vision: emp.coverageLevel,
                                    basicLife: emp.coverageLevel,
                                    dependentLife: emp.coverageLevel,
                                    std: emp.coverageLevel,
                                    ltd: emp.coverageLevel,
                                    suppLife : emp.coverageLevel,
                                };
                            }
                            else{
                                const empCoverages = {};
                                emp.products.forEach((coverage, index) => {
                                    const product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                                    empCoverages[product] = coverage.coverageLevel;
                                })
                                temp.coverages = empCoverages;
                            } 
                        }
                        else{
                            temp.coverages = {
                                medical: emp.coverages.medical,
                                dental: emp.coverages.dental,
                                vision: emp.coverages.vision,
                                basicLife: emp.coverages.life,
                                dependentLife: emp.coverages.dependentLife,
                                std: emp.coverageLevel,
                                ltd: emp.coverageLevel,
                                suppLife : emp.coverageLevel,
                            };
                        }
                        temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
                        temp.employeeStatus = emp.status ? emp.status : '';
                        temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
                        temp.relationship = temp.relationship ? temp.relationship : '';
                        employeesRenewal[i].dependents.push(temp);
                    }
                });
            }
        }
    });

    const covLevelEmp = [];
    covLevelEmp.push(employeesRenewal);
    const tempSelectedProducts = {
        medical: medicalPlans && medicalPlans.length > 0 ? true : false,
        dental: dentalPlans && dentalPlans.length > 0 ? true : false,
        vision: visionPlans && visionPlans.length > 0 ? true : false,
        basicLife: lifePlans && lifePlans.length > 0 ? true : false,
        dependentLife: depLifePlans && depLifePlans.length > 0 ? true : false,
        ltd:ltdPlans && ltdPlans.length >0 ?true:false,
        std:stdPlans && stdPlans.length >0 ?true:false,
        suppLife : suppLifePlans && suppLifePlans.length > 0 ? true : false
        
    };

    

    employeesRenewal = setCoverages(tempSelectedProducts, covLevelEmp, medicalPlans, dentalPlans, visionPlans, lifePlans, depLifePlans,stdPlans, ltdPlans );
    const companyProfileRenewal = deepCopy(state.companyProfile);
    const overallRenewal = deepCopy(state.overall);
    // companyProfileRenewal.sicCode = deepCopy(state.review.renewalData.memberGroup.industryCode)
    if (companyProfileRenewal.sicCode === '' || companyProfileRenewal.sicCode == null) {
        companyProfileRenewal.sicCode = '-';
    }
    companyProfileRenewal.sicData = {
        // division: "",
        // majorGroup: 99,
        // industryGroup: 999,
        sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
        description: '-',
        // divisionDescription: "Public Administration",
        // majorGroupDescription: "Nonclassifiable Establishments",
        // industryGroupDescription: "Nonclassifiable Establishments",
        productAvailability: {
            dental: false,
            vision: false,
            life: false,
            dependentLife: false,
            ltd: false,
            std: false,
            suppLife : false
        },
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    const selectedProductsObj = {
        medical: medicalPlans && medicalPlans.length > 0 ? true : false,
        dental: dentalPlans && dentalPlans.length > 0 ? true : false,
        vision: visionPlans && visionPlans.length > 0 ? true : false,
        life: lifePlans && lifePlans.length > 0 ? true : false,
        dependentLife: depLifePlans && depLifePlans.length > 0 ? true : false,
        ltd:ltdPlans && ltdPlans.length >0 ?true:false,
        std:stdPlans && stdPlans.length >0 ?true:false,
        suppLife : suppLifePlans && suppLifePlans.length >0 ?true:false
    };
    let zipObj = {
        zipCode: state.review.renewalCaseData.zipCode,
        stateCode: state.review.renewalCaseData.situsState,
        state: state.review.renewalCaseData.situsState
    }
    companyProfileRenewal.employees = employeesRenewal;
    companyProfileRenewal.effectiveDate = effectiveDateRenewal;
    companyProfileRenewal.selectedProducts = selectedProductsObj;
    const zipObjRenewal = isUhcRenewal ? zipObj : Object.keys(companyProfileRenewal.locations[0].zipData).length != 0 ? companyProfileRenewal.locations[0].zipData : zipObj;
    const specialtyCriteriaRenewal = state.specialtyCriteria;

    specialtyCriteriaRenewal.sicObj = {
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    const { customPlanNames } = state.review;

    if (medicalPlans !== []) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
                if (isUhcRenewals()) {
                    const uhcPlatformCode = state.renewals?.renewalCaseData?.uhcPlatformCode || '';
                    const platformBeforeIpps = uhcPlatformCode !== '' ? ((uhcPlatformCode === 'M' || uhcPlatformCode === 'U') ? 'CIRRUS' : 'PRIME') : plan.platform;
                    plan.installPlatform = plan.installPlatform ? plan.installPlatform : platformBeforeIpps
                }
            }
        });
    }

    if(isUhcRenewal && renewingPlans && renewingPlans.length > 0 && medicalPlans && medicalPlans.length > 0){
        let renewingPlanCode = renewingPlans.map((renewingPlan) => renewingPlan.code);
        medicalPlans = medicalPlans.filter((plan) => !renewingPlanCode.includes(plan.code) || plan.renewalPlanType === "renewing")
    }

    pdfData = {
        effectiveDate: effectiveDateRenewal,
        groupSize: groupSizeRenewal,
        eligibleEmployees,
        zipObj: zipObjRenewal,
        quickQuoteRequest: {
            zipObj: zipObjRenewal,
            effectiveDate: effectiveDateRenewal,
            otherDetails: {
                includeSpecialty: dentalPlans.length > 0 || visionPlans.length > 0 || lifePlans.length > 0|| ltdPlans>0 || stdPlans>0 || suppLifePlans > 0,
                sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
                selectedProducts: prods,
                isPreferredPlans: true,
            },
        },
        isCirrus: !!((identifyPlatform(state.review.renewalData) === 'CIRRUS' || identifyPlatform(state.review.renewalData) === 'DUAL')),
        companyProfile: companyProfileRenewal,
        overall: overallRenewal,
        brokers: extractBrokers(state),
        generalAgent: extractGeneralAgent(state),

        salesRep: extractSalesRep(state),
        agencies: extractAgency(state),

        employees: employeesRenewal,
        medicalPlans,
        dental: { selectedPlans: dentalPlans },
        vision: { selectedPlans: finalVisionPlan },
        life: { selectedPlans: lifePlans },
        lifeDep: { selectedPlans: depLifePlans },
        ltd:{selectedPlans:ltdPlans},
        std:{selectedPlans:stdPlans},
        suppLife : {selectedPlans : suppLifePlans},
        sendMail: false,
        ratingMethod: {
            age: !isComposite,
            tier: isComposite,
        },
        specialtyCriteria: specialtyCriteriaRenewal,
        creationDate: new Date().toLocaleString('en-us', { timeZoneName: 'short' }),
    };
    return pdfData;
};
export const renewalShoppingPageHelper = (state) => {
    let pdfData;
    const isUhcRenewal = isUhcRenewals();
    const isOxfordRenewal = isOxfordRenewals()
    const effectiveDateRenewal = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(state.renewalShoppingHelper.renewalCaseData.effectiveDate));
    const groupSize = isUhcRenewal ? deepCopy(state.renewalShoppingHelper.renewalData.Ibr.quote?.census?.employee ? state.renewalShoppingHelper.renewalData.Ibr.quote.census.employee.length : '3')  :deepCopy(state.renewalShoppingHelper.renewalData.memberGroup.locations.employeeCounts[0] ? state.renewalShoppingHelper.renewalData.memberGroup.locations.employeeCounts[0].count : '3');
    const eligibleEmployees = isUhcRenewal ? state.review?.renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '' : state.renewalShoppingHelper.renewalData.memberGroup.locations.employeeCounts[1].count;
    let employeesRenArray = deepCopy(state.renewalShoppingHelper.renewalData.members);
    const renLifePlans = state.renewalShoppingHelper.life.selectedPlans || [];
    if(isUhcRenewals() && renLifePlans.length > 0){
        const renLifeEmployees = extractLifeEmployees(renLifePlans);
        if(renLifeEmployees.length > 0 && renLifeEmployees[0].optedLifeClass !== undefined && renLifeEmployees[0].optedLifeClass !== ""){
            employeesRenArray = mapClassName(employeesRenArray, renLifeEmployees);
        }
    }
    if (isUhcRenewals()) employeesRenArray.sort((a, b) => a.lastName.localeCompare(b.lastName)); // UHC RN displays employees alphabetically
    const ibrEmployees = isUhcRenewals() ? deepCopy(state.renewalShoppingHelper.members) : null;
    const customerType = deepCopy(state.renewals?.rlCaseData?.custType);
    let employeesRenewal = [];
    let groupCount = 0;
    const planRates = state.renewalShoppingHelper.renewalData?state.renewalShoppingHelper.renewalData.planRates?state.renewalShoppingHelper.renewalData.planRates:'':'';
    let medicalPlans = state.renewalShopping.selectedPlans || [];
    const dentalPlans = state.dental.selectedPlans || [];
    const visionPlans = state.vision.selectedPlans || [];
    const lifePlans = state.life.selectedPlans || [];
    const lifeDepPlans = state.renewalShoppingHelper.dependentLife.selectedPlans || [];
    let ltdPlans= (isUhcRenewal || isOxfordRenewal) && state.renewalShoppingHelper.ltd && state.renewalShoppingHelper.ltd.selectedPlans ||[];
    let stdPlans= (isUhcRenewal || isOxfordRenewal) && state.renewalShoppingHelper.std &&state.renewalShoppingHelper.std.selectedPlans ||[];
    let suppLifePlans = (isUhcRenewal || isOxfordRenewal) && state.renewalShoppingHelper.suppLife &&state.renewalShoppingHelper.suppLife.selectedPlans ||[];
    const prods = [];
    let renewingPlans = [];
    state.renewalShoppingHelper.medical && state.renewalShoppingHelper.medical.set && state.renewalShoppingHelper.medical.set.length > 0 && state.renewalShoppingHelper.medical.set.forEach(plan => {
        renewingPlans.push(plan[1]);
    })
    if (medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans.length > 0) prods.push('dental');
    if (visionPlans.length > 0) prods.push('vision');
    if (lifePlans.length > 0) prods.push('life');
    if (lifeDepPlans.length > 0) prods.push('life_dep_basic');
    if (ltdPlans && ltdPlans.length > 0) prods.push('ltd');
    if (stdPlans && stdPlans.length > 0) prods.push('std');
    if (suppLifePlans && suppLifePlans.length > 0) prods.push('suppLife');

    let  finalVisionPlan = [];
    let isPushed=false;
    let isRateGuaranteePlan;
    if(identifyPlatform(state.renewalShoppingHelper.renewalData) === 'CIRRUS' ){
     visionPlans.map(plan=>{
        isPushed=false;
        planRates &&  planRates.map(x=>{
             if(x.planCode==plan.planCode && x.renewalPlanType && x.renewalPlanType=="Renewal"){
                 isPushed=true;
                 isRateGuaranteePlan={
                     ...plan,
                     rateGuarBegin:x.rateGuarBegin,
                     rateGuarEnd:x.rateGuarEnd
                 }
                 finalVisionPlan.push(isRateGuaranteePlan);
             }
         })
         if(!isPushed){
            finalVisionPlan.push(plan);
         }
     })
    }
    else{
        finalVisionPlan=visionPlans
    }
    if(isUhcRenewal){
        employeesRenArray.map((emp) => {
            const matchedEmp =ibrEmployees.find((emp1)=>emp1.employeeId==emp.employeeId);
            let products = ['medical','dental','vision','basicLife','dependentLife','ltd','std' , 'suppLife']
            if(matchedEmp){
                let coverages = matchedEmp.coverages;
                products.map((product)=>{
                    if(!coverages.hasOwnProperty(product)){
                        coverages[product] = emp.coverageLevel;
                    }
                    else{
                        coverages[product] = getCovergaes(coverages[product])
                    }
                })
                emp.coverages = coverages;
            }
        });
    }
    
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Self') {
            if(!isUhcRenewal){
                if(customerType === 'PRIME'){
                    temp.coverages = {
                        medical: emp.coverageLevel,
                        dental: emp.coverageLevel,
                        vision: emp.coverageLevel,
                        basicLife: emp.coverageLevel,
                        dependentLife: emp.coverageLevel,
                        std: emp.coverageLevel,
                        ltd: emp.coverageLevel,
                        suppLife : emp.coverageLevel,
                    };
                }
                else{
                    const empCoverages = {};
                    emp.products.forEach((coverage, index) => {
                        let product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                        let coverageLevel =  coverage.coverageLevel;
                        if (product.toLowerCase().includes("basiclife")) {
                          product = "basicLife";
                          coverageLevel =
                            coverageLevel == "EO" ? "EL" : coverageLevel;
                        }
                        empCoverages[product] = coverageLevel;
                    })
                    temp.coverages = empCoverages;
                } 
            }
            else{
                temp.coverages = emp.coverages;
            }
            temp.dependents = [];
            temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
            temp.employeeStatus = emp.status ? emp.status : '';
            temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
            employeesRenewal.push(temp);
            groupCount += 1;
        }
    });
    const groupSizeRenewal = groupSize === 0 ? groupCount : groupSize;
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Spouse' || temp.relationship === 'Civil Union Partner' || temp.relationship === 'Domestic Partner' || temp.relationship === 'Life Partner' || temp.relationship === 'Child' || temp.relationship === 'Dependent Child') {
            if (employeesRenewal && employeesRenewal.length > 0) {
                employeesRenewal.forEach((y, i) => {
                    if (temp.employeeId === y.employeeId) {
                        if(!isUhcRenewal){
                            if(customerType === 'PRIME'){
                                temp.coverages = {
                                    medical: emp.coverageLevel,
                                    dental: emp.coverageLevel,
                                    vision: emp.coverageLevel,
                                    basicLife: emp.coverageLevel,
                                    dependentLife: emp.coverageLevel,
                                    std: emp.coverageLevel,
                                    ltd: emp.coverageLevel,
                                    suppLife : emp.coverageLevel,
                                };
                            }
                            else{
                                const empCoverages = {};
                                emp.products.forEach((coverage, index) => {
                                    const product = (coverage.type).charAt(0).toLowerCase() + (coverage.type).slice(1).replace(/\s/g, '');
                                    empCoverages[product] = coverage.coverageLevel;
                                })
                                temp.coverages = empCoverages;
                            } 
                        }
                        else{
                            temp.coverages = {
                                medical: emp.coverages.medical,
                                dental: emp.coverages.dental,
                                vision: emp.coverages.vision,
                                basicLife: emp.coverages.life,
                                dependentLife: emp.coverages.dependentLife,
                                std: emp.coverageLevel,
                                ltd: emp.coverageLevel,
                                suppLife : emp.coverageLevel,
                            };
                        }
                        temp.dob = isUhcRenewal ? convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY') : MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
                        temp.employeeStatus = emp.status ? emp.status : '';
                        temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
                        temp.relationship = temp.relationship ? temp.relationship : '';
                        employeesRenewal[i].dependents.push(temp);
                    }
                });
            }
        }
    });
    const covLevelEmp = [];
    covLevelEmp.push(employeesRenewal);
    const tempSelectedProducts = {
        medical: medicalPlans.length > 0,
        dental: dentalPlans.length > 0,
        vision: visionPlans.length > 0,
        basicLife: lifePlans.length > 0,
        ltd: ltdPlans.length >0 ,
        std:stdPlans.length >0 ,
        suppLife : suppLifePlans.length >0 
        

    };

    employeesRenewal = setCoverages(tempSelectedProducts, covLevelEmp, medicalPlans, dentalPlans, visionPlans, lifePlans, lifeDepPlans, stdPlans, ltdPlans , suppLifePlans);
    const companyProfileRenewal = deepCopy(state.companyProfile);
    const overallRenewal = deepCopy(state.overall);
    // companyProfileRenewal.sicCode = deepCopy(state.renewalShoppingHelper.renewalData.memberGroup.industryCode)
    if (companyProfileRenewal.sicCode === '' || companyProfileRenewal.sicCode == null) {
        companyProfileRenewal.sicCode = '-';
    }
    companyProfileRenewal.sicData = {
        // division: "",
        // majorGroup: 99,
        // industryGroup: 999,
        sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
        description: '-',
        // divisionDescription: "Public Administration",
        // majorGroupDescription: "Nonclassifiable Establishments",
        // industryGroupDescription: "Nonclassifiable Establishments",
        productAvailability: {
            dental: false,
            vision: false,
            life: false,
            ltd: false,
            std: false,
            suppLife : false,
        },
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    let zipObj = {
        zipCode: state.renewalShoppingHelper.renewalCaseData.zipCode,
        stateCode: state.renewalShoppingHelper.renewalCaseData.situsState,
        state: state.renewalShoppingHelper.renewalCaseData.situsState
    }
    companyProfileRenewal.employees = employeesRenewal;
    companyProfileRenewal.effectiveDate = effectiveDateRenewal;
    companyProfileRenewal.selectedProducts = state.companyProfile.userCardsSelectedProducts;
    const zipObjRenewal = isUhcRenewal ? zipObj : Object.keys(companyProfileRenewal.locations[0].zipData).length != 0 ? companyProfileRenewal.locations[0].zipData : zipObj;
    const specialtyCriteriaRenewal = state.specialtyCriteria;

    const { customPlanNames } = state.renewalShoppingHelper;

    let isComposite = false;
    if (medicalPlans && medicalPlans.length > 0) {
        medicalPlans.forEach((plan) => {
            if(isUhcRenewal) {
                if(plan.defaultRatingType === 'composite') isComposite = true;
            } else {
                if (plan.isComposite) { isComposite = true; }
            }
        });
    }

    if (medicalPlans !== []) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
                if (isUhcRenewals()) {
                    const uhcPlatformCode = state.renewals?.renewalCaseData?.uhcPlatformCode || '';
                    const platformBeforeIpps = uhcPlatformCode !== '' ? ((uhcPlatformCode === 'M' || uhcPlatformCode === 'U') ? 'CIRRUS' : 'PRIME') : plan.platform;
                    plan.installPlatform = plan.installPlatform ? plan.installPlatform : platformBeforeIpps
                }
            }
        });
    }

    if(isUhcRenewal && renewingPlans && renewingPlans.length > 0 && medicalPlans && medicalPlans.length > 0){
        let renewingPlanCode = renewingPlans.map((renewingPlan) => renewingPlan.code);
        medicalPlans = medicalPlans.filter((plan) => !renewingPlanCode.includes(plan.code) || plan.renewalPlanType === "renewing")
    }

    pdfData = {
        effectiveDate: effectiveDateRenewal,
        groupSize: groupSizeRenewal,
        eligibleEmployees,
        zipObj: zipObjRenewal,
        quickQuoteRequest: {
            zipObj: zipObjRenewal,
            effectiveDate: effectiveDateRenewal,
            otherDetails: {
                includeSpecialty: dentalPlans.length > 0 || visionPlans.length > 0 || lifePlans.length > 0 || ltdPlans>0 || stdPlans>0 || suppLifePlans > 0,
                sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
                selectedProducts: prods,
                isPreferredPlans: true,
            },
        },
        isCirrus: !!((identifyPlatform(state.renewalShoppingHelper.renewalData) === 'CIRRUS' || identifyPlatform(state.renewalShoppingHelper.renewalData) === 'DUAL')),
        companyProfile: companyProfileRenewal,
        overall: overallRenewal,

        brokers: extractBrokers(state),
        generalAgent: extractGeneralAgent(state),
        salesRep: extractSalesRep(state),
        agencies: extractAgency(state),
        // salesRep: [
        //     {
        //         name: state.reviewCards.ownerAssignment.salesRep[0] ? state.reviewCards.ownerAssignment.salesRep[0].salesRepName : "N/A",
        //         phone: "N/A"
        //     }
        // ],
        employees: employeesRenewal,
        medicalPlans,
        dental: { selectedPlans: dentalPlans },
        vision: { selectedPlans: finalVisionPlan },
        life: { selectedPlans: lifePlans },
        lifeDep: { selectedPlans: lifeDepPlans },
        ltd:{selectedPlans:ltdPlans},
        std:{selectedPlans:stdPlans},
        suppLife:{selectedPlans:suppLifePlans},
        sendMail: false,
        ratingMethod: {
            age: !isComposite,
            tier: isComposite,
        },
        specialtyCriteria: specialtyCriteriaRenewal,
        creationDate: new Date().toLocaleString('en-us', { timeZoneName: 'short' }),
    };
    return pdfData;
};
